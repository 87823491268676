import { Badge, Image, Nav } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { APITeam, APITournament } from '../../Types'
import { getEmptyBoardCount } from '../../util/Team'

type Props = {
    tournament: APITournament,
    teams: APITeam[]
}

export function TeamLeadSideBar ({tournament, teams}: Props) {    
    return (
        <Nav variant="pills" className="flex-column justify-content-between pt-2 h-100">
            <div>
                { teams.map((team, key) => {
                    let countPlayersNotRegistered = team.players.filter(p=>!p.isRegistered).length
                    let emptyBoardCount = getEmptyBoardCount(team, tournament.settings.boardCount, tournament.settings.isStrictMode)
                    return (
                        <LinkContainer to={"/"+tournament.id+"/teamlead/"+team.id} key={key}>
                            <Nav.Link>
                                {team.name+" "}
                                {countPlayersNotRegistered > 0 &&
                                    <Badge variant="warning">
                                        {countPlayersNotRegistered}
                                    </Badge>
                                }
                                {emptyBoardCount > 0 &&
                                    <Badge variant="danger">
                                        {emptyBoardCount}
                                    </Badge>
                                }
                            </Nav.Link>
                        </LinkContainer>
                    )
                })}
            </div>
            <Image src="/skk-logo.png" fluid className="px-2" />
        </Nav>
    )
}