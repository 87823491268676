import {Table, Button} from 'react-bootstrap'
import {DOMAIN_ADMINISTRATOR, ALERT_ADD} from '../../constants';
import { t } from '../../language/translations';
import {User, UIAlertAction} from '../../Types'
import { alertDispatch } from '../../util/Alerts';
import {getRequestHeader} from '../../util/ApiRequest'

async function sendRequestToBackend(alias: string, token: string|null){
    const requestHeader = getRequestHeader (token)
    const target = DOMAIN_ADMINISTRATOR + "/" + alias

    const fetchRes = await fetch(target, {
        method: 'GET',
        headers: requestHeader
    });
    
    if(fetchRes.ok){
        const txt = await fetchRes.text()
        alertDispatch(  {type: ALERT_ADD, payload: {title: alias + " " + t("ta_alert_wasExecuted"),
                        text: txt, variant: "success"}} as UIAlertAction)
    }
    else{
        const txt = await fetchRes.text()
        alertDispatch(  {type: ALERT_ADD, payload: {title: alias + " " + t("ta_alert_wasNotExecuted"),
            	        text: txt, variant: "danger"}} as UIAlertAction)
    }
}



type Props = {
    user: User
}

export function AdminEventTrigger ({user} : Props) {
    const handleClick = (alias: string) => sendRequestToBackend(alias, user.token);
    
    return(
        <Table>
            <thead>
                <tr>
                    <th className="">Einmalige Server-Aktionen</th>
                    <th className="">Erklärung</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><Button variant="secondary" onClick={() => handleClick("db_backup")}>Datenbank Backup</Button></td>
                    <td>Ein Backup der Datenbank wird erstellt und wird lokal auf dem Server gespeichert.</td>
                </tr>
                <tr>
                    <td><Button variant="danger" onClick={() => handleClick("db_readnew")}>Read New</Button></td>
                    <td>Ein kleiner Neustart des Servers. Die Daten werden im Kotlin gelöscht und neu aus den MySQL Datenbanken gelesen.</td>
                </tr>
            </tbody>
        </Table>
    )
}