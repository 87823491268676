import React from 'react';
import { Container, Col } from 'react-bootstrap';
import { APITournament, User } from '../../Types';
import {TeamAddForm} from './TeamAddForm';
import {SetPairings} from './SetPairings';
import {TournamentSettings} from './TournamentSettings'
import {TriggerTournamentEvent} from './TriggerTournamentEvent'
import {SetGameResultForm} from './SetGameResultForm'
import {SetInfoText} from './SetInfoText'
import {TournamentAdminSideBar} from './TournamentAdminSideBar';
import {TournamentOverview} from './TournamentOverview';
import { useParams } from 'react-router-dom';
//import { getAllGames } from '../../util/Tournament';

type Props = {
    user: User|null,
    tournament: APITournament,
    updateTournament: Function
}

interface RouteParams {
    action: string
}


export function TournamentAdminPage({user, tournament, updateTournament}: Props){
    const params = useParams<RouteParams>();
    if(!user)
        return <Container>Sie müssen sich zuerst anmelden!</Container>

    //console.log(getAllGames(tournament).map(g=>g.id).join(","))
    const actionToDisplay = params.action ?? "see_overview"
    return (
        <>
        <Col className="col-md-3 col-lg-2 d-none d-md-block">
            <TournamentAdminSideBar tournament={tournament}/>
        </Col>
        <Col className="col-12 col-md-9 col-lg-10 col-xl-8 mainFrame">
            {actionToDisplay === "see_overview" && 
                <TournamentOverview tournament={tournament} updateTournament={updateTournament}/>
            }
            {actionToDisplay === "add_team" && 
                <TeamAddForm tournament={tournament} updateTournament={updateTournament}/>
            }
            {actionToDisplay === "set_pairing" && 
                <SetPairings tournament={tournament} updateTournament={updateTournament}/>
            }
            {actionToDisplay === "set_action" && 
                <TriggerTournamentEvent token={user.token} tournament={tournament} updateTournament={updateTournament}/>
            }
            {actionToDisplay === "set_options" && 
                <TournamentSettings token={user.token} tournament={tournament} updateTournament={updateTournament}/>
            }
            {actionToDisplay === "set_result" && 
                <SetGameResultForm token={user.token} tournament={tournament} updateTournament={updateTournament}/>
            }
            {actionToDisplay === "set_info" && 
                <SetInfoText token={user.token} tournament={tournament} updateTournament={updateTournament}/>
            }
        </Col>
        </>
    )
}