import { Container, Row, Table } from "react-bootstrap"
import { APITournament } from "../../../Types"
import { getWinnerAndLosersFromRound } from "../../../util/Round"

function appendTable(table:[(number|null),(number|null)][], teams:(number|null)[], place: number) : number{
    teams.forEach(t=> table.push([t,place]))
    return place+teams.length
}

export function getKoRanks(tournament: APITournament) {
    var table: [(number|null),(number|null)][] = []

    const rounds = tournament.rounds
    var placeCounter = 1
    for (let i=rounds.length-1; i>=0; i--) {
        var [winner,loser,playing] = getWinnerAndLosersFromRound(rounds[i])
        winner = winner.filter(w=>table.findIndex(r=>r[0]===w)===-1)
        loser = loser.filter(w=>table.findIndex(r=>r[0]===w)===-1)
        playing = playing.filter(w=>table.findIndex(r=>r[0]===w)===-1)

        // 3rd place match
        if (i === (tournament.settings?.roundCnt??0)-1) {
            if (winner.length===2 && loser.length===2) {
                table.push([winner[0], 1])
                table.push([loser[0], 2])
                table.push([winner[1], 3])
                table.push([loser[1], 4])
                placeCounter = 5
            }
            else {
                table.push([winner[0], 1])
                table.push([loser[0], 2])
                placeCounter = 3
            }
        }
        // other last round
        else if (i === rounds.length-1) {
            placeCounter = appendTable(table, winner, placeCounter)
            placeCounter = appendTable(table, playing, placeCounter)
            placeCounter = appendTable(table, loser, placeCounter)
        }
        // all previous rounds
        else {
            placeCounter = appendTable(table, loser, placeCounter)
        }
    }

    return table.filter((r,i)=>
        table.findIndex(sr => sr[0] === r[0]) === i
    )
}


type Props = {
    tournament: APITournament
}
export function KoRankings({tournament}: Props) {

    var table: {id: number, place?: number, name?: string, score?: number, dwz?:number}[] = []
    const ranks = getKoRanks(tournament)
    ranks.forEach(row=> {
        if (row[0] !== null)
            table.push({id: row[0]??0, place: row[1]??undefined})
    })

    table.forEach(row=>{
        const team = tournament.teams.find(t=>t.id===row.id)
        if (team) {
            row.name = team.name??undefined
            if (team.players.length===1)
                row.dwz = team.players[0].rating??undefined
        }
    })

    return (
        <>
         <Container fluid="md" className="px-0">
            <Row className="p-3 m-0 d-md-flex justify-content-between">
                <h2 className="px-3 px-md-0">Tabelle</h2>
            </Row>
            <Table className="text-center table-vcenter" size="sm" hover>
                <thead>
                    <tr>
                        <th>Platz</th>
                        <th>Name</th>
                        <th>DWZ</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        table.map((row,key)=>
                            <tr key={key}>
                                <td>{row.place}</td>
                                <td>{row.name}</td>
                                <td>{row.dwz}</td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </Container>
        </>
    )
}