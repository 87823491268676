import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';

import {BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { NavBar } from './components/NavBar';
import { TournamentPage } from './components/TournamentPage';
import { SiteAdminPage } from './components/siteAdmin/SiteAdminPage'
import { HomePage } from './components/guest/HomePage'
import { Footer } from './components/Footer'
import { LinkModal } from './components/player/LinkModal'
import { AlertBox } from './components/AlertBox'
import { FAQ } from './components/guest/FAQ'
import { Login } from './components/player/Login'

import {isLang, t, translation_settings} from './language/translations'

import { DOMAIN, refreshInterval, ALERT_ADD, LOCAL_STORAGE_LANG, LOCAL_STORAGE_TOKEN, LANG_DEFAULT, LOCAL_STORAGE_USER } from './constants'
import {User, UIConfig} from './Types'
import {getRequestHeader} from './util/ApiRequest'

import {FeedbackForm} from './components/guest/FeedbackForm';
import { PrivacyPolicy } from './components/PrivacyPolicy';
import { About } from './components/About';
import { onPageLoad } from './util/RedirectLogin';
import { alertDispatch, setRerenderFunction } from './util/Alerts';
import { isUserEqual } from './util/User';

function getToken(): null|string{
    const queryString = window.location.search
    const params = new URLSearchParams(queryString)
    var token: null|string = params.get("token")
    if(token !== null){
        try {
            localStorage.setItem(LOCAL_STORAGE_TOKEN, token)
        }
        catch (e) {
            console.log(e)
        }
        window.location.href = window.location.pathname
    }
    else{
        const storedToken = localStorage.getItem(LOCAL_STORAGE_TOKEN)
        if(storedToken !== undefined)
            token = storedToken
    }
    return token
}

function getLang(): string {
    // URL
    const queryString = window.location.search
    const params = new URLSearchParams(queryString)
    var lang: null|string = params.get("lang")
    if (lang && isLang(lang)) {
        try {
            localStorage.setItem(LOCAL_STORAGE_LANG, lang)
        }
        catch (e) {
            console.log(e)
        }
        window.location.href = window.location.pathname
        return lang
    }

    // Local Storage
    if (localStorage.getItem(LOCAL_STORAGE_LANG) && isLang(localStorage.getItem(LOCAL_STORAGE_LANG)))
        return localStorage.getItem(LOCAL_STORAGE_LANG)!
    
    // Browser Default
    if (navigator.language && isLang(navigator.language))
        return navigator.language
    
    // Site Default
    return LANG_DEFAULT
}

interface AppProps {

} 

interface AppState {
    token: string|null,
    user: User|null,
    showLinkModal: boolean,
    lang: string
}

export default class App extends React.Component<AppProps,AppState> {
    timer: any

    state: AppState = {
        token: getToken(),
        user: null,//LoadUser(),
        showLinkModal: false,
        lang: getLang()
    }

    constructor(props: AppProps) {
        super(props);
        translation_settings.language = getLang()
        setRerenderFunction(()=>this.forceUpdate())
        localStorage.removeItem(LOCAL_STORAGE_USER);
        onPageLoad()
    }
    
    setNewFrontendConfig (conf: UIConfig) {
        if (conf.refresh_get_my_teams)
            refreshInterval.get_myTeams = conf.refresh_get_my_teams
        if (conf.refresh_get_tournament)
            refreshInterval.get_tournament = conf.refresh_get_tournament
        if (conf.refresh_get_tournaments)
            refreshInterval.get_tournaments = conf.refresh_get_tournaments
        if (conf.refresh_get_user){
            refreshInterval.get_user = conf.refresh_get_user
            clearInterval(this.timer)
            this.timer = setInterval(() => {this.fetchUser()}, conf.refresh_get_user)
        }
    }

    async fetchUser() {
        if(!this.state.token)
            return

        const requestHeader = getRequestHeader (this.state.token)
        const fetchRes = await fetch(DOMAIN + "/get_user", {
            method: 'GET',
            headers: requestHeader
        });
        var res: User = await fetchRes.json();

        //StoreUser(res)

        if (res.frontendConfig)
            this.setNewFrontendConfig(res.frontendConfig)

        if (!isUserEqual(this.state.user, res))
            this.setUser(res)
    }

    componentDidMount() {
        this.fetchUser()
        this.timer = setInterval(() => {this.fetchUser()}, refreshInterval.get_user)
    }

    componentWillUnmount() {
        clearInterval(this.timer)
    }

    setUser = (user: User|null) => {
        this.setState((prevState) => {
            if (user?.nextLink && prevState.user?.nextLink !== user.nextLink) {
                alertDispatch({type: ALERT_ADD, payload: {title: t("pl_link_new"), text: t("pl_link_to"), link: user.nextLink, variant: "info", duration: 120000}})
                return {user: user, showLinkModal: true}
            }
            else
                return {user: user, showLinkModal: prevState.showLinkModal}
        })
    }

    setShowLinkModal = (isVisible: boolean) => {
        this.setState({showLinkModal: isVisible})
    }

    shouldComponentUpdate = (nextState: AppState) => {
        return Object.keys(this.state).some(key => this.state[key as keyof AppState]?.toString !== nextState[key as keyof AppState]?.toString)
    }

    setLang = (lang: string) => {
        try {
            localStorage.setItem(LOCAL_STORAGE_LANG, lang)
        }
        catch (e) {
            console.log(e)
        }
        translation_settings.language = lang
        this.setState({lang: lang})
    }

    render() {
        

        return (
            <div className="App d-flex flex-column h-100">
                {this.state.user && this.state.showLinkModal &&
                    <LinkModal user={this.state.user} show={this.state.showLinkModal} onHide={()=>this.setShowLinkModal(false)}/>
                }
                <Router>
                    <Switch>
                        <Route exact path="/admin">
                            <SiteAdminPage user={this.state.user}/>
                        </Route>
                        <Route exact path="/faq">
                            <NavBar user={this.state.user} tournament={null}/>
                            <FAQ/>
                        </Route>
                        <Route exact path="/feedback">
                            <NavBar user={this.state.user} tournament={null}/>
                            <FeedbackForm/>
                        </Route>
                        <Route exact path="/privacy-policy">
                            <NavBar user={this.state.user} tournament={null}/>
                            <PrivacyPolicy/>
                        </Route>
                        <Route exact path="/about">
                            <NavBar user={this.state.user} tournament={null}/>
                            <About />
                        </Route>
                        {!this.state.user &&
                        <Route exact path="/login">
                            <NavBar user={this.state.user} tournament={null}/>
                            <Login />
                        </Route>
                        }
                        <Route path="/:tournamentId">
                            <TournamentPage user={this.state.user}/>
                        </Route>
                        <Route path="/">
                            <HomePage user={this.state.user}/>
                        </Route>
                    </Switch>
                <AlertBox/>
                <Footer setLang={this.setLang}/>
                </Router>
            </div>
        );
    }
}