import React, { RefObject, useRef } from 'react';
import { Form, Button, Table} from 'react-bootstrap';
import {DOMAIN, ALERT_ADD} from '../../constants';
import { t } from '../../language/translations';
import { APITournament, UIAlertAction } from '../../Types';
import { alertDispatch } from '../../util/Alerts';
import {getRequestHeader} from '../../util/ApiRequest'

const SET_INFO_URL = "/tournament_admin/set_info_text"

async function sendRequestToBackend(alias: string, token: string|null, infoTextRef: RefObject<HTMLTextAreaElement>, 
                                     tournamentId: string){
    var textToSend = infoTextRef.current?.value
    if(textToSend)
        textToSend = encodeURIComponent(textToSend)
    var data = "info_text=" + textToSend

    const requestHeader = getRequestHeader (token)

    const target = DOMAIN + alias + "?" + data + "&tournamentId=" + encodeURIComponent(tournamentId);

    const fetchRes = await fetch(target, {
        method: 'GET',
        headers: requestHeader
    })
    if(fetchRes.ok){
        const txt = await fetchRes.text()
        alertDispatch(  {type: ALERT_ADD, payload: {title: "Der Infotext wurde geändert",
                        text: txt, variant: "success"}} as UIAlertAction)
    }
    else{
        const txt = await fetchRes.text()
        alertDispatch(  {type: ALERT_ADD, payload: {title: "Der Infotext konnte nicht geändert werden",
            	        text: txt, variant: "danger"}} as UIAlertAction)
    }
}

export function SetInfoText({token, tournament, updateTournament}: {token: string|null, tournament: APITournament, updateTournament: Function}){
    const infoTextRef   = useRef<HTMLTextAreaElement>(null)

    const handleClick = async (alias: string) => { 
        await sendRequestToBackend(alias, token, infoTextRef, tournament.id) 
        updateTournament()
    }

    return (
        <Form>
            <h2 className="p-3">
                {t("set_info")}
            </h2>
            <Form.Group controlId="admin-setInfoText">
                <Form.Control ref={infoTextRef} as="textarea" rows={5} placeholder="Hallo Schachfreunde, ..." defaultValue={tournament.infoText??""}/>
                <Form.Text className="text-muted">
                    Dieser Text wird allen Besuchern der Turnierseite oben angezeigt.
                </Form.Text>
                <Form.Text className="text-muted">
                    {t("HtmlIsSupported")}
                    <Table size="sm" className="col-3">
                        <thead>
                            <tr>
                                <th>Funktion</th>
                                <th>HTML-Code</th>
                                <th>Vorschau</th>
                            </tr>
                        </thead>
                        <tbody>
                            {[
                                {what: "Zeilenumbruch", html: "A<br/>B", preview: <>A<br/>B</>},
                                {what: "Link", html: "<a href=\"schachklub-kelheim.de\">hier</a>", preview: <a href="schachklub-kelheim.de">hier</a>},
                                {what: "Fett", html: "<b>Text</b>", preview: <b>Text</b>},
                                {what: "Kursiv", html: "<i>Text</i>", preview: <i>Text</i>},
                                {what: "Unterstreichen", html: "<u>Text</u>", preview: <u>Text</u>}
                            ].map((line, key)=>
                                <tr key={key}>
                                    <td>{line.what}</td>
                                    <td>{line.html}</td>
                                    <td>{line.preview}</td>
                                </tr>
                            )
                            }
                        </tbody>
                    </Table>                
                </Form.Text>
            </Form.Group>
            <Button type="button" className="mb-2" onClick={() => {handleClick(SET_INFO_URL)}}>
                {t("infotext_send")}
            </Button>
        </Form>
    )
}