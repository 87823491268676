import { KO_ROUND_NAMES } from '../constants'
import {APIGame, APIRound, APITeam} from '../Types'
import { koMatchWinner } from './Match'

export function getAllGamesFromRound(round: APIRound):APIGame[] {
    return round.matches.reduce((acc,m)=>acc.concat(m.games),[] as APIGame[])
}

export function getAllGamesFromRoundForTeam(round: APIRound, teamId: number):APIGame[] {
    return round.matches.filter(m=>m.firstTeamId===teamId||m.secondTeamId===teamId).reduce((acc,m)=>acc.concat(m.games),[] as APIGame[])
}

export function getLiveGames(round: APIRound):APIGame[] {
    var liveGames = [] as APIGame[]
    round.matches.forEach(m=>m.games.forEach(g=>{if (g.result===null) liveGames.push(g)}))
    return liveGames
}

export function getLiveGamesCount (round: APIRound): number {
    var count = 0
    round.matches.forEach(m=>m.games.forEach(g=>{if (g.result===null) count++}))
    return count
}

export function isTeamPaired (round: APIRound, teamId: number) {
    return round.matches.some(m=>m.firstTeamId===teamId||m.secondTeamId===teamId)
}

export function getUnpairedTeams (round: APIRound, teams: APITeam[]) {
    return teams.filter(t=>!isTeamPaired(round, t.id))
}

export function getKoRoundName (roundsToPlay: number|null, currentRound: number): string {
    const delta = (roundsToPlay??0) - currentRound
    if (delta >= 0 && delta < KO_ROUND_NAMES.length)
        return KO_ROUND_NAMES[delta]
    return currentRound +". Runde"
}

export function getWinnerAndLosersFromRound(round: APIRound): [(number|null)[], (number|null)[], (number|null)[]] {
    var winnerTeamIds: (number|null)[] = []
    var loserTeamIds: (number|null)[] = []
    var playingTeamIds: (number|null)[] = []

    round.matches.forEach(match=> {
        const winner = koMatchWinner(match)
        if (winner === 1) {
            winnerTeamIds.push(match.firstTeamId)
            loserTeamIds.push(match.secondTeamId)
        }
        else if (winner === 2){
            winnerTeamIds.push(match.secondTeamId)
            loserTeamIds.push(match.firstTeamId)
        }
        else {
            playingTeamIds.push(match.secondTeamId)
            playingTeamIds.push(match.firstTeamId)
        }
        
    })

    return [winnerTeamIds,loserTeamIds,playingTeamIds]
}