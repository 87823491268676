import {APIPlayer, APITournamentSettings} from '../Types'

export function isPlayerActive (player: APIPlayer, isStrictMode: boolean): boolean {
    return !player.isPaused && (player.isRegistered || !isStrictMode)
}

export function isPlayerPaused (player: APIPlayer, board: number, settings: APITournamentSettings) {
    return player.isPaused || (settings.isStrictMode && !player.isRegistered) || board > settings.boardCount
}

export function getPlayersReady (players: APIPlayer[], isStrictMode: boolean): APIPlayer[] {
    return players.filter(p=>isPlayerActive(p, isStrictMode))
}

export function getPlayersNotReady (players: APIPlayer[], isStrictMode: boolean): APIPlayer[] {
    return players.filter(p=>!isPlayerActive(p, isStrictMode))
}

export function getPlayersNotReadyAsString (players: APIPlayer[], isStrictMode: boolean): string {
    return getPlayersNotReady(players, isStrictMode).map(p=>p.name).join(", ")
}