import React, { RefObject, useRef } from 'react'
import { ALERT_ADD, DOMAIN_TEAMLEAD } from '../../constants';
import { Form, Button, Table} from 'react-bootstrap';
import { APITournament, APITeam, UIAlertAction, APIPlayerQuery } from '../../Types';
import { isLichessUserName } from '../../util/Validation';
import { t } from '../../language/translations';
import { PersonPlusFill } from 'react-bootstrap-icons';
import { sendQueryPlayer, sendRequestToBackendText } from '../../util/ApiRequest'
import { alertDispatch } from '../../util/Alerts';

const DOMAIN_ADD_PLAYER = DOMAIN_TEAMLEAD + "/add_player"

async function sendRequestToBackend(lichessRef: RefObject<HTMLInputElement>, player: APIPlayerQuery, tournamentId: string, teamId: number){
    if (!lichessRef.current || !(await isLichessUserName(lichessRef.current.value.trim()))){
        alertDispatch({type: ALERT_ADD, payload: {title: t("tl_reg_fail"), text: t("lichessNameNotFound"), variant: "danger"}} as UIAlertAction)
        lichessRef.current?.classList.add("border-danger")
        return false
    }

    const data = {
        rating: player.dwz===0 ? null : player.dwz,
        tournamentId: tournamentId,
        teamId: teamId,
        lichess: lichessRef.current.value.trim(),
        prename: player.firstname.trim()==="" ? null : player.firstname.trim(),
        surname: player.lastname.trim()==="" ? null : player.lastname.trim(),
    }

    const isSuccess = await sendRequestToBackendText(DOMAIN_ADD_PLAYER, data)
    if(isSuccess !== false){
        lichessRef.current?.classList.remove("border-danger")
        alertDispatch({type: ALERT_ADD, payload: {title: "Spieler hinzugefügt", text: isSuccess, variant: "success"}} as UIAlertAction)
    }
    return isSuccess
}

type Props = {
    tournament: APITournament,
    team: APITeam,
    updateTeams: Function
}

export function TeamLeadRegPlayer({tournament, team, updateTeams}: Props){
    const [playerSelection, setPlayerSelection] = React.useState([] as APIPlayerQuery[])
    
    const lichessNameRef = useRef<HTMLInputElement>(null)
    const prenameRef     = useRef<HTMLInputElement>(null)
    const surnameRef     = useRef<HTMLInputElement>(null)
    const ratingRef      = useRef<HTMLInputElement>(null)

    const handleClick = async(player?: APIPlayerQuery) => {
        var hasStored: string|boolean = false

        if (player)
            hasStored = await sendRequestToBackend( lichessNameRef, player, tournament.id, team.id)
        else{
            const ratingStr = ratingRef.current?.value??null
            const rating = ratingStr===null ? null : parseInt(ratingStr)
            hasStored = await sendRequestToBackend( lichessNameRef, 
                                        {
                                            firstname: (prenameRef.current?.value??""),
                                            lastname: (surnameRef.current?.value??""),
                                            dwz: rating

                                        } as APIPlayerQuery,
                                        tournament.id, team.id)
        }

        if (hasStored) {
            lichessNameRef.current!.value = ""
            prenameRef.current!.value = ""
            surnameRef.current!.value = ""
            ratingRef.current!.value = ""
            setPlayerSelection([])
        }
        
        updateTeams()
    }

    const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>)  => {
        if(event.key==="Enter")
            handleClick()
        else 
            handleAutocompleteChange()
    }

    const handleAutocompleteChange = async () => {
        const firstNamePart = prenameRef.current?.value??""
        const lastNamePart = surnameRef.current?.value??""

        if (firstNamePart.length + lastNamePart.length > 1) {
            const players = await sendQueryPlayer(tournament.id, team.id, firstNamePart, lastNamePart)
            setPlayerSelection(players) 
        }
        else 
            setPlayerSelection([]) 
    }
    
    return(
        <Form>
            <h2>{t("addPlayerToTeam")}</h2>
            <Table size="sm" hover>
                <thead>
                    <tr>
                        <th className="autocomplete-heading"><Form.Control ref={lichessNameRef} placeholder={t("lichessName")} onKeyUp={handleKeyUp}/></th>
                        <th className="autocomplete-heading"><Form.Control ref={surnameRef} placeholder={t("surname")} onKeyUp={handleKeyUp}/></th>
                        <th className="autocomplete-heading"><Form.Control ref={prenameRef} placeholder={t("prename")} onKeyUp={handleKeyUp}/></th>
                        <th className="autocomplete-heading"><Form.Control ref={ratingRef} placeholder={t("rating")} onKeyUp={handleKeyUp}/></th>
                        <th className="autocomplete-heading">
                            <Button type="button" className="mb-2" onClick={() => {handleClick()}}>
                                <PersonPlusFill width="20px"/>
                            </Button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {playerSelection.map((player,key)=>
                        <tr className="isPaused" key={key}>
                            <td></td>
                            <td className="px-3">{player.lastname}</td>
                            <td className="px-3">{player.firstname}</td>
                            <td className="px-3">{player.dwz}</td>
                            <td>
                                <Button type="button" className="mb-2" onClick={() => {handleClick(player)}} size="sm">
                                    <PersonPlusFill width="20px"/>
                                </Button>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </Form>
    )
}