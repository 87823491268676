import { ALERT_ADD, ALERT_DISPLAY_INTERVAL, ALERT_EMPTY, ALERT_REMOVE } from '../constants'
import {UIAlert, UIAlertAction} from '../Types'
import { uuid } from './uuid'

type myGlobType = {
    alerts: UIAlert[],
    rerenderApp: Function
}

var myGlobal: myGlobType = {alerts: [], rerenderApp: ()=>{}}

function alertReducer (alerts: UIAlert[], action: UIAlertAction): UIAlert[] {
    switch (action.type){
        case ALERT_ADD:
            if (action.payload) {
                action.payload.id = uuid()
                if (action.payload.variant === "info")
                    return [action.payload, ...alerts.filter(alert => alert.variant!=="info")]
                else
                    return [action.payload, ...alerts]
            }
            else
                return alerts
        case ALERT_REMOVE:
            if (action.payload === null)
                return alerts.slice(0,-1)
            return alerts.reduce((newAlerts, cur) => {
                if (cur.id === action.payload?.id)
                    return [...newAlerts]
                else
                    return [...newAlerts, cur]
            }, [] as UIAlert[])
        case ALERT_EMPTY:
            return [] as UIAlert[]
        default:
            return alerts
    }
}

export function alertDispatch (newAction: UIAlertAction) {
    myGlobal.alerts = alertReducer(myGlobal.alerts, newAction)
    if (newAction.type === ALERT_ADD && (newAction.payload?.duration ?? 1) > 0) {
        setTimeout(()=>{
                myGlobal.alerts = alertReducer( myGlobal.alerts, {type: ALERT_REMOVE, payload:null})
                myGlobal.rerenderApp()
            },
            newAction.payload?.duration ?? ALERT_DISPLAY_INTERVAL
        )
    }
    myGlobal.rerenderApp()
}

export function getAlerts() {
    return myGlobal.alerts
}

export function setRerenderFunction(rerenderFunction: Function) {
    myGlobal.rerenderApp = rerenderFunction
}