import React, { RefObject, useRef } from 'react';
import { Form, Col, Row, Button, InputGroup} from 'react-bootstrap';
import {ALERT_ADD, DOMAIN_ADMINISTRATOR} from '../../constants';
import {UIAlertAction} from '../../Types';
import { alertDispatch } from '../../util/Alerts';
import { sendRequestToBackendText} from '../../util/ApiRequest'
import { isLichessUserName } from '../../util/Validation';

const DOMAIN_ADD_TOURNAMENT = DOMAIN_ADMINISTRATOR + "/add_tournament"


async function AddTournament       (nameRef: RefObject<HTMLInputElement>,
                                    aliasRef: RefObject<HTMLInputElement>, 
                                    adminRef: RefObject<HTMLInputElement>){
    const admins = (adminRef.current?.value ?? "").split(",").map(admin => admin.trim())

    if (admins.some(admin => !isLichessUserName(admin))){
        alertDispatch({type: ALERT_ADD, payload: {title: "Das Turnier wurde nicht erstellt", text: "Ein Lichess-Name wurde nicht gefunden.", variant: "danger"}} as UIAlertAction)
        adminRef.current?.classList.add("border-danger")
        return
    }

    const data = {
        name: nameRef.current?.value ?? "",
        alias: aliasRef.current?.value ?? "",
        admins: admins
    }

    const isSuccess = await sendRequestToBackendText(DOMAIN_ADD_TOURNAMENT, data)

    if(isSuccess !== false){
        nameRef.current!.value = ""
        aliasRef.current!.value = ""
        adminRef.current!.value = ""
        adminRef.current?.classList.remove("border-danger")
        alertDispatch({type: ALERT_ADD, payload: {title: "Turnier wurde erstellt", text: isSuccess, variant: "success"}} as UIAlertAction)
    }
    else{
    }
}

export function TournamentAddForm(){
    const nameRef = useRef<HTMLInputElement>(null)
    const aliasRef = useRef<HTMLInputElement>(null)
    const adminRef = useRef<HTMLInputElement>(null)

    const labelRef = useRef<HTMLSpanElement>(null)

    const handleClick = () => AddTournament(nameRef, aliasRef, adminRef);
    const handleNameChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        labelRef.current!.innerText = event.currentTarget.value.length.toString() + "/50"
    };

    return(
        <Form>
            <h2>
                Turnier erstellen
            </h2>
            <Form.Group as={Row}>
                <Form.Label column md="3" xs="4">
                    Turnier-Name
                </Form.Label>
                <Col  md="7" xs="6">
                    <InputGroup className="mb-2">
                        <Form.Control ref={nameRef} placeholder="" onChange={handleNameChange}/>
                        <InputGroup.Append>
                            <InputGroup.Text ref={labelRef}>0/50</InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
            </Form.Group>

            <Form.Group as={Row}>
                <Form.Label column md="3" xs="4">
                    Turnier-Alias
                </Form.Label>
                <Col md="7" xs="6">
                    <Form.Control ref={aliasRef} placeholder="" />
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Form.Label column md="3" xs="4">
                    Turnier-Admins
                </Form.Label>
                <Col md="7" xs="6">
                    <InputGroup className="mb-2">
                        <Form.Control ref={adminRef} placeholder="e.g. gemuesekinder,techtobi,lena2345,kapfel" />
                        <InputGroup.Append>
                            <InputGroup.Text>durch Komma getrennt</InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
            </Form.Group>
            <Row>
                <Col>
                    <Button type="button" className="mb-2" onClick={() => {handleClick()}}>
                        Turnier erstellen
                    </Button>
                </Col>
            </Row>
        </Form>
    )
}