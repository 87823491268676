import React from 'react'
import { Container, Tab, Row, Col, Nav } from 'react-bootstrap'
import { t } from '../../language/translations'
import { APITournament } from '../../Types'
import { PlayerResults } from './PlayerResults'


type Props = {
    tournament: APITournament
}

export function SinglePlayerResults ({tournament} : Props) {
    const boardPlayer = Array.from({length: tournament.settings.boardCount}).map( (_, key) => 
        tournament.teams.filter(team => team.players.length>key).map(team => {
            if (key === tournament.settings.boardCount-1)
                return team.players.filter((_,rank) => rank>=key)
            else
                return team.players[key]
        }).flat()
    )

    return (
        <Container fluid="md" className="p-0 py-1 p-md-3">
            <Tab.Container id="singlePlayerResultsTab" defaultActiveKey="event_0" transition={false}>
                <Col>
                    <Row className="">
                        <h2 className="mr-3">{t("board")}</h2>
                        <Nav variant="pills" className="flex-row">
                            { Array.from({length: tournament.settings.boardCount}).map( (_, key) => 
                                <Nav.Item key={key}>
                                    <Nav.Link eventKey={"event_"+key}>{key+1}</Nav.Link>
                                </Nav.Item>
                            )}
                        </Nav>
                    </Row>
                    <Row sm={10} xl={8}>
                        <Tab.Content>
                            { Array.from({length: tournament.settings.boardCount}).map( (_, key) => 
                                <Tab.Pane eventKey={"event_"+key} key={key}>
                                    <PlayerResults tournament={tournament} players={boardPlayer[key]} sort={true}/>
                                </Tab.Pane>
                            )}
                        </Tab.Content>
                    </Row>
                </Col>
            </Tab.Container>
        </Container>
    )
}