import { Button, ButtonGroup } from "react-bootstrap";
import { APIGame, APIMatch,  APITeam,  APITournament } from "../../../Types";
import { koMatchWinner } from "../../../util/Match";

function KoLiveButtonLabel(isFirstWhite: boolean) {
    return (
        <>
        <div className={"ko-game-color ko-color-indicator-" + (isFirstWhite?"white":"black")} />
        Live
        <div className={"ko-game-color ko-color-indicator-" + (isFirstWhite?"black":"white")} />
        </>
    )
}

function KoGameButton(link:string|null, variant: string, label: JSX.Element) {
    return (
        <Button variant={variant} href={link!==null?link:""} target="_blank" size="sm" className="ko-game-button" disabled={link===null}>
            {label}
        </Button>
    );
}

function Game({game, index}: {game: APIGame, index: number}){
    const gameLink = "https://lichess.org/" + game.id
    function getResult(result: number){
        switch(result){
            case 1: return "½"
            default: return (result/2).toString()
        }
    }

    if(game.result===null){
        if (game.id===null)
            return KoGameButton(null, "outline-success", <div>-</div>)
        return KoGameButton(gameLink, "success", KoLiveButtonLabel(game.isFirstWhite??true))
    }
    else if(index <= 3 || game.result !== 1)
        return KoGameButton(gameLink, "outline-secondary", 
            <>
                <div className={game.isFirstWhite ? "ko-game-button-white" : "ko-game-button-black"}>
                    {getResult(game.result)}
                </div>
                <div className={game.isFirstWhite ? "ko-game-button-black" : "ko-game-button-white"}>
                    {getResult(2-game.result)}
                </div>
            </>
        )
    else
        return null
}

function GameTable({games}: {games: APIGame[]}){
    return (
        <ButtonGroup>           
            {games.map((game, index) => (<Game game={game} index={index} key={index}/>)).filter(x => x!==null)}
        </ButtonGroup>
    )
}


function cssClassPlayer(match: APIMatch, playerNum: number){
    const winner = koMatchWinner(match)
    if(winner === undefined)
    return "";
    else if (winner === playerNum)
    return "ko-winner";
    else
    return "ko-loser";
}

type Props = {
    tournament: APITournament,
    matchOrNum: APIMatch|number|null,
    id: string
}
export function KoMatch({tournament, matchOrNum: match, id}: Props) {
    if (match===null)
        return LuckyLoserMatch(null, id)
    if (typeof(match) === "number") {
        const team = tournament.teams.find(team=>team.id===match)
        if (team === undefined)
            return EmptyMatch(id)
        return LuckyLoserMatch(team, id)
    }

    const team1 = tournament.teams.find(team=>team.id === match.firstTeamId)
    const team2 = tournament.teams.find(team=>team.id === match.secondTeamId)

    return (
        <>
            <div className="ko-match ko-match-row" id={id}>
                <div className="ko-name-col">
                    <div className={cssClassPlayer(match, 1) + " ko-name"}>
                        {team1?.name??"spielfrei"}
                    </div>
                    <div className={cssClassPlayer(match, 2) + " ko-name"}>
                        {team2?.name??"spielfrei"}
                    </div>
                </div>
                <div className="ko-game-col">
                    <GameTable games={match.games}/>
                </div>
            </div>
        </>
    )
}

export function LuckyLoserMatch(team: APITeam|null, id: string) {
    return (
        <div className="ko-match ko-match-row" id={id}>
            Hier steigt ein Lucky Loser ein:<br/>
            <div className="ko-winner">{team?.name??"Darum wird in einer Arena gekämpft"}</div>
        </div>
    )
}

export function EmptyMatch(id: string) {
    return (
        <div className="ko-match ko-match-placeholder"></div>
    )
}