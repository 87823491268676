import React, { RefObject, useRef } from 'react';
import { Form, Col, InputGroup,FormControl, Button} from 'react-bootstrap';
import {DOMAIN, RESULT, ALERT_ADD} from '../../constants';
import { t } from '../../language/translations';
import { APITournament, UIAlertAction } from '../../Types';
import { alertDispatch } from '../../util/Alerts';
import {getRequestHeader} from '../../util/ApiRequest'
import {findGame} from '../../util/Tournament'

const SET_RESULT_URL    = "/tournament_admin/set_game_result"
const DELETE_URL        = "/tournament_admin/delete_game"

async function sendRequestToBackend(alias: string, token: string|null, setGameRef: RefObject<HTMLInputElement>,
                                     setResultRef: RefObject<HTMLSelectElement>,
                                     tournamentId: string){
    var data = "game_id=" + setGameRef.current?.value + "&action=" + setResultRef.current?.value
    if (setResultRef.current?.value === "delete")
        alias = DELETE_URL

    const requestHeader = getRequestHeader (token)

    const target = DOMAIN + alias + "?" + data + "&tournamentId=" + tournamentId;

    const fetchRes = await fetch(target, {
        method: 'GET',
        headers: requestHeader
    })
    if(fetchRes.ok){
        const txt = await fetchRes.text()
        if (alias === SET_RESULT_URL)
            alertDispatch(  {type: ALERT_ADD, payload: {title: "Das Ergebnis der Partie-ID \""+ (setGameRef.current?.value ?? "") + "\" wurde geändert",
                            text: txt, variant: "success"}} as UIAlertAction)
        else
            alertDispatch(  {type: ALERT_ADD, payload: {title: "Die Partie \""+ (setGameRef.current?.value ?? "") + "\" wurde gelöscht",
                            text: txt, variant: "success"}} as UIAlertAction)
        
        setGameRef.current!.value = ""
    }
    else{
        const txt = await fetchRes.text()
        alertDispatch(  {type: ALERT_ADD, payload: {title: "Das Ergebnis konnte nicht geändert werden",
            	        text: txt, variant: "danger"}} as UIAlertAction)
    }
}

function GameIdFromGET (): null|string {
    const queryString = window.location.search
    const params = new URLSearchParams(queryString)
    return params.get("gameId")
}

export function SetGameResultForm({token, tournament, updateTournament}: {token: string|null, tournament: APITournament, updateTournament: Function}){
    const setGameRef    = useRef<HTMLInputElement>(null)
    const setResultRef  = useRef<HTMLSelectElement>(null)
    const whiteRef  = useRef<HTMLInputElement>(null)
    const blackRef  = useRef<HTMLInputElement>(null)

    const handleClick = async (alias: string) => { 
        await sendRequestToBackend(alias, token, setGameRef, setResultRef, tournament.id) 
        updateTournament()
    }
    const handleGameIdChange = () => {
        const game = findGame(tournament, setGameRef.current?.value??"")
        if(!game) {
            whiteRef.current!.value = ""
            blackRef.current!.value = ""
            return
        }
        whiteRef.current!.value = game.isFirstWhite ? (game?.player1?.name??"") : (game?.player2?.name??"")
        blackRef.current!.value = game.isFirstWhite ? (game?.player2?.name??"") : (game?.player1?.name??"")
    }

    React.useEffect(()=>{
        handleGameIdChange()
    },[])

    return (
        <Form>
            <h2 className="p-3">
                {t("set_result")}
            </h2>
            <Form.Row className="align-items-center">
                <Col xs="5">
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                        <InputGroup.Text>Game-ID</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl ref={setGameRef} placeholder="e.g. TCtZMbg0" defaultValue={GameIdFromGET()??""} onInput={()=>handleGameIdChange()}/>
                    </InputGroup>
                </Col>
                <Col xs="2">
                    <InputGroup className="mb-2">
                        <FormControl ref={setResultRef} as="select">
                            <option value={RESULT.player1Won}>1 - 0</option>
                            <option value={RESULT.draw}>½ - ½</option>
                            <option value={RESULT.player2Won}>0 - 1</option>
                            <option value={"delete"}>{t("delete")}</option>
                        </FormControl>
                    </InputGroup>
                </Col>
                <Col xs="auto">
                    <Button type="button" className="mb-2" onClick={() => {handleClick(SET_RESULT_URL)}}>
                        {t("changeResult")}
                    </Button>
                </Col>
            </Form.Row>
            <Form.Row>
                <Col xs="5">
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                        <InputGroup.Text>{t("white")}</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl ref={whiteRef} disabled/>
                    </InputGroup>
                </Col>
                <Col xs="5">
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                        <InputGroup.Text>{t("black")}</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl ref={blackRef} disabled/>
                    </InputGroup>
                </Col>
            </Form.Row>
        </Form>
    )
}