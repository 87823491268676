import React from 'react'
import { Container, Col, Alert } from 'react-bootstrap';
import {TeamLeadRegPlayer} from './TeamLeadRegPlayer';
import {EditTeamTable} from './EditTeamTable';

import { APITeam, APITournament, User } from '../../Types';
import { refreshInterval } from '../../constants';
import { GetMyTeams, LoadMyTeams, StoreMyTeams } from '../../util/api/FetchMyTeams';
import { useParams } from 'react-router-dom';
import { TeamLeadSideBar } from './TeamLeadSideBar';
import useTimeout from '../../util/CustomHook/useTimeout';
import { isTournamentAdminOrHigher } from '../../util/Rights';
import { sortFunctionForTeams } from '../../util/Team';
import { t } from '../../language/translations';

type Props = {
    user: User|null,
    tournament: APITournament
} 

interface RouteParams {
    teamId: string
}

function getDefaultTeams(lichessname: string|null, teams: APITeam[]) {
    var defaultTeams = LoadMyTeams()
    defaultTeams = defaultTeams.filter(team => teams.some(t=>t.id===team.id))
    defaultTeams = teams.filter(team => team.teamleaders.some(teamleader=> teamleader===lichessname))
    return defaultTeams
}

export function TeamLeadPage({user, tournament}: Props){
    const params = useParams<RouteParams>();
    const [myTeams, setMyTeams] = React.useState(getDefaultTeams(user?.lichessName??null, tournament.teams) as APITeam[])

    const updateTeams = React.useCallback(async function () {
        if (user) {
            const res: APITeam[] = await GetMyTeams(tournament.id)
            res.sort(sortFunctionForTeams("alphabetical"))
            StoreMyTeams(res)
            setMyTeams(res)
        }
    },[tournament.id, user])

    React.useEffect(() => {updateTeams()},[updateTeams])

    useTimeout(updateTeams, refreshInterval.get_myTeams)

    if(!user)
        return <Container>Sie müssen sich zuerst anmelden!</Container>
    
    const teamToDisplay = myTeams.find(team => team.id === parseInt(params.teamId)) ?? (myTeams.length>0 ? myTeams[0] : null)
    return(
        <>
            <Col className="col-md-3 col-lg-2 d-none d-md-flex">
                <TeamLeadSideBar tournament={tournament} teams={myTeams} />
            </Col>
            <Col className="col-12 col-md-9 col-lg-10 col-xl-8 mainFrame">
                { teamToDisplay &&
                    <>
                        <h2 className="p-3">
                        {teamToDisplay.name}
                        </h2>
                        <EditTeamTable user={user} tournament={tournament} updateTeams={updateTeams} team={teamToDisplay}/>
                        {(tournament.rounds.length===0 || isTournamentAdminOrHigher(tournament,user)) ?
                            <TeamLeadRegPlayer tournament={tournament} team={teamToDisplay} updateTeams={updateTeams}/> :
                            <Alert variant="info">{t("tl_noReg")}</Alert>
                        }
                    </>
                }
                {teamToDisplay === null &&
                    <b>{t("noTeamsForTeamleaderYet")}</b>
                }
            </Col>
        </>
    )
}