import { Container, Button } from "react-bootstrap";
import { REGISTER } from "../../constants";
import { t } from "../../language/translations";

export function Login () {
    return (
        <Container>
            <h2>
                {t('loginTitle')}
            </h2>
            <p>
                {t('loginText')}
            </p>
            <p>
                <small>
                {t('loginAgreementText')}
                <ul>
                    {t('loginAgreementItems')?.map((a:string,key:number)=>
                        <li key={key}>{a}</li>
                        )}
                </ul>
                </small>
            </p>
            <Button variant="success" href={REGISTER}>
                {t('loginAccept')}
            </Button>
        </Container>
    )
}