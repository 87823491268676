import { Container } from "react-bootstrap";
import { TITLE_PREFIX } from "../constants";

export function About () {
    document.title = TITLE_PREFIX + "Über uns"

    return (
        <Container>
        <h1>Kelheimer Turnierseite für Mannschaftsturniere mit Lichess</h1>
        <h2>Unsere Motivation</h2>
        <p>
            Lichess hat viele Möglichkeiten. Turniere mit Einzelspielern oder Teamturniere im Arenamodus sind uns allen bekannt. Eine Sache haben wir im Online-Schach aber schmerzlich vermisst - klassische Mannschaftsturniere. Mit unserer Seite wollen wir dieses Angebot erweitern.
        </p>

        <h2>Turniere in Kelheim</h2>
        <p>
            Der <a href="https://schachklub-kelheim.de" target="_blank" rel="noreferrer">Schachklub Kelheim</a> hat schon verschiedenste Projekte initiiert. Unser breit aufgestelltes Team hat sich mit vielen Aktionen, Trainingslagern und Schachturnieren einen Namen gemacht. Aus gegebenem Anlass liegt der Fokus derzeit auf Online-Schach.
        </p>
        <p>
            Wir wollen aber nicht nur Schach in Kelheim fördern, sondern auch überregional. Deshalb kann mit unserer Seite jeder kostenlos Mannschaftsturniere ausrichten. Damit leisten wir unseren Beitrag für unsere Lieblingssportart. Das Angebot wird gut angenommen: Neben unzähligen weiteren Turnieren fanden über unsere Seite für Mannschaftsturniere schon Turniere der DSJ im Schweizer System und Schulschach-Turniere mehrerer Bundesländer statt, auch im Rundensystem.
        </p>

        <h2>Schweizer System</h2>
        <p>
            Bei Schachturnieren ist das Schweizer System an der Tagesordnung. Es gehört zu den beliebtesten Spielmodi und wir werden dafür in der Sportwelt beneidet. Entsprechend war es für uns klar, dass wir auf dieser Seite das Schweizer System für Teamturniere ermöglichen wollen. Dazu benutzen wir den gleichen Paarungsalgorithmus wie Lichess selbst.
        </p>

        <h2>Rundensystem</h2>
        <p>
            Wenn man überlegt, Turniere, Ligen, Meisterschaften oder Qualifikationen online auszuspielen, ist das Rundensystem immer eine beliebte Option. Deshalb wird der Turnierleiter auf dieser Turnierseite auch dabei unterstützt.
        </p>

        <h2>Konferenzen in Kelheim</h2>
        <p>
            Neben dieser Turnierseite für Mannschaftsturniere betreiben wir auch einen BigBlueButton- und einen Teamspeakserver um die virtuelle Kommunikation mit (Video-)Telefonie sicherzustellen. Auf Anfrage stellen wir diese Infrastruktur gerne Schachfreunden zur Verfügung. Seit März 2020 sammeln wir unsere Erfahrungen im Online-Schach und teilen sie gerne. Schreibt uns einfach (<a href="mailto:turniere@schachklub-kelheim.de">turniere@schachklub-kelheim.de</a>).
        </p>

        <h2>Training in Kelheim</h2>
        <p>
            Wir bieten aber nicht nur Möglichkeiten für Turniere und Konferenzen. Auch für Aufgaben und Trainingsmaterialien aller Art haben wir eine Plattform geschaffen. Unsere Moodle-Installation ist unter <a href="https://training.schachklub-kelheim.de">training.schachklub-kelheim.de</a> erreichbar. Sie eignet sich ideal, um Unterlagen und Lösungen mit Schülern auszutauschen. Auch wenn du daran Interesse hast, kannst du uns gerne schreiben!
        </p>
        <h2>Unser Team</h2>
        <p>
            Diese vielfältigen Möglichkeiten kann natürlich niemand alleine auf die Beine stellen. In Kelheim haben wir traditionell ein großes Team engagierter Schachspieler. So wird auch die Entwicklung und Betreuung dieser Turnierseite für Lichess Mannschaftsturniere von mehreren Ehrenamtlichen ermöglicht.
        </p>
        <p>
            Constantin Blodig und Tobias Schindler haben die Seite programmiert und entwickeln sie stetig weiter. Lena Antczak und Cornelius Mühlich haben mit der Organisation zahlreicher (Test-)Turniere im Runden- und Schweizer System unsere Turnierplattform in Schwung gebracht und schieben weiter an.
        </p>
        <p>
            Bedanken wollen wir uns aber auch bei den schon über 1500 Spielern auf unserer Seite. Auch ihr könnt mit Feedback, Bug-Reports und Vorschlägen zur Weiterentwicklung unserer Kelheimer Turnierseite einen wertvollen Beitrag leisten. Teilt uns gerne Verbesserungsvorschäge, Anregungen und eure Erfahrungen auf unserem Tool unter <a href="/feedback">Feedback</a> mit.
        </p>
        </Container>
    )
}
