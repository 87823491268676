import React, { RefObject, useRef } from 'react';
import { Form, Col, Row, Button, InputGroup} from 'react-bootstrap';
import {ALERT_ADD, DOMAIN_ADMINISTRATOR} from '../../constants';
import {UIAlertAction} from '../../Types';
import { alertDispatch } from '../../util/Alerts';
import { sendRequestToBackendText} from '../../util/ApiRequest'
import { isLichessUserName } from '../../util/Validation';

const DOMAIN_SET_REALNAME = DOMAIN_ADMINISTRATOR + "/set_realname"


async function renameUser (lichessNameRef: RefObject<HTMLInputElement>,
                           realNameRef:    RefObject<HTMLInputElement>,){
    const lichessName = lichessNameRef.current?.value ?? ""

    if (!isLichessUserName(lichessName)){
        alertDispatch({type: ALERT_ADD, payload: {title: "Das Turnier wurde nicht erstellt", text: "Ein Lichess-Name wurde nicht gefunden.", variant: "danger"}} as UIAlertAction)
        lichessNameRef.current?.classList.add("border-danger")
        return
    }

    const data = {
        lichessName: lichessName,
        name: realNameRef.current?.value ?? ""
    }

    const isSuccess = await sendRequestToBackendText(DOMAIN_SET_REALNAME, data)

    if(isSuccess !== false){
        lichessNameRef.current!.value = ""
        realNameRef.current!.value = ""
        lichessNameRef.current?.classList.remove("border-danger")
        alertDispatch({type: ALERT_ADD, payload: {title: "Name wurde geändert", text: isSuccess, variant: "success"}} as UIAlertAction)
    }
    else{
    }
}

export function RenameUserForm(){
    const lichessNameRef = useRef<HTMLInputElement>(null)
    const realNameRef = useRef<HTMLInputElement>(null)

    const handleClick = () => renameUser(lichessNameRef, realNameRef);

    return(
        <Form>
            <h2>
                User-Namen ändern
            </h2>
            <Form.Group as={Row}>
                <Form.Label column md="3" xs="4">
                    Lichess-Name
                </Form.Label>
                <Col  md="7" xs="6">
                    <Form.Control ref={lichessNameRef} placeholder=""/>
                </Col>
            </Form.Group>

            <Form.Group as={Row}>
                <Form.Label column md="3" xs="4">
                    Echt Name
                </Form.Label>
                <Col md="7" xs="6">
                    <InputGroup className="mb-2">
                        <Form.Control ref={realNameRef} placeholder="" />
                        <InputGroup.Append>
                            <InputGroup.Text>Vorname Nachname</InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
            </Form.Group>
            <Row>
                <Col>
                    <Button type="button" className="mb-2" onClick={() => {handleClick()}}>
                        Namen ändern
                    </Button>
                </Col>
            </Row>
        </Form>
    )
}