import React from 'react';
import { Alert, Row } from 'react-bootstrap';
import { UIAlert, UIAlertAction } from '../Types';
import { ALERT_DISPLAY_COUNT, ALERT_REMOVE } from '../constants';
import { alertDispatch, getAlerts } from '../util/Alerts';

export function AlertBox() {
    const onClose = (alert: UIAlert) => {
        if (alert === null) return
        alertDispatch({
            type: ALERT_REMOVE,
            payload: alert
        } as UIAlertAction)
    }

    return (     
        <Row className="fixed-bottom mb-5 justify-content-center" style={{ zIndex: 1}}>
            {getAlerts().filter((_,key)=>key < ALERT_DISPLAY_COUNT).map((alert, key) =>
                <Alert variant={alert.variant} key={key} className="col-7 mb-0" dismissible onClose={() => onClose(alert)}>
                    {alert.title && <Alert.Heading>{alert.title}</Alert.Heading>}
                    <p>{alert.text}</p>
                    {alert.link &&
                        <p>
                            <a href={alert.link} target="_blank" rel="noreferrer">{alert.link}</a>
                        </p>
                    }
                </Alert>
            )}
        </Row>
    )
  }