import {Game} from '../components/guest/PlayerResults'
import {notEmpty} from './General'

const PERFECT_TOURNAMENT_MAX_PERF_DELTA = 667

function getDeltaFromP(P:number) {
    if (P === 0)
        return -PERFECT_TOURNAMENT_MAX_PERF_DELTA
    else if (P === 1)
        return PERFECT_TOURNAMENT_MAX_PERF_DELTA

    return -Math.log10(1/P-1)*400
}

function P(delta: number) {
    return 1/(1+Math.pow(10,-delta/400))
}

function AverageArray (array: number[]) {
    return array.reduce((sum, current) => sum + current, 0) / array.length
}

function getWe (Ro: number, opponentRatings: number[]) {
    var We = 0
    opponentRatings.forEach(opponentRating => {We += P(Ro - opponentRating)})
    return We
}

function getRh (Ro: number, W: number, We: number|null, n: number) {
    return Ro + (W-(We??0)) * 800 / n
}

export function CalculatePerformance (Ro: number|null, games: (Game|null)[]) {
    const ratedGames: Game[] = games.filter(notEmpty).filter(game => game && game.opponentRating !== null && game.opponentRating>0 && game.myResult !== null)
    const opponentRatings = ratedGames.map(ratedGame=>ratedGame.opponentRating??0)
    const opponentAverage = AverageArray(opponentRatings)
    const ratedScore = ratedGames.reduce((acc, ratedGame)=> acc+ratedGame.myResult!,0)


    if (!Ro) {
        return Math.round(opponentAverage + getDeltaFromP(ratedScore/ratedGames.length))
    }

    var Rh:number

    if (ratedScore === 0)
        Rh = opponentAverage - PERFECT_TOURNAMENT_MAX_PERF_DELTA
    else if (ratedScore === ratedGames.length)
        Rh = opponentAverage + PERFECT_TOURNAMENT_MAX_PERF_DELTA
    else
        Rh = getRh (Ro, ratedScore, getWe(Ro, opponentRatings), ratedGames.length)

    return Math.round(Rh)
}