import { useRef } from "react";
import { Container, Form, Button, Row, Col, InputGroup } from "react-bootstrap";
import { ALERT_ADD, DOMAIN_GUEST, TITLE_PREFIX } from "../../constants";
import { t } from "../../language/translations";
import { UIAlertAction } from "../../Types";
import { alertDispatch } from "../../util/Alerts";
import { getValueOrNull, sendRequestToBackendText } from "../../util/ApiRequest";

const DOMAIN_FEEDBACK = DOMAIN_GUEST + "/give_feedback"

export function FeedbackForm () {
    const nameRef       = useRef<HTMLInputElement>(null)
    const contactRef    = useRef<HTMLInputElement>(null)
    const feedbackRef   = useRef<HTMLTextAreaElement>(null)

    const handleClick = async () => {
        const data = {
            name:     getValueOrNull(nameRef),
            contact:  getValueOrNull(contactRef),
            feedback: feedbackRef.current?.value ?? null
        }
        const isSuccess = await sendRequestToBackendText(DOMAIN_FEEDBACK, data)

        if(isSuccess !== false){
            nameRef.current!.value = ""
            contactRef.current!.value = ""
            feedbackRef.current!.value = ""
            alertDispatch({type: ALERT_ADD, payload: {title: "Gespeichert", text: isSuccess, variant: "success"}} as UIAlertAction)
        }
    }

    document.title = TITLE_PREFIX + "Feedback"

    return (
        <Container className="mainFrame flex-grow-1">
            <Form>
                <h2>{t("giveFeedback")}</h2>
                <Form.Group as={Row}>
                    <Form.Label column md="3" xs="4">
                        {t("name")}
                    </Form.Label>
                    <Col md="7" xs="6">
                        <InputGroup className="mb-2">
                            <Form.Control ref={nameRef} placeholder=""/>
                        </InputGroup>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column md="3" xs="4">
                        {t("contact")}
                    </Form.Label>
                    <Col md="7" xs="6">
                        <InputGroup className="mb-2">
                            <Form.Control ref={contactRef} placeholder={t("contactPlaceholder")}/>
                        </InputGroup>
                    </Col>
                </Form.Group>
                <Form.Group>
                    <Form.Control ref={feedbackRef} as="textarea" rows={5} placeholder={t("putYourFeedback")} />
                </Form.Group>
                <Button type="button" className="mb-2" onClick={() => {handleClick()}}>
                    {t("sendFeedback")}
                </Button>
            </Form>
        </Container>
    )
}