import { Card, Container, Row } from "react-bootstrap";
import { t } from "../../language/translations";

export function HomePageInfo() {
    return (
        <Container className="border rounded mt-4 pt-3 mainFrame">
            <h3 className="text-center">{t("startPageFinallyTeamTournaments")}</h3>
            <p className="mb-1">
                {t("startPageFinallyTextAll")}
            </p>
            
            <Row className="justify-content-around">
                <Card style={{ width: '22rem' }} className="my-2" border="light">
                    <Card.Header>{t("player")}</Card.Header>
                    <Card.Body>
                        <Card.Text>
                            {t("startPageFinallyTextPlayer")}
                        </Card.Text>
                        <Card.Link href="https://youtu.be/ApLKIP4RiV8" target="_blank" rel="noreferrer">{t("startPageFinallyTextPlayerLink")}</Card.Link>
                    </Card.Body>
                </Card>
                <Card style={{ width: '22rem' }} className="my-2" border="light">
                    <Card.Header>{t("teamlead")}</Card.Header>
                    <Card.Body>
                        <Card.Text>
                            {t("startPageFinallyTextTeamlead")}
                        </Card.Text>
                        <Card.Link href="https://youtu.be/_GZL8Y9tNfw" target="_blank" rel="noreferrer">{t("startPageFinallyTextTeamleadLink")}</Card.Link>
                    </Card.Body>
                </Card>
                <Card style={{ width: '22rem' }} className="my-2" border="light">
                    <Card.Header>{t("tournamentAdmin")}</Card.Header>
                    <Card.Body>
                        <Card.Text>
                            {t("startPageFinallyTextTournamentAdmin1")} {t("startPageFinallyTextTournamentAdmin2")}
                        </Card.Text>
                        <Card.Link href="mailto:turniere@schachklub-kelheim.de">turniere@schachklub-kelheim.de</Card.Link><br/>
                        <Card.Link href="https://youtu.be/cfvupfNcpp8" target="_blank" rel="noreferrer">{t("startPageFinallyTextTournamentAdminLink")}</Card.Link>
                    </Card.Body>
                </Card>
            </Row>
        </Container>
    )
}