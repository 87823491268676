import { Container, Accordion, Card } from "react-bootstrap"
import { TITLE_PREFIX } from "../../constants"

type Info = {
    title:string,
    text:string
}

type FaqItem = {
    text:string,
    title:string,
    tag:string
}

const faq: FaqItem[] = [
    {
        "text": "Für die Sonneborn-Berger Feinwertung wird die erreichte Brettpunktzahl gegen einen Gegner mit der Mannschaftspunktezahl dieses Gegners multipliziert. Dieses Produkt wird dann für alle Gegner aufsummiert.",
        "title": "Sonneborn-Berger Feinwertung (SoBerg)",
        "tag":"guest"
    },
    {
        "title": "Wie funktioniert die Wertung in den Tabellen?",
        "text": "Die Rangliste und die Kreuztabelle wird nach diesen Kriterien sortiert: Mannschaftspunkte, Brettpunkte, Sonneborn-Berger (siehe oben), Los (geloste Startreihenfolge).",
        "tag":"guest"
    },
    {
        "text": "Das Rating einer Mannschaft berechnet sich aus dem Rating der ersten n gemeldeten Spieler, wobei n die Zahl der Bretter ist, an denen gespielt wird. Spieler ohne DWZ werden aktuell mit einer Zahl von 800 in der Berechnung berücksichtigt.",
        "title": "Durchschnittsrating",
        "tag":"guest"
    },
    {
        "title": "Wie viele Ersatzspieler kann ich melden?",
        "text": "Der Mannschaftsführer kann beliebig viele Ersatzspieler nominieren.",
        "tag":"teamleader"
    },
    {
        "title": "Kann ich einen Spieler in mehreren Mannschaften melden?",
        "text": "Nein, in einem Turnier kann ein Spieler nur für eine Mannschaft gemeldet werden. In einem anderen Turnier kann der Spieler für eine andere Mannschaft starten.",
        "tag":"teamleader"
    },
    {
        "title": "Bis wann kann ich die Aufstellung ändern?",
        "text": "Der Mannschaftsführer kann bis zum Start der 1. Runde Spieler hinzufügen, verschieben, pausieren und löschen. Nach dem Start der 1. Runde kann er nur mehr Spieler pausieren, bzw. wieder entpausieren. Der Turnierleiter kann jederzeit Spieler hinzufügen, verschieben, pausieren und löschen.",
        "tag":"teamleader"
    },
    {
        "title": "Wie kann ich meinen Zeitplan schützen?",
        "text": "Die Runden werden manuell gestartet. Der Turnierleiter kann frei entscheiden wie viel Pause er zwischen den Runden lässt. Nachdem die Bekanntgabe der Paarungen (Next Round) und das Starten der Partien (Start Round) unabhängig sind, kann der Turnierleiter auch hier eine Pause einfügen.",
        "tag":"tournamentAdmin"
    },
    {
        "title": "Gibt es irgendwann Kapazitätsprobleme? Wie viele Spieler können hier gleichzeitig spielen?",
        "text": "Wir sind auch für große Turniere gerüstet. Ein Limit sind 500 Partiestarts alle 10 Minuten (mehr könnten wir auf Anfrage bereitstellen). Die Website ist für eine große Zahl von Nutzern konzipiert. Kurz gesagt: bis 1000 Teilnehmer erwarten wir keine Probleme - bei mehr als 1000 finden wir bestimmt auch eine Lösung ;-).",
        "tag":"tournamentAdmin"
    },
    {
        "text": "Der Turnierleiter kann für ein Turnier den strengen Modus festlegen. Dann dürfen nur Spieler eingesetzt werden, die auf dieser Website den \"Login mit Lichess\" durchführt haben. Der Login kann auch schon Tage vor dem Rundenstart erfolgt sein. Der Vorteil am strengen Modus ist die glattere Turnierdurchführung. Insbesondere bei größeren Turnieren empfehlen wir diese Option.",
        "title": "Strenger Modus",
        "tag":"tournamentAdmin"
    }
]

export function FAQ () {
    document.title = TITLE_PREFIX + "FAQ"

    return (
        <Container>
            <h1>Fragen von Zuschauern</h1>
            <Accordion defaultActiveKey="0">
                { faq.filter((item:FaqItem)=> item.tag.includes("guest")).map( (info: Info,key:number) =>
                    <Card key={key}>
                        <Accordion.Toggle as={Card.Header} eventKey={"info-"+key}>
                            <strong>{info.title}</strong>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={"info-"+key}>
                            <Card.Body>
                                <p>
                                    {info.text}
                                </p>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                )}
            </Accordion>


            <h1>Fragen von Spielern</h1>
            Die Spielersicht kannst du dir in unserem <a href="https://youtu.be/ApLKIP4RiV8" target="_blank" rel="noreferrer">Intro für Spieler</a> anschauen.
            <Accordion defaultActiveKey="0">
                { faq.filter((item:FaqItem)=> item.tag.includes("player")).map( (info: Info,key:number) =>
                    <Card key={key}>
                        <Accordion.Toggle as={Card.Header} eventKey={"info-"+key}>
                            <strong>{info.title}</strong>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={"info-"+key}>
                            <Card.Body>
                                <p>
                                    {info.text}
                                </p>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                )}
            </Accordion>


            <h1>Fragen von Mannschaftsführern</h1>
            Die Sicht des Mannschaftsführers kannst du dir in unserem <a href="https://youtu.be/_GZL8Y9tNfw" target="_blank" rel="noreferrer">Intro für Mannschaftsführer</a> anschauen.
            <Accordion defaultActiveKey="0">
                { faq.filter((item:FaqItem)=> item.tag.includes("teamleader")).map( (info: Info,key:number) =>
                    <Card key={key}>
                        <Accordion.Toggle as={Card.Header} eventKey={"info-"+key}>
                            <strong>{info.title}</strong>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={"info-"+key}>
                            <Card.Body>
                                <p>
                                    {info.text}
                                </p>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                )}
            </Accordion>


            <h1>Fragen von Turnierleitern</h1>
            Die Perspektive der Turnierleiter kannst du dir in unserem <a href="https://youtu.be/cfvupfNcpp8" target="_blank" rel="noreferrer">Intro für Turnierleiter</a> anschauen.
            <Accordion defaultActiveKey="0">
                { faq.filter((item:FaqItem)=> item.tag.includes("tournamentAdmin")).map( (info: Info,key:number) =>
                    <Card key={key}>
                        <Accordion.Toggle as={Card.Header} eventKey={"info-"+key}>
                            <strong>{info.title}</strong>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={"info-"+key}>
                            <Card.Body>
                                <p>
                                    {info.text}
                                </p>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                )}
            </Accordion>
        </Container>
    )
}