import React from 'react';
import { Alert, Badge, Button, Modal, Table } from 'react-bootstrap';
import { CaretRight } from 'react-bootstrap-icons';
import {DOMAIN, ALERT_ADD} from '../../constants';
import { t } from '../../language/translations';
import {APIGame, APITeam, APITournament, UIAlertAction} from '../../Types';
import { alertDispatch } from '../../util/Alerts';
import { GetMyTeams } from '../../util/api/FetchMyTeams';
import {getRequestHeader} from '../../util/ApiRequest'
import { getPlayersNotReadyAsString } from '../../util/Player';
import { getAllGamesFromRound, isTeamPaired } from '../../util/Round';
import { getEmptyBoardCount, getTeamsNotComplete, sortFunctionForTeams } from '../../util/Team';
import { shouldNextRound, shouldSetPairings, shouldStartClock, shouldStartRound } from '../../util/Tournament';

const INPUT_ID_TOURNAMENT_ID = "tournamentId"

async function sendRequestToBackend(alias: string, token: string|null, tournamentId: string){
    const requestHeader = getRequestHeader (token)
    const target = `${DOMAIN}/tournament_admin/` + alias + "?" + INPUT_ID_TOURNAMENT_ID + "=" + tournamentId

    const fetchRes = await fetch(target, {
        method: 'GET',
        headers: requestHeader
    });
    
    if(fetchRes.ok){
        const txt = await fetchRes.text()
        alertDispatch(  {type: ALERT_ADD, payload: {title: "Die Aktion \"" + alias + "\" wurde ausgeführt",
                        text: txt, variant: "success"}} as UIAlertAction)
    }
    else{
        const txt = await fetchRes.text()
        alertDispatch(  {type: ALERT_ADD, payload: {title: "Die Aktion \"" + alias + "\" wurde nicht ausgeführt",
            	        text: "Der Befehl wurde an '" + target + "' gesendet, lieferte aber den Status " + fetchRes.status + " zurück. Fehlermeldung: " + txt, variant: "danger"}} as UIAlertAction)
    }
}

function ConfirmNextRound ({tournament, teams, onHide}: {tournament: APITournament, teams: APITeam[], onHide: (isConfirmed: boolean)=>void}) {
    const teamsNotComplete = getTeamsNotComplete(teams, tournament.settings.boardCount, tournament.settings.isStrictMode)
    const teamsNotPaired: APITeam[] = tournament.rounds.length>0 ? teams.filter(t=>!isTeamPaired(tournament.rounds[tournament.rounds.length-1], t.id)) : []

    return (
        <Modal
            show={true}
            onHide={()=>onHide(false)}
            size="lg"
            aria-labelledby="modal-tournament-info"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="modal-tournament-info">
                    Bist du sicher, dass du die Spieler paaren möchtest?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {teamsNotPaired.length===0 &&
                    <p>Alle Teams wurden gepaart</p>
                }
                {teamsNotPaired.length>0 &&
                    <>
                    <p>Folgende Teams wurden nicht gepaart:</p>
                    <ul>
                        {teamsNotPaired.map((t,k)=>
                            <li key={k}>{t.name}</li>
                        )}
                    </ul>
                    </>
                }
                {teamsNotComplete.length>0 &&
                    <>
                    Folgende Mannschaften haben nicht die erforderliche Anzahl von Spielern:
                    <Table size="sm" className="table-hover">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>leere Bretter</th>
                                <th>Nicht startbereite Spieler</th>
                            </tr>
                        </thead>
                        <tbody>
                            {teamsNotComplete.map((team,key) =>
                                <tr key={key} className={teamsNotPaired.some(t=>t.id===team.id)?"isPaused":""}>
                                    <td>{key+1}</td>
                                    <td>{team.name}</td>
                                    <td className="">{getEmptyBoardCount(team, tournament.settings.boardCount, tournament.settings.isStrictMode)}</td>
                                    <td>{getPlayersNotReadyAsString(team.players, tournament.settings.isStrictMode)}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                    </>
                }
                {teamsNotComplete.length === 0 &&
                    <p>Passt alles ;-) Wir können starten.</p>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={()=>onHide(true)} variant="warning">Los!</Button>
                <Button onClick={()=>onHide(false)} variant="outline-success">Abbruch</Button>
            </Modal.Footer>
        </Modal>
    )
}

function ConfirmStartRound ({tournament, onHide}: {tournament: APITournament, onHide: (isConfirmed: boolean)=>void}) {
    var games: APIGame[] = []
    if (tournament.rounds.length>0)
        games = getAllGamesFromRound(tournament.rounds[tournament.rounds.length-1])

    return (
        <Modal
            show={true}
            onHide={()=>onHide(false)}
            size="lg"
            aria-labelledby="modal-tournament-info"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="modal-tournament-info">
                    Bist du sicher, dass die Lichesspartien gestartet werden sollen?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {games.length === 0 &&
                    <Alert variant="danger">Es werden keine Partien gestartet. Wurden die Paarungen gesetzt und Next Round ausgeführt?</Alert>
                }
                {games.length > 0 &&
                    <p>Es {games.length===1?"wird":"werden"} {games.length} Partie{games.length===1?"":"n"} gestartet</p>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={()=>onHide(true)} variant="warning">Los!</Button>
                <Button onClick={()=>onHide(false)} variant="outline-success">Abbruch</Button>
            </Modal.Footer>
        </Modal>
    )
}

type Props = {
    token: string|null,
    tournament: APITournament,
    updateTournament: Function
}

export function TriggerTournamentEvent({token, tournament, updateTournament}: Props){
    const [confirmNextRoundTeams, setConfirmNextRoundTeams] = React.useState(null as null|APITeam[])
    const [confirmStartRound, setConfirmStartRound] = React.useState(false as boolean)
    
    const handleConfirmation = (isConfirmed: boolean, alias: string) => {
        if (isConfirmed)
            sendRequestToBackend(alias, token, tournament.id)

        if (confirmNextRoundTeams)
            setConfirmNextRoundTeams(null)
        if (confirmStartRound)
            setConfirmStartRound(false)
    }

    const handleClickNextRound = async (alias: string) => {
        const res: APITeam[] = await GetMyTeams(tournament.id)
        res.sort(sortFunctionForTeams("alphabetical"))
        setConfirmNextRoundTeams(res)
        await updateTournament()
    }
    const handleClickStartRound = async (alias: string) => {
        await updateTournament()
        setConfirmStartRound(true)
    }

    const handleClick = (alias:string) => {sendRequestToBackend(alias, token, tournament.id)}
    

    const highlightSetPairings = shouldSetPairings(tournament)
    const highlightNextRound = shouldNextRound(tournament)
    const highlightStartRound = shouldStartRound(tournament)
    const highlightStartClock = shouldStartClock(tournament)

    return (
        <>
        {confirmNextRoundTeams && <ConfirmNextRound tournament={tournament} teams={confirmNextRoundTeams} onHide={(isConfirmed:boolean)=>handleConfirmation(isConfirmed, "next_round")} />}
        {confirmStartRound && <ConfirmStartRound tournament={tournament} onHide={(isConfirmed:boolean)=>handleConfirmation(isConfirmed, "start_round")} />}
        <Table bordered>
            <thead>
                <tr>
                    <th style={{width: "3rem"}}></th>
                    <th style={{width: "8rem"}}></th>
                    <th style={{width: "160px"}}>{t("action")}</th>
                    <th>{t("ta_event_explanation")}</th>
                </tr>
            </thead>
            <tbody>
                <tr className={highlightSetPairings ? "table-primary" : ""}>
                    <td className="align-middle">{highlightSetPairings ? <CaretRight width="2rem" height="2rem"/> : <></>}</td>
                    <td className="align-middle">{t("step")} 1</td>
                    <td className="align-middle text-center">{/*<SwissPairing tournament={tournament} handlePairings={handlePairings}/>*/}</td>
                    <td>{t("ta_event_1_text")}</td>
                </tr>
                <tr className={highlightNextRound ? "table-primary" : ""}>
                    <td className="align-middle">{highlightNextRound ? <CaretRight width="2rem" height="2rem"/> : <></>}</td>
                    <td className="align-middle">{t("step")} 2</td>
                    <td className="align-middle text-center"><Button variant="warning" onClick={() => handleClickNextRound("next_round")}>Next Round</Button></td>
                    <td>{t("ta_event_2_text")}</td>
                </tr>
                <tr className={highlightStartRound ? "table-primary" : ""}>
                    <td className="align-middle">{highlightStartRound ? <CaretRight width="2rem" height="2rem"/> : <></>}</td>
                    <td className="align-middle">{t("step")} 3</td>
                    <td className="align-middle text-center"><Button variant="warning" onClick={() => handleClickStartRound("start_round")}>Start Round</Button></td>
                    <td>{t("ta_event_3_text")}</td>
                </tr>
                <tr className={highlightStartClock ? "table-primary" : ""}>
                    <td className="align-middle">{highlightStartClock ? <Badge variant='info'>{highlightStartClock}</Badge> : <></> }</td>
                    <td className="align-middle">{t("step")} 4</td>
                    <td className="align-middle text-center"><Button variant="warning" onClick={() => handleClick("start_clocks")}>Start Clock</Button></td>
                    <td>{t("ta_event_4_text")}</td>
                </tr>
            </tbody>
        </Table>
        </>
    )
}