import de from './de.json';
import en from './en.json';
import { LANG_DEFAULT } from '../constants';

import {ReactComponent as DeFlag} from '../flags/de.svg';
import {ReactComponent as EnFlag} from '../flags/en.svg';

type flag = {
    abbreviation: string,
    flag30: JSX.Element,
    flag50: JSX.Element
}

const FLAGS: flag[] = [{
        abbreviation: "de",
        flag30: <DeFlag width="30"/>,
        flag50: <DeFlag width="50"/>
    },{
        abbreviation: "en",
        flag30: <EnFlag width="30"/>,
        flag50: <EnFlag width="50"/>
}]



export function isLang(lang:string|null) {
    if (!lang)
        return false
    return FLAGS.some(flag => flag.abbreviation === lang)
}

export function getTranslation(lang: string) {
    switch (lang) {
        case "de":
            return de
        case "en":
            return en
        default:
            return de
    }
}

export function getFlag(lang: string): flag {
    const flag = FLAGS.find(flag => flag.abbreviation === lang)

    if (!flag)
        return getFlag(LANG_DEFAULT)
    return flag
}


export function getFlags() {
    return FLAGS
}

function tWithLang(key: string, language: string): string {
    const trans: any = getTranslation(language)
    return trans[key]
}

export var translation_settings = {
    language: "de"
}

export function t(key: string) : any {
    return tWithLang(key, translation_settings.language)
}