import React from 'react'
import { Container, Table, OverlayTrigger, Tooltip, Button, Row } from 'react-bootstrap';
import { ArrowsCollapse, ArrowsExpand, Clipboard } from 'react-bootstrap-icons';
import { ALERT_ADD } from '../../constants';
import { t } from '../../language/translations';
import { APITournament, APITeam } from '../../Types';
import { alertDispatch } from '../../util/Alerts';
import { isBuchholz } from '../../util/Tournament';
import { ButtonGroupSelector } from './ExpansionSelector';

import {SinglePlayerTable} from './PlayerResults'
import {getCrossTable, Result, CrosstableRow} from './RankCrossTable'



const PROGRESSTABLE_ID = "progressTable"

function ProgressTableResult (result: Result, teams: APITeam[], boardCount: number) {
    return (
        <OverlayTrigger
            placement="bottom"
            key={result.opponentId}
            overlay={
                <Tooltip id={`tooltip-${result.opponentId}`}>
                    {result.isHome ? t("homeAgainst") : t("visitorAgainst")} <strong>{teams.find(team=>team.id === result.opponentId)?.name ?? t("bye")}</strong>
                </Tooltip>
            }>
            <Button size="sm" className="team-result-button" variant=   {result.score>boardCount/2 ? "outline-success" : 
                                                                (result.score===boardCount/2 ? "outline-primary" : "outline-secondary")}>
                {result.opponentId===null ? "+" : (result.opponentId>0 ? result.score : "-")}
            </Button>
        </OverlayTrigger>
    )
}

function ProgressTableGames (crossTableRow: CrosstableRow, teams: APITeam[], boardCount: number) {
    return(
        crossTableRow.results.map((result,key) => 
            <td key={key} className="crossTable-col">
                {ProgressTableResult(result, teams, boardCount)}
            </td>
        )
    )
}

function exportFunction (how: string) {
    const text =    "<style>.crossTable-empty{background-color: grey;} .crossTable-col{width: 40px; min-width: 40px;} .text-center{text-align: center !important;} .team-result-button{min-width: 45px;}</style>" + 
                    "<h2>"+t("progressTable")+"</h2>" + 
                    document.getElementById(PROGRESSTABLE_ID)?.outerHTML +
                    "Weitere Informationen zum Turnier finden Sie <a href='https://turniere.schachklub-kelheim.de'>hier</a>"
    if (how === "clipboard" && text !== undefined) {
        navigator.clipboard.writeText(text)
        alertDispatch(  {type: ALERT_ADD, payload: {title: "HTML wurde in die Zwischenablage kopiert",
                        text: "", variant: "success"}})
    }
}

const expansionSelectorButtons = [
    {action: "none", tooltip: "tt_hideAllTeammembers", label: <ArrowsCollapse size={20}/>},
    {action: "all", tooltip: "tt_showAllTeammembers", label: <ArrowsExpand size={20}/>}
] as {action: string, tooltip: string, label: JSX.Element}[]

const exportButtons = [
    {action: "clipboard", tooltip: "tt_exportClipboard", label: <Clipboard size={20}/>}
    //{action: "pdf", tooltip: "tt_exportPDF", label: <Download size={20}/>}
] as {action: string, tooltip: string, label: JSX.Element}[]

export function RankProgressTable({tournament}: {tournament: APITournament}){
    const crossTable: CrosstableRow[] = getCrossTable(tournament)
    const [teamIdsToShow, SetTeamIdsToShow] = React.useState([] as number[])
    
    const handleShow = (mode: string) => {
        const ids = mode === "all" ? tournament.teams.map(t=>t.id) : []
        SetTeamIdsToShow(ids)
    }
    const handleToggleTeam = (id: number|null) => {
        if (id === null)
            return
        if (teamIdsToShow.some(exp_id=>exp_id===id))
            SetTeamIdsToShow(teamIdsToShow.filter(exp_id=>exp_id!==id))
        else
            SetTeamIdsToShow([...teamIdsToShow, id])
    }

    const isBH = isBuchholz(tournament)

    return(
        <Container fluid="md" className="px-0">
            <Row className="p-3 m-0 d-md-flex justify-content-between">
                <h2 className="px-3 px-md-0">{t("progressTable")}</h2>
                <ButtonGroupSelector buttons={expansionSelectorButtons} handleClick={handleShow}/>
                <ButtonGroupSelector buttons={exportButtons} handleClick={exportFunction}/>
            </Row>
            
            <Table className="text-center table-vcenter" size="sm" hover id={PROGRESSTABLE_ID}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th className="text-left" colSpan={3}>{t("team")}</th>
                        {tournament.rounds.map((_, key) => 
                            <th key={key}>{key+1}</th>
                        )}
                        <th>MP</th>
                        <th>BP</th>
                        <th>{isBH ? "Buchholz" : "SoBerg"}</th>
                    </tr>
                </thead>
                <tbody>
                    {crossTable.map((crossTableRow, key) => {
                        let showSinglePlayer = (teamIdsToShow.some(id=>id===crossTableRow.teamId))
                        let teamWithDetails = showSinglePlayer && tournament.teams.find(team=>team.id === crossTableRow.teamId)
                        return (
                            <React.Fragment key={crossTableRow.teamId}>
                                <tr key={crossTableRow.teamId} onClick={()=>handleToggleTeam(crossTableRow.teamId)} className={teamWithDetails ? "table-active" :""}>
                                    <td className={teamWithDetails ? "table-active" :""} 
                                        rowSpan={teamWithDetails? (teamWithDetails?.players.length??-1)+2 : 1}>
                                            {key+1}
                                    </td>
                                    <td className="text-left clickable" colSpan={3}>{tournament.teams.find(team => team.id === crossTableRow.teamId)?.name ?? ""}</td>
                                    {ProgressTableGames(crossTableRow, tournament.teams, tournament.settings.boardCount)}
                                    <td>{crossTableRow.totalMP}</td>
                                    <td>{crossTableRow.totalBP}</td>
                                    <td>{isBH ? crossTableRow.totalBuchholz : crossTableRow.totalSoBerg}</td>
                                </tr>
                                {teamWithDetails &&
                                    <SinglePlayerTable tournament={tournament} players={teamWithDetails.players} sort={false} key={"spt"+teamWithDetails.id}/>
                                }
                            </React.Fragment>
                        )
                    })}
                </tbody>
                {crossTable.length === 0 &&
                    <caption>
                        {t("noTeamsYet")}
                    </caption>
                }
            </Table>
        </Container>
    )
}