import { DOMAIN, LOCAL_STORAGE_TOURNAMENT_DETAILS, MAX_TOURNAMENT_DETAILS_IN_LOCALSTORAGE } from "../../constants";
import { APITournament } from "../../Types";

const DOMAIN_FETCH_TOURNAMENT = DOMAIN + "/get_tournament"

export async function FetchTournament (tournamentId: string): Promise<APITournament|null> {
    const requestHeader = new Headers()

    const fetchRes = await fetch(DOMAIN_FETCH_TOURNAMENT + "?tournamentId=" + tournamentId, {
        method: 'GET',
        headers: requestHeader
    });
    if (!fetchRes.ok)
        return null

    const res: APITournament = await fetchRes.json();
    return res
}

export function StoreTournament (tournament: APITournament) {
    var storedTournamentDetails = LoadTournamentDetails()

    const index = storedTournamentDetails.findIndex(t=>t.id===tournament.id)

    if (index === -1) {
        if (storedTournamentDetails.length >= MAX_TOURNAMENT_DETAILS_IN_LOCALSTORAGE)
            storedTournamentDetails.shift()
        storedTournamentDetails.push(tournament)
    }
    else
        storedTournamentDetails[index] = tournament

    try {
        localStorage.setItem(LOCAL_STORAGE_TOURNAMENT_DETAILS, JSON.stringify(storedTournamentDetails))
    }
    catch (e) {
        console.log(e)
    }
}

function LoadTournamentDetails (): APITournament[] {
    const storedTournamentDetails = localStorage.getItem(LOCAL_STORAGE_TOURNAMENT_DETAILS)
    if(storedTournamentDetails)
        return JSON.parse(storedTournamentDetails)
    else
        return []
}

export function LoadTournament (tournamentId: string): APITournament|undefined {
    const storedTournamentDetails = LoadTournamentDetails()
    return storedTournamentDetails.find(t => t.id === tournamentId)??undefined
}