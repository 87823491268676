import React from 'react'
import { Link, useParams } from "react-router-dom";
import { Accordion, Card, Row, Col, Table, Button, ButtonGroup, DropdownButton, Dropdown} from 'react-bootstrap';
import {ChevronDoubleDown, CaretLeftFill, CaretRightFill, ArrowsCollapse, ArrowsExpand, Pencil} from 'react-bootstrap-icons'
import { APIGame, APIMatch, APIPlayer, APIRound, APITournament, User } from '../../Types';
import { LICHESS_URL, RESULT } from '../../constants';

import { TeamParticipantsList } from './TeamParticipantsList';
import { LinkContainer } from 'react-router-bootstrap';
import {SetResult, DeleteGame} from '../../util/api/SetResult'
import { t } from '../../language/translations';
import { isTournamentAdminOrHigher } from '../../util/Rights';
import { notEmpty } from '../../util/General';
import { isNoTokenGame, isSpecialGame, resultToString } from '../../util/Game';
import { ButtonGroupSelector } from './ExpansionSelector';


const expansionSelectorButtons = [
    {action: "none", tooltip: "tt_hideAllGames", label: <ArrowsCollapse size="20"/>},
    {action: "live", tooltip: null, label: "live"},
    {action: "all", tooltip: "tt_showAllGames", label: <ArrowsExpand size="20"/>}
] as {action: string, tooltip: string, label: JSX.Element}[]


interface RouteParams {
    tournamentId: string
    roundId: string
}

function matchTotal (match: APIMatch, boardCount: number) {
    const replaceDrawSymbol = (input: number) => Number.isInteger(input) ? input : (Math.floor(input) === 0 ? "": Math.floor(input))+ "½" 

    var matchTotalFirst = 0
    var matchTotalSecond = 0

    if (match.firstTeamId === null)
        matchTotalSecond = boardCount
    else if (match.secondTeamId === null)
        matchTotalFirst = boardCount
    else
        match.games.forEach((game) => {   
            matchTotalFirst += (game.result ?? 0)/2;
            matchTotalSecond += (game.result === null ? 0 : (2-game.result)/2);
            return null
        })

    return replaceDrawSymbol(matchTotalFirst) + " - " + replaceDrawSymbol(matchTotalSecond)
}

function nameRatingString (player: APIPlayer|null) {
    if (!player)
        return t("bye")
    return player.name + ((player.rating??-1>0) ? " ("+player.rating+")":"")
}

function SetResultDropdown (game: APIGame, boardNumber: number, updateResult: Function) {
    const flip: boolean = boardNumber%2===0

    return (
        <DropdownButton as={ButtonGroup} variant="danger" title={game.result===null?"no token":resultToString(game.result)} size="sm" className="center-parent gameButton">
            <Dropdown.Item onClick={() => {updateResult(game.id,flip?RESULT.player2Won:RESULT.player1Won)}}>1-0</Dropdown.Item>
            <Dropdown.Item onClick={() => {updateResult(game.id,flip?RESULT.player1Won:RESULT.player2Won)}}>0-1</Dropdown.Item>
            <Dropdown.Item onClick={() => {updateResult(game.id,RESULT.draw)}}>½</Dropdown.Item>
            <Dropdown.Item onClick={() => {updateResult(game.id,"delete")}}>{t("delete")}</Dropdown.Item>
        </DropdownButton>
    )
}

function GameButton (game: APIGame, label: string, variant: string, mayChangeResults: boolean = false) {
    const buttonDisabled = isNoTokenGame(game) || !game.id || (isSpecialGame(game) && !mayChangeResults)
    return (
        <Button variant={variant} href={LICHESS_URL + game.id} target="_blank" size="sm" className="center-parent gameButton" disabled={buttonDisabled}>
            {label}
        </Button>
    )
}

function convertResult (game: APIGame, boardNumber: number, updateResult: Function, mayChangeResults: boolean) {
    if (isNoTokenGame(game) && mayChangeResults)
        return (SetResultDropdown(game,boardNumber,updateResult))

    if (game.id && game.result === null && !isNoTokenGame(game))
        return (GameButton(game,"live","success", mayChangeResults))

    if (game.result !== null)
        return (GameButton(game,resultToString(game.result),"outline-secondary", mayChangeResults))
        
    return (GameButton(game,"-","outline-success"))
}

function ColourIndicator(isFirstWhite: boolean, isLeftSide: boolean) {
    const color = isFirstWhite !== isLeftSide ? "black" : "white"
    return <div className={"round-player-color round-player-" + color} />
}

function MatchCard(tournament: APITournament, match: APIMatch, index: number, updateResult: Function, show: boolean, onShow: Function, mayChangeResults: boolean) {
    return (
        <Card style={{overflow: "visible"}} key={index}>
            <Card.Header style={{cursor: "pointer"}} className="match-finished" onClick={()=>onShow()}>
                <Row>
                    <Col className="d-none d-md-block col-1 pl-3">
                        {index+1}
                    </Col>
                    <Col className="col-4 pl-md-3 pl-2">
                            {tournament.teams.find((team) => team.id === match.firstTeamId)?.name ?? t("bye")}
                    </Col>
                    <Col className={(match.games.some(game=>game.result===null)?"match-live":"match-finished") + " col-xl-2 col-md-2 col-3 text-center"}>
                            {matchTotal(match, tournament.settings.boardCount) } 
                    </Col>
                    <Col className="col-4">
                            {tournament.teams.find((team) => team.id === match.secondTeamId)?.name ?? t("bye")}
                    </Col>
                    <Col className="col-1 text-right">
                        <ChevronDoubleDown/>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body className={(show?"": "d-none") + " p-0"}>
                <Table hover size="sm" className="m-0">
                    <tbody>
                        {
                            match.games.map( (game,gameKey) =>
                                <tr key={gameKey} className="d-flex">
                                    <td className="d-none d-md-block col-1 pl-4">{gameKey+1}</td>
                                    <td className="col-4 pl-md-4 pl-2">{nameRatingString(game.player1)}</td>
                                    <td className="col-md-2 col-3 text-center">{ColourIndicator(game.isFirstWhite??false,true)}{convertResult(game, gameKey, updateResult, mayChangeResults)}{ColourIndicator(game.isFirstWhite??false,false)}</td>
                                    <td className="col-5 pl-3 d-flex justify-content-between">
                                        <span>{nameRatingString(game.player2)}</span>
                                        {mayChangeResults &&
                                            <Link to={"/"+tournament.id+"/admin/set_result?gameId="+game.id}>
                                                <Button variant="outline-secondary" size="sm"><Pencil width="15px"/></Button>
                                            </Link>
                                        }
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    )
}

function isMatchVisible (expandMatches: string, match: APIMatch) {
    switch (expandMatches) {
        case "all":
            return true
        case "none":
            return false
        default:
            return match.games.some(game=>game.result===null)
    }
}

export function Round({user, tournament}: {user: User|null, tournament: APITournament}){
    const params = useParams<RouteParams>();
    const [firstTeamIdOfExpandedMatches, SetFirstTeamIdsOfExpandMatches] = React.useState([] as number[]) 
    
    const updateResult = (gameId: string|null, action: string|number) => { 
        if (gameId === null)
        return
        
        if (typeof action === "number")
        SetResult (tournament.id, gameId, action.toString())
        if (action === "delete")
        DeleteGame (tournament.id, gameId, action.toString())
    }
    
    var round: APIRound
    var roundNumber: number
    if (params.roundId && parseInt(params.roundId)-1<tournament.rounds.length)
    roundNumber = parseInt(params.roundId)-1
    else if (tournament.rounds.length>0)
    roundNumber = tournament.rounds.length-1
    else {
        return (<><TeamParticipantsList tournament={tournament}/></>)    
    }
    
    round = tournament.rounds[roundNumber]

    const handleShow = (mode: string) => {
        const ids = round.matches.filter(match=>isMatchVisible(mode, match)).map(match=>match.firstTeamId).filter(notEmpty)
        SetFirstTeamIdsOfExpandMatches(ids)
    }
    const handleToggleTeam = (id: number|null) => {
        if (id === null)
            return
        if (firstTeamIdOfExpandedMatches.some(exp_id=>exp_id===id))
            SetFirstTeamIdsOfExpandMatches(firstTeamIdOfExpandedMatches.filter(exp_id=>exp_id!==id))
        else
            SetFirstTeamIdsOfExpandMatches([...firstTeamIdOfExpandedMatches, id])
    }
    
    const mayChangeResults = isTournamentAdminOrHigher(tournament, user)
    return(
        <>
            <Row className="p-3 m-0 d-flex d-md-none">
                <h2 className="">
                    {t("round")} 
                </h2>
                <ButtonGroup aria-label="Basic example" className="ml-3">
                    <LinkContainer to={"/" + tournament.id + '/round/' + (roundNumber)}>
                        <Button  variant="outline-secondary" disabled={roundNumber < 1}><CaretLeftFill/></Button>
                    </LinkContainer>
                    <Button variant="secondary" style={{minWidth: "42px"}} active>{roundNumber+1}</Button>
                    <LinkContainer to={"/" + tournament.id + '/round/' + (roundNumber+2)}>
                        <Button  variant="outline-secondary" disabled={roundNumber > tournament.rounds.length-2}><CaretRightFill/></Button>
                    </LinkContainer>
                </ButtonGroup>
            </Row>

            <Row className="p-3 m-0 d-none d-md-flex justify-content-between">
                <h2 className="">
                    {t("round")} {roundNumber+1} 
                </h2>
                <ButtonGroupSelector buttons={expansionSelectorButtons} handleClick={handleShow} />
            </Row>

            <Accordion className="p-0 p-md-3 m-0">
                { round.matches.map( (match: APIMatch,key) =>
                    MatchCard (tournament, match, key, updateResult,
                                firstTeamIdOfExpandedMatches.some(id => id===match.firstTeamId),
                                ()=>handleToggleTeam(match.firstTeamId),
                                mayChangeResults)
                )}
            </Accordion>
        </>
    )
}