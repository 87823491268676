import {APIPlayer, APITeam} from '../Types'
import {getPlayersReady} from './Player'

const MAX_BOARDCOUNT = 1000
const DEFAULT_RATING = 800


function calcPlayerAverageRating (players: APIPlayer[]) {
    if (players.length===0)
        return 0
    return players.reduce((sum, p)=>sum+(p.rating &&p.rating>0 ? p.rating : DEFAULT_RATING),0) / players.length
}

// Rating average of the first "boardCount" members of "team"
export function calcRatingAverage (team: APITeam, boardCount?: number) {
    if (!boardCount)
        return calcPlayerAverageRating(team.players)
    else
        return calcPlayerAverageRating(team.players.filter((_,key)=>key<(boardCount??MAX_BOARDCOUNT)))
}

export function printRatingAverage (team: APITeam, boardCount?: number): string{
    const avg = calcRatingAverage (team, boardCount)
    if (avg > 0)
        return Math.round(avg).toString()
    else
        return ""
}

export function sortFunctionForTeams (mode:string, boardCount?: number) {
    switch (mode) {
        case "random":
            return (t1:APITeam,t2:APITeam)=>0
        case "alphabetical":
            return (t1:APITeam,t2:APITeam)=> (t1.name < t2.name ? -1 : 1)
        default:
            return (t1:APITeam,t2:APITeam)=> {
                const diff = calcRatingAverage(t2, boardCount)-calcRatingAverage(t1, boardCount)
                if (diff === 0)
                    return t2.id-t1.id
                else
                    return diff
            }
    }
}

export function isComplete (team: APITeam, boardCount: number, isStrictMode: boolean): boolean {
    return team.players.filter(p=>!p.isPaused&&(!isStrictMode||p.isRegistered)).length >= boardCount
}

export function getTeamsNotComplete (teams: APITeam[], boardCount: number, isStrictMode: boolean): APITeam[] {
    return teams.filter(t=>!isComplete(t, boardCount, isStrictMode))
}

export function getTeamsNotCompleteAsString (teams: APITeam[], boardCount: number, isStrictMode: boolean): string {
    return getTeamsNotComplete(teams, boardCount, isStrictMode).map(t=>t.name).join(", ")
}

export function getEmptyBoardCount (team: APITeam, boardCount: number, isStrictMode: boolean) {
    return boardCount-getPlayersReady(team.players, isStrictMode).length
}

/**
 * 
 * @param teams 
 * @param lichessname 
 * @returns teamId of the team with the given player or undefined if not found
 */
export function getTeamFromLichessName(teams: APITeam[], lichessname: string): number|undefined {
    const searchName = lichessname.toLocaleLowerCase().trim()
    const team = teams.find(team=>team.players.some(player=>player.lichessName===searchName))
    return team?.id ?? undefined
}


export function getLichessNameFromTeamId(teams: APITeam[], teamId:number|null): string|undefined {
    if (teamId === null)
        return undefined
    return teams.find(team=>team.id===teamId)?.players[0].lichessName ?? undefined
}
