import { ALERT_ADD, DOMAIN_TOURNAMENT_ADMIN } from "../../constants"
import { APIPairing } from "../../Types"
import { alertDispatch } from "../Alerts"
import { ApiPostRequest } from "../ApiRequest"

const DOMAIN_SWISS_PAIRINGS = DOMAIN_TOURNAMENT_ADMIN + "/swiss_pairing"

export async function SwissPairingFromApi(tournamentId: string, notPaired: number[]) {
    const data = {
        tournamentId: tournamentId,
        notPaired: notPaired
    }

    const fetchRes = await ApiPostRequest(DOMAIN_SWISS_PAIRINGS, data)
    if (fetchRes.ok) {
        alertDispatch(  {type: ALERT_ADD, payload: {title: "Paarungen wurden geladen", text: "", variant: "success"}})
        const pairings: APIPairing[] = await fetchRes.json()
        return pairings
    }
    else {
        const txt = await fetchRes.text()
        alertDispatch(  {type: ALERT_ADD, payload: {title: "Fehler beim Paaren der teams", text: txt, variant: "danger"}})
        return null
    }
}