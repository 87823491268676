import React, { RefObject, useRef } from 'react';
import { Form, Col, Row, ButtonGroup, Button, Table } from 'react-bootstrap';
import {PencilFill, Trash} from 'react-bootstrap-icons'
import { ALERT_ADD } from '../../constants';
import { t } from '../../language/translations';
import {UIAlertAction, APITournament, APITeam} from '../../Types';
import { alertDispatch } from '../../util/Alerts';
import { sendAddTeam, sendDeleteTeam} from '../../util/ApiRequest';
import { isComplete, sortFunctionForTeams } from '../../util/Team';
import { isLichessUserName } from '../../util/Validation';
import {EditTeamModal} from './EditTeamModal'
import { KoRegPlayerForm } from './KoRegPlayerForm';

async function sendCreateTeam(tournamentId: string, teamNameRef: RefObject<HTMLInputElement>, teamAdminRef: RefObject<HTMLInputElement>){
    if (!teamNameRef.current?.value || !teamAdminRef.current?.value) {
        alertDispatch({type: ALERT_ADD, payload: {title: "Mannschaft wurde nicht erstellt", text: "Bitte füllen sie alle Felder aus!", variant: "danger"}} as UIAlertAction)
        teamNameRef.current?.classList.add("border-danger")
        teamAdminRef.current?.classList.add("border-danger")
        return
    }
    
    if (!teamAdminRef.current || !(await isLichessUserName(teamAdminRef.current.value))){
        alertDispatch({type: ALERT_ADD, payload: {title: "Mannschaft wurde nicht erstellt", text: t("lichessNameNotFound"), variant: "danger"}} as UIAlertAction)
        teamAdminRef.current?.classList.add("border-danger")
        return
    }

    const teamname  = (teamNameRef.current?.value ?? "").trim()
    const admin     = (teamAdminRef.current?.value ?? "").trim()
    
    const res = await sendAddTeam(tournamentId, teamname, admin)

    if(res !== false){
        alertDispatch(  {type: ALERT_ADD, payload: {title: "Mannschaft \""+ (teamNameRef.current?.value ?? "") + "\" wurde erstellt",
                        text: "", variant: "success"}} as UIAlertAction)
        teamNameRef.current!.value = ""
        teamAdminRef.current!.value = ""
        teamNameRef.current?.classList.remove("border-danger")
        teamAdminRef.current?.classList.remove("border-danger")
    }

    return res
}

type Props = {
    tournament: APITournament,
    updateTournament: any
}

export function TeamAddForm({tournament, updateTournament}: Props){
    const teamNameRef = useRef<HTMLInputElement>(null)
    const teamAdminRef = useRef<HTMLInputElement>(null)

    const [editTeam, setEditTeam] = React.useState(null as APITeam|null)


    const handleClick = async (alias: string) => { 
        await sendCreateTeam(tournament.id, teamNameRef, teamAdminRef)
        updateTournament()
    }
    const handleDeleteTeam = async (teamId: number) => {
        await sendDeleteTeam(tournament.id, teamId)
        updateTournament()
    }
    
    return(
        <>
        {(tournament.settings.type === 5 || tournament.settings.boardCount === 1) &&
            <KoRegPlayerForm tournament={tournament}/>
        }
        {editTeam !== null && 
            <EditTeamModal tournamentId={tournament.id} team={editTeam} onHide={()=>setEditTeam(null)} updateTournament={updateTournament}/> 
        }
        <Form>
            <h2 className="p-3">{t("addTeamToTournament")}</h2>
            <Row>
                <Col>
                    <Form.Control ref={teamNameRef} placeholder={t("teamName")}/>
                </Col>
                <Col>
                    <Form.Control ref={teamAdminRef} placeholder={t("teamlead")+" Lichess"} 
                        onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>)  => {
                            if(event.key==="Enter") handleClick("add_team")
                        }}
                    />
                </Col>
                <Col>
                    <Button type="button" className="mb-2" onClick={() => {handleClick("add_team")}}>
                        +
                    </Button>
                </Col>
            </Row>
        </Form>
        <br/>
        <h2 className="p-3">{t("registeredTeams")}</h2>
        <Table>
            <thead>
                <tr>
                    <th>#</th>
                    <th>{t("teamName")}</th>
                    <th>{t("teamlead")}</th>
                    <th>{t("actions")}</th>
                </tr>
            </thead>
            <tbody>
                { tournament.teams.slice().sort(sortFunctionForTeams("alphabetical")).map ( ( team, key ) => 
                    <tr key={key+1} className={isComplete(team, tournament.settings.boardCount, tournament.settings.isStrictMode) ? "isRegistered" : ""}>
                        <td>{key+1}</td>
                        <td>{team.name}</td>
                        <td>{team.teamleaders.join(",")}</td>
                        <td>
                            <ButtonGroup>
                                <Button size="sm" variant="outline-secondary" onClick={()=>setEditTeam(team)}><PencilFill /></Button>
                                <Button size="sm" variant="outline-secondary" onClick={()=>handleDeleteTeam(team.id)}><Trash /></Button>
                            </ButtonGroup>
                        </td>
                    </tr>
                )}
            </tbody>
        </Table>
        </>
    )
}