import React from 'react'
import { Container, Table, Alert, Row, Modal, Button } from 'react-bootstrap';
import { t } from '../../language/translations';
import { APITournament, APIMatch, APITeam } from '../../Types';

import {PlayerResults} from './PlayerResults'
import {isByeMatch} from '../../util/Match'
import { sortFunctionForTeams } from '../../util/Team';
import { Clipboard } from 'react-bootstrap-icons';
import {ButtonGroupSelector} from './ExpansionSelector'
import { alertDispatch } from '../../util/Alerts';
import { ALERT_ADD } from '../../constants';
import { getKoRanks } from './KoTree/KoRankings';
import { isBuchholz } from '../../util/Tournament';

const CROSSTABLE_ID = "crossTable"
const NO_SELECTION = -1

export type Result = {
    opponentId: number|null,
    score: number,
    isHome: boolean,
    isFinished: boolean
}

export type CrosstableRow =  {
    teamId: number
    results: Result[]
    totalMP: number
    totalBP: number
    totalSoBerg: number
    totalBuchholz: number
}


function getOpponentId (teamId: number, match: APIMatch) {
    if (match.firstTeamId === teamId)
        return match.secondTeamId
    else if (match.secondTeamId === teamId)
        return match.firstTeamId
    return -1
}
function getScore(teamId: number, match: APIMatch, boardCount: number) {
    const results = match.games.map(game => game.result ?? -1)

    if (isByeMatch(match))
        return boardCount
    if (teamId===match.firstTeamId)
        return results.reduce((sum, currentResult) => sum+(currentResult<0 ? 0 : currentResult/2),0)
    else if (teamId===match.secondTeamId)
        return results.reduce((sum, currentResult) => sum+(currentResult<0 ? 0 : (0-(currentResult-2)/2)),0)
    else
        return 0
}
function isFinished(match: APIMatch) {
    return (!match.games.some(game => game.result === null) && match.games.length >0)
}

export function getCrossTable (tournament: APITournament) {
    const isBH = isBuchholz(tournament)

    var crossTable: CrosstableRow[] = []
    const numBoards = tournament.settings.boardCount
    tournament.teams.slice(0).sort(sortFunctionForTeams("rating",numBoards)).map(team => crossTable.push({
        teamId: team.id,
        results: tournament.rounds.map(round => {
            let match = round.matches.find(match => (match.firstTeamId===team.id || match.secondTeamId===team.id)) ?? null
            if (!match)
                return ({
                    opponentId: -1,
                    score: 0,
                    isHome: false,
                    isFinished: true
                })
            else
                return ({
                    opponentId: getOpponentId(team.id, match),
                    score: getScore(team.id, match, tournament.settings.boardCount),
                    isHome: match.firstTeamId===team.id,
                    isFinished: isFinished(match)
                })
        }),
        totalMP: 0,
        totalBP: 0,
        totalSoBerg: 0,
        totalBuchholz: 0
    } as CrosstableRow))

    
    for(let i=0; i<crossTable.length; i++) {
        crossTable[i].totalMP = crossTable[i].results.map(result => (result.score>numBoards/2 ? 2 : (result.score===numBoards/2 ? 1 : 0)) as number).reduce((a,b)=>a+b,0)
        crossTable[i].totalBP = crossTable[i].results.reduce((a,b)=>a+b.score,0)
    }
    
    if (!isBH)
        for(let i=0; i<crossTable.length; i++)
            crossTable[i].totalSoBerg = crossTable[i].results.reduce((acc, cur) => 
                acc + cur.score * (crossTable.find(op => op.teamId === cur.opponentId)?.totalMP ?? 0), 0
            )

    const BUCHHOLZ_MAX = 1e9
    if (isBH)
        for(let i=0; i<crossTable.length; i++) {
            var [buchholz, streichwertung] = crossTable[i].results.reduce((acc, cur) => {
                var MP = crossTable.find(op => op.teamId === cur.opponentId)?.totalMP ?? 0
                return [acc[0] + MP, (MP<acc[1] ? MP : acc[1])]
            }, [0,BUCHHOLZ_MAX])
            crossTable[i].totalBuchholz = buchholz - (streichwertung===BUCHHOLZ_MAX ? 0 : streichwertung)
        }

        
    var compareScore = (team: CrosstableRow|null) =>    (team?.totalSoBerg ?? 0) * 2 + 
                                                        (team?.totalBuchholz ?? 0) * 2 +
                                                        (team?.totalBP ?? 0) * 1e6 +
                                                        (team?.totalMP ?? 0) * 1e9
    if (tournament.settings.type===5) {
        const ranks = getKoRanks(tournament)
        compareScore = (team: CrosstableRow|null) =>    (team?.totalSoBerg ?? 0) * 2 + 
                                                        (team?.totalBuchholz ?? 0) * 2 +
                                                        (team?.totalBP ?? 0) * 1e6 +
                                                        (team?.totalMP ?? 0) * 1e9 +
                            1e15 / (ranks.find(row=>row[0]===team?.teamId)?.[1]??0)
    }
    crossTable.sort((team1, team2) => compareScore(team2) - compareScore(team1))

    return crossTable
}


function SinglePlayerResultsModal({tournament, team, onHide}:{tournament:APITournament, team: APITeam, onHide: Function}) {
    return (
        <Modal
            show={true}
            onHide={onHide}
            size="lg"
            aria-labelledby="modal-single-player"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="modal-single-player">
                    {team.name}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <PlayerResults tournament={tournament} players={team.players} sort={false}/>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={()=>onHide()}>{t("close")}</Button>
            </Modal.Footer>
        </Modal>
    )
}

const exportButtons = [
    {action: "clipboard", tooltip: "tt_exportClipboard", label: <Clipboard size={20}/>}
    //{action: "pdf", tooltip: "tt_exportPDF", label: <Download size={20}/>}
] as {action: string, tooltip: string, label: JSX.Element}[]

export function RankCrossTable({tournament}: {tournament: APITournament}){
    const crossTable: CrosstableRow[] = getCrossTable(tournament)
    const [currentTeamId, setCurrentTeamId] = React.useState(NO_SELECTION)

    const exportFunction = (how: string) => {
        const text =    "<style>.crossTable-empty{background-color: grey;} .crossTable-col{width: 40px; min-width: 40px;} .text-center{text-align: center !important;}</style>" + 
                        "<h2>"+t("crossTable")+"</h2>" + 
                        document.getElementById(CROSSTABLE_ID)?.outerHTML +
                        "Weitere Informationen zum Turnier finden Sie <a href='https://turniere.schachklub-kelheim.de'>hier</a>"
        if (how === "clipboard" && text !== undefined) {
            navigator.clipboard.writeText(text)
            alertDispatch(  {type: ALERT_ADD, payload: {title: "HTML wurde in die Zwischenablage kopiert",
                            text: "", variant: "success"}})
        }
    }

    const team = tournament.teams.find(t=>t.id===currentTeamId)
    const isBH = isBuchholz(tournament)

    return(
        <>
        {team &&
            <SinglePlayerResultsModal tournament={tournament} team={team} onHide={()=>setCurrentTeamId(NO_SELECTION)}/>
        }
        <Container fluid="md" className="px-0">
            <Row className="p-3 m-0 d-md-flex justify-content-between">
                <h2 className="px-3 px-md-0">{t("crossTable")}</h2>
                <ButtonGroupSelector buttons={exportButtons} handleClick={exportFunction}/>
            </Row>            
            
            {crossTable.length > 16 &&
                <Alert variant="info">Wir wissen, dass Kreuztabellen bei so vielen Teilnehmern nur mehr begrenzt sinnvoll sind. Manch einer will sie aber trotzdem. Bittschön &#128517;. Die Darstellung kann - je nach Gerät - etwas komisch sein.</Alert>
            }
            <Table className="text-center table-vcenter table-bordered" size="sm" hover id={CROSSTABLE_ID}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th className="text-left">{t("team")}</th>
                        {crossTable.map((_, key) => 
                            <th key={key}>{key+1}</th>
                        )}
                        <th>MP</th>
                        <th>BP</th>
                        <th>{isBH ? "Buchholz" : "SoBerg"}</th>
                    </tr>
                </thead>
                <tbody>
                    {crossTable.map((crossTableRow, key) =>
                        <tr key={key} onClick={()=>setCurrentTeamId(crossTableRow.teamId)}>
                            <td>{key+1}</td>
                            <td className="text-left clickable text-nowrap">{tournament.teams.find(team => team.id === crossTableRow.teamId)?.name ?? ""}</td>
                            {crossTable.map((crossTableRowOpponent, key2) => {
                                let results = crossTableRow.results.filter(result => result.opponentId === crossTableRowOpponent.teamId)

                                return (
                                <td key={key2} className={
                                    (crossTableRowOpponent.teamId === crossTableRow.teamId ? "crossTable-empty": "") + 
                                    (results.some(r=>r.isFinished === false) ? " live " : " ")  + " crossTable-col"
                                }>
                                    {
                                        results.map((result,key) =>
                                            <span key={key}>
                                                {key>0 && <br/>}
                                                {(crossTableRowOpponent.teamId === crossTableRow.teamId ? "X": result?.score ?? "")}
                                            </span>
                                        )
                                    }
                                </td>)
                            })}
                            <td>{crossTableRow.totalMP}</td>
                            <td>{crossTableRow.totalBP}</td>
                            <td>{isBH ? crossTableRow.totalBuchholz : crossTableRow.totalSoBerg}</td>
                        </tr>
                    )}
                </tbody>
                {crossTable.length === 0 &&
                    <caption>
                        {t("noTeamsYet")}
                    </caption>
                }
            </Table>
        </Container>
        </>
    )
}