import { RESULT } from "../constants";
import { APIMatch } from "../Types";

export function isByeMatch(match: APIMatch) {
    return match.firstTeamId===null || match.secondTeamId===null
}

/**
 * 
 * @param match 
 * @returns array of sum of board points for both teams; sets [2,0] or [0,2] when the opponent is bye
 */
export function matchResult(match:APIMatch): [number,number] {
    var firstSum = 0
    var secondSum = 0

    if (match.firstTeamId === null)
        return [0,2]
    if (match.secondTeamId === null)
        return [2,0]

    match.games.forEach(game=> {
        switch (game.result) {
            case RESULT.player1Won:
                firstSum+=1
                break;
            case RESULT.player2Won:
                secondSum+=1
                break;
            case RESULT.draw:
                firstSum+=0.5
                secondSum+=0.5
                break;
            default:
                break;
        }
    })
    return [firstSum,secondSum]
}

/**
 * 
 * @param match 
 * @returns true if first player has won, false if second player has won or the match is a draw
 */
export function hasFirstWon(match:APIMatch): boolean {
    const result = matchResult(match)
    return result[0] > result[1]
}


/**
 * 
 * @param match
 * @returns undefined for a non-finished, 1 if player1 won, 2 if player2 won
 */
export function koMatchWinner(match:APIMatch): number|undefined {
    const score = matchResult(match)
    if ([1,3].includes(match.games.length) || match.games.some(game=>game.result===null))
        return undefined

    if (score[0] > score[1])
        return 1
    if (score[0] < score[1])
        return 2

    return undefined
}