import { Badge, Col, Nav, Row } from 'react-bootstrap'
import {LinkContainer} from 'react-router-bootstrap'
import { t } from '../language/translations'
import {APITournament} from '../Types'
import {getLiveGamesCount} from '../util/Round'
import Image from 'react-bootstrap/Image'

type Props = {
    tournament: APITournament
}

export function TournamentSideBar ({tournament}: Props) {
    
    var liveGamesCount = 0
    if (tournament.rounds.length>0)
        liveGamesCount = getLiveGamesCount(tournament.rounds[tournament.rounds.length-1])

    return (
        <Nav variant="pills" className="flex-column justify-content-between pt-2 h-100">
            <Row className="flex-row-fill">
                <Col>
                    { tournament.settings.type === 5 &&
                        <>
                        <LinkContainer to={"/"+tournament.id+"/ko"}>
                            <Nav.Link>
                                K.o.-Baum
                                {liveGamesCount>0 &&
                                    <Badge variant="success" className="ml-1">
                                        {liveGamesCount}
                                    </Badge>
                                }
                            </Nav.Link>
                        </LinkContainer>
                        <div className="v-divider"></div>
                        </>
                    }
                    { tournament.rounds.map((round, key) => {                        
                        return (
                            <LinkContainer to={"/"+tournament.id+"/round/"+(key+1)} key={key}>
                                <Nav.Link>
                                    {t("round")} {key+1}
                                    {liveGamesCount>0 && key === tournament.rounds.length-1 &&
                                        <Badge variant="success" className="ml-1">
                                            {liveGamesCount}
                                        </Badge>
                                    }
                                </Nav.Link>
                            </LinkContainer>
                        )
                    })}
                    { tournament.rounds.length === 0 &&
                        <Nav.Link disabled>{t("noRoundShort")}</Nav.Link>
                    }

                    <div className="v-divider"></div>

                    {tournament.broadcastLink &&
                        <>
                            <Nav.Link as="a" href={tournament.broadcastLink} target="_blank" active={false}>
                                Live Partien
                            </Nav.Link>
                            <div className="v-divider"></div>
                        </>
                    }

                    {tournament.settings.type === 5 &&
                        <LinkContainer to={"/"+tournament.id+"/ko-ranking"}>
                            <Nav.Link>Tabelle</Nav.Link>
                        </LinkContainer>
                    }
                    {tournament.settings.type !== 5 &&
                        <LinkContainer to={"/"+tournament.id+"/tabelle"}>
                            <Nav.Link>{t("crossTable")}</Nav.Link>
                        </LinkContainer>
                    }
                    <LinkContainer to={"/"+tournament.id+"/fortschrittstabelle"}>
                        <Nav.Link>{t("progressTable")}</Nav.Link>
                    </LinkContainer>
                    {tournament.settings.type !== 5 &&
                        <LinkContainer to={"/"+tournament.id+"/einzel"}>
                            <Nav.Link>{t("singlePlayerResults")}</Nav.Link>
                        </LinkContainer>
                    }
                    <LinkContainer to={"/"+tournament.id+"/teilnehmer"}>
                        <Nav.Link>{t("participantsList")}</Nav.Link>
                    </LinkContainer>
                </Col>
            </Row>
            <Row className="flex-row-adapt justify-content-around">
                <a href="https://schachklub-kelheim.de" target="_blank" rel="noreferrer">
                    <Image src="/skk-logo.png" className="px-2 skk-logo"/>
                </a>
            </Row>
        </Nav>
    )
}