import React from 'react';
import { Container } from 'react-bootstrap';
import { User } from '../../Types';

import {NavBar} from '../NavBar'

import {TournamentAddForm} from './TournamentAddForm'
import {AdminEventTrigger} from './AdminEventTrigger'
import {TournamentOptions} from './TournamentOptions'
import {SetRefreshRates} from './SetRefreshRates'
import { RenameUserForm } from './RenameUserForm';



export function SiteAdminPage({user}: {user: User|null}){
    if(!user)
        return <Container>Sie müssen sich zuerst anmelden!</Container>
    return (
        <>
            <NavBar user={user} tournament={null}/>
            <Container className="mainFrame">
                <TournamentAddForm/>
                <br/>
                <RenameUserForm/>
                <br/>
                <AdminEventTrigger user={user}/>
                <br/>
                <SetRefreshRates/>
                <br/>
                <TournamentOptions/>
            </Container>
        </>
    )
}