import {APITeam, APITournament, User} from '../Types'

function isTeamLeadInTeam (team: APITeam|null, lichessName: string|null) {
    if (!team || !lichessName)
        return false
    return team.teamleaders.some(teamleader => teamleader === lichessName)
}

export function isTeamLeadOrHigherInTeam (tournament: APITournament, team: APITeam, user: User|null) {
    if (!tournament || !team || !user)
        return false
    return isTournamentAdminOrHigher(tournament, user) || isTeamLeadInTeam(team, user.lichessName)
}

function isTeamLeadInTournament (tournament: APITournament|null, lichessName: string|null) {
    if (!tournament || !lichessName)
        return false
    return tournament.teams.some(team => isTeamLeadInTeam(team, lichessName))
}

export function isTournamentAdmin (tournament: APITournament|null, lichessName: string|null) {
    if (!tournament || !lichessName)
        return false
    return tournament.admins.some(admin => admin === lichessName)
}

export function isTournamentAdminOrHigher (tournament: APITournament|null, user: User|null) {
    if(!tournament || !user)
        return false
    return user.isSiteAdmin || isTournamentAdmin(tournament, user.lichessName)
}

export function isTeamLeadOrHigher (tournament: APITournament|null, user: User|null) {
    if(!tournament || !user)
        return false
    return isTournamentAdminOrHigher(tournament, user) || isTeamLeadInTournament(tournament, user.lichessName)
}