import { DOMAIN, LOCAL_STORAGE_TOURNAMENTS } from "../../constants";
import { APITournament } from "../../Types";
import { getRequestHeaderFromStorage } from "../ApiRequest";

const DOMAIN_FETCH_TOURNAMENTS = DOMAIN + "/get_tournaments"

type Date = {
    year : number,
    month: number,
    day: number
}

function getDateFromString (date : string|null): Date {
    if(!date)
        return {year: 0, month: 0, day: 0}

    const splits = date.split(".")
    if (splits.length >= 2)
        return {year: parseInt(splits[0]), month: parseInt(splits[1]), day: parseInt(splits[2])}
    else
        return {year: 0, month: 0, day: 0}
}

function compareDates (date1AsString: string|null, date2AsString: string|null) {
    const date1 = getDateFromString(date1AsString)
    const date2 = getDateFromString(date2AsString)
    
    return  (date2.year-date1.year)*100 +
            (date2.month-date1.month)*10 +
            (date2.day-date1.day)*1
}

function sortTournaments (a: APITournament,b: APITournament): number {
    return compareDates(a.settings.startDate,b.settings.startDate)
}

export async function FetchTournaments () {
    const requestHeader = getRequestHeaderFromStorage ()
    requestHeader.set("Content-Type", "application/json")

    const fetchRes = await fetch(DOMAIN_FETCH_TOURNAMENTS, {
        method: 'GET',
        headers: requestHeader
    });
    const res: APITournament[] = await fetchRes.json();
    res.sort(sortTournaments)
    return res
}

export function StoreTournaments (tournaments: APITournament[]) {
    try {
        localStorage.setItem(LOCAL_STORAGE_TOURNAMENTS, JSON.stringify(tournaments.slice(0,20)))
    }
    catch (e) {
        console.log(e)
    }
}

export function LoadTournaments (): APITournament[] {
    const storedTournaments = localStorage.getItem(LOCAL_STORAGE_TOURNAMENTS)
    if(storedTournaments)
        return JSON.parse(storedTournaments)
    else
        return []
}