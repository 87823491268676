import React from 'react';
import { Modal, Button, Form, Col, Row } from 'react-bootstrap';
import { ALERT_ADD, DOMAIN_ADMINISTRATOR } from '../../constants';
import { t } from '../../language/translations';

import {APITournament, UIAlertAction} from '../../Types'
import { alertDispatch } from '../../util/Alerts';
import {sendRequestToBackendText} from '../../util/ApiRequest'
import { isLichessUserName } from '../../util/Validation';

const DOMAIN_EDIT_TOURNAMENT = DOMAIN_ADMINISTRATOR + "/edit_tournament"

type Props = {
    tournament: APITournament,
    onHide: any,
    updateTournaments: Function
}

export function EditTournamentModal({tournament, onHide, updateTournaments}: Props) {
    const nameRef = React.useRef<HTMLInputElement>(null)
    const aliasRef = React.useRef<HTMLInputElement>(null)
    const adminRef = React.useRef<HTMLInputElement>(null)

    const handleClick = async () => {
        const admins = (adminRef.current?.value ?? "").split(",").map(admin => admin.trim())

        if (admins.some(admin => !isLichessUserName(admin))){
            alertDispatch({type: ALERT_ADD, payload: {title: "Das Turnier wurde nicht erstellt", text: "Ein Lichess-Name wurde nicht gefunden.", variant: "danger"}} as UIAlertAction)
            adminRef.current?.classList.add("border-danger")
            return
        }

        var data = {
            previousId: tournament.id,
            name: nameRef.current?.value ?? "",
            newId: aliasRef.current?.value ?? "",
            admins: admins
        }    
        if (await sendRequestToBackendText(DOMAIN_EDIT_TOURNAMENT, data)) {
            onHide()
            updateTournaments()
        }
    }

    return (
        <Modal
            size="lg"
            show={true}
            aria-labelledby="editTeamModal"
            onHide={onHide}
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="editTeamModal">
                    Turnier bearbeiten
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col>
                            <Form.Control ref={nameRef} placeholder={t("tournamentName")} defaultValue={tournament.name}/>
                        </Col>
                        <Col>
                            <Form.Control ref={aliasRef} placeholder={t("tournamentAlias")} defaultValue={tournament.id}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Control ref={adminRef} placeholder={t("tournamentAdmin")+" Lichess"} defaultValue={tournament.admins.join(",")}
                                onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>)  => {
                                    if(event.key==="Enter") handleClick()
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button type="button" className="mb-2" onClick={() => {handleClick()}}>
                                Speichern
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide}>Schließen</Button>
            </Modal.Footer>
        </Modal>
    );
}