import { useRef, useEffect } from 'react'

const DEFAULT_DELAY = 5000

function useTimeout(callback: () => void, delay: number) {
    const savedCallback = useRef<() => void | null>()
    const savedDelay = useRef<number>(DEFAULT_DELAY)
    
    // Remember the latest callback.
    useEffect(() => {
       savedCallback.current = callback
    }, [callback])

    // Set up the interval.
    useEffect(() => {
        savedDelay.current = delay
    }, [delay])

    useEffect(() => {
        var stopFlag = false
        async function tick() {
            if (stopFlag) return
            if (typeof savedCallback?.current !== 'undefined') {
                try {
                    await savedCallback?.current()
                }
                finally {
                    setTimeout(tick, savedDelay?.current??DEFAULT_DELAY)
                }
            }
        }
        tick()
        return ()=>{stopFlag=true}
    },[])
}

export default useTimeout