import React, { useCallback, useState } from 'react'

import { Route, Switch, useParams } from "react-router-dom";
import { NavBar } from "./NavBar";
import { Alert, Col, Container, Row, Spinner } from 'react-bootstrap';
import { TeamLeadPage } from "./teamLead/TeamLeadPage";
import { Round } from "./guest/Round"
import { TournamentSelector } from "./guest/TournamentSelector";
import {TournamentAdminPage} from "./tournamentAdmin/TournamentAdminPage"
import {RankCrossTable} from "./guest/RankCrossTable"
import {RankProgressTable} from "./guest/RankProgressTable"
import {SinglePlayerResults} from "./guest/SinglePlayerResults"

import { TeamParticipantsList } from "./guest/TeamParticipantsList";
import { APITournament, User } from '../Types';
import { refreshInterval, TITLE_PREFIX } from '../constants';
import { FetchTournament, LoadTournament, StoreTournament } from '../util/api/FetchTournament';
import { TournamentSideBar } from './TournamentSideBar';
import { t } from '../language/translations';
import useTimeout from '../util/CustomHook/useTimeout';
import { getLiveGamesCount } from '../util/Round';
import { KoTree } from './guest/KoTree/KoTree';
import { KoRankings } from './guest/KoTree/KoRankings';

interface RouteParams {
    tournamentId: string
}

export function TournamentPage({user}: {user: User|null}){
    const params = useParams<RouteParams>();
    const tournamentId = params.tournamentId
    const [tournament, setTournament] = useState(LoadTournament(tournamentId) as undefined|null|APITournament)

    const updateTournament = useCallback(async function () { 
        const newTournament = await FetchTournament(tournamentId)
        if (newTournament) {
            newTournament.teams = newTournament.teams.sort((t1,t2) => t1.id-t2.id)
            StoreTournament(newTournament)
        }
        setTournament(newTournament)
    }, [tournamentId])

    useTimeout(updateTournament, refreshInterval.get_tournament)

    document.title = TITLE_PREFIX + (tournament?.name??"")

    if (!tournament)
        return(
            <>
                <NavBar user={user} tournament={null}/>
                <Container>
                    {tournament === undefined &&
                        <Alert variant="info" className="text-center">
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </Alert>
                    }
                    {tournament === null &&
                        <Alert variant="warning">
                            {t("tournamentNotFound1") + params.tournamentId + t("tournamentNotFound2")}
                        </Alert>
                    }
                </Container>
                <TournamentSelector/>
            </>
        )
    return (
        <>
            <NavBar user={user} tournament={tournament}/>
            <Row className="mb-auto mx-0 flex-grow-1">
                <Switch>
                    <Route exact path="/:tournamentId/teamlead">
                        <TeamLeadPage user={user} tournament={tournament}/>
                    </Route>
                    <Route path="/:tournamentId/teamlead/:teamId">
                        <TeamLeadPage user={user} tournament={tournament}/>
                    </Route>
                    <Route exact path="/:tournamentId/admin">
                        <TournamentAdminPage user={user} tournament={tournament} updateTournament={updateTournament}/>
                    </Route>
                    <Route path="/:tournamentId/admin/:action">
                        <TournamentAdminPage user={user} tournament={tournament} updateTournament={updateTournament}/>
                    </Route>
                    <>
                        <Col className="col-md-3 col-lg-2 d-none d-md-block">
                            <TournamentSideBar tournament={tournament} />
                        </Col>
                        <Col className={"col-12 col-md-9 col-lg-10 mainFrame " + (tournament.settings.type===5 ? "col-xl-10" : "col-xl-8")}>
                            <Switch>
                                {tournament.settings.type === 5 &&
                                    <Route path="/:tournamentId/ko/:roundId?">
                                        <KoTree tournament={tournament}/>
                                    </Route>
                                }
                                {tournament.settings.type === 5 &&
                                    <Route path="/:tournamentId/ko-ranking">
                                        <KoRankings tournament={tournament}/>
                                    </Route>
                                }
                                <Route path="/:tournamentId/teilnehmer">
                                    <TeamParticipantsList tournament={tournament}/>
                                </Route>
                                <Route path="/:tournamentId/einzel">
                                    <SinglePlayerResults tournament={tournament}/>
                                </Route>
                                <Route path="/:tournamentId/tabelle">
                                    <RankCrossTable tournament={tournament}/>
                                </Route>
                                <Route path="/:tournamentId/fortschrittstabelle">
                                    <RankProgressTable tournament={tournament}/>
                                </Route>
                                <Route path="/:tournamentId/round/:roundId">
                                    <Round user={user} tournament={tournament}/>
                                </Route>
                                {tournament.settings.type === 5 &&
                                    <Route path="/:tournamentId/round">
                                        <Round user={user} tournament={tournament}/>
                                    </Route>
                                }
                                <Route path="/:tournamentId/">
                                    {(tournament.settings.roundCnt !== null && tournament.settings.type !== 5 &&
                                    tournament.rounds.length >= tournament.settings.roundCnt &&
                                    getLiveGamesCount(tournament.rounds[tournament.rounds.length-1])) === 0 &&
                                        (tournament.settings.type === 1 ?
                                            <RankCrossTable tournament={tournament}/> : 
                                            <RankProgressTable tournament={tournament}/>
                                        )
                                    }
                                    {tournament.settings.type !== 5 &&
                                        <Round user={user} tournament={tournament}/>
                                    }
                                    {tournament.settings.type === 5 &&
                                        <KoTree tournament={tournament}/>
                                    }
                                </Route>
                            </Switch>
                        </Col>
                    </>
                </Switch>
            </Row>
        </>
    )
};