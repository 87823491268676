import { Form, Col, Row, Button, ButtonGroup} from 'react-bootstrap';
import { t } from '../../language/translations';
import {APIPairing, APITeam, APITournament} from '../../Types';

import { sortFunctionForTeams } from '../../util/Team';

import {RankCrossTable} from '../guest/RankCrossTable';
import { SwissPairing } from './SwissPairingModal';
import {SetPairingsConfirm} from './SetPairingsConfirm'
import { hasFirstWon } from '../../util/Match';

const INPUT_ID_PREFIX = "pairing_"

export const TEAM_ID_BYE = 0
export const TEAM_ID_NOT_PAIRED = -1


function replace0WithNull (num: number|null): number|null {
    return (num===TEAM_ID_BYE ? null : num)
}

function changeValue (board: number, teamId1: string|number, teamId2: string|number) {
    const t1: HTMLSelectElement|null = (document?.getElementById(INPUT_ID_PREFIX+board+"_0") as HTMLSelectElement)
    const t2: HTMLSelectElement|null = (document?.getElementById(INPUT_ID_PREFIX+board+"_1") as HTMLSelectElement)
    
    if (t1)
    t1.value = teamId1.toString();
    if (t2)
    t2.value = teamId2.toString();
}

function enterPairings(pairings: APIPairing[]) {
    pairings.forEach((pairing,key)=>changeValue(key,pairing.first??TEAM_ID_BYE,pairing.second??TEAM_ID_BYE))
}

function clearFields(tables: number) {
    Array.from({length: tables}).forEach((_,key) => changeValue(key, TEAM_ID_NOT_PAIRED, TEAM_ID_NOT_PAIRED))
}

function handlePairings(tableCount: number, pairings: APIPairing[]) {
    clearFields(tableCount)

    pairings.forEach((pairing,boardId)=> {
        changeValue(boardId,
                    pairing.first??TEAM_ID_BYE, 
                    pairing.second??TEAM_ID_BYE)
    })
}

export function getPairings (tableCount: number): APIPairing[] {
    var pairings = [] as APIPairing[]
    for (let i=0; i<tableCount; i++) {
        const first  = parseInt((document?.getElementById(INPUT_ID_PREFIX+i+"_0") as HTMLInputElement)?.value ?? TEAM_ID_NOT_PAIRED)
        const second = parseInt((document?.getElementById(INPUT_ID_PREFIX+i+"_1") as HTMLInputElement)?.value ?? TEAM_ID_NOT_PAIRED)

        if (!(first === TEAM_ID_NOT_PAIRED || second === TEAM_ID_NOT_PAIRED))
            pairings.push({first: replace0WithNull(first), second: replace0WithNull(second)})
    }
    return pairings
}

function RoundRobinPairing(teams: APITeam[], playedRoundsCount: number) {
    var currentCircle = teams.map(team => team.id)
    if (currentCircle.length % 2 !== 0)
        currentCircle.unshift(TEAM_ID_NOT_PAIRED)

    Array.from({length: playedRoundsCount}).forEach(() => currentCircle.splice(1,0,currentCircle.pop() ?? -1))

    function isWhiteUp(boardNumber: number, roundNumber: number, passNumber: number) : boolean {
        if (boardNumber === 0)
            return roundNumber % 2 === 0
        else 
            return (boardNumber+passNumber) % 2 === 0
    }

    const passNumber = Math.floor((playedRoundsCount+1) / currentCircle.length)
    for (let boardId=0; boardId<(currentCircle.length/2);boardId++) {
        let whiteTeamId = ( isWhiteUp(boardId, playedRoundsCount, passNumber) ? currentCircle[boardId] : currentCircle[currentCircle.length-1-boardId]);
        let blackTeamId = (!isWhiteUp(boardId, playedRoundsCount, passNumber) ? currentCircle[boardId] : currentCircle[currentCircle.length-1-boardId]);
        
        changeValue(boardId, whiteTeamId.toString(), blackTeamId.toString())
    }
}

function KoPairing(tournament: APITournament) {
    var teamIds: number[]

    // First round, just shuffle:
    if (tournament.rounds.length === 0) {
        teamIds = tournament.teams.map(team=>team.id) 
        teamIds.sort(() => Math.random() - 0.5);
    }
    // Every other round, add winners from last round:
    else {
        const round_num = tournament.rounds.length
        var previousMatches = tournament.rounds[round_num-1].matches.slice()
        teamIds = previousMatches.map(match=>(hasFirstWon(match) ? match.firstTeamId??0 : match.secondTeamId??0))

        const luckyLoser = tournament.settings.koSettings?.luckyLoser.filter(ll=>ll.round===round_num)??[]
        luckyLoser.forEach(ll => teamIds.splice(ll.position,0, ll.teamId??TEAM_ID_NOT_PAIRED))
        
        // Last round, add losers from previous round
        if (tournament.rounds.length === ((tournament.settings.roundCnt??0)-1)) {
            previousMatches.forEach(match=> {
                teamIds.push(hasFirstWon(match) ? match.secondTeamId??0 : match.firstTeamId??0)
            })
        }
    }
    
    var pairings: APIPairing[] = []
    for (let i=0; i<teamIds.length-1; i+=2)
        pairings.push({first: teamIds[i], second: teamIds[i+1]})

    // give last a bye opponent
    if (teamIds.length % 2 !== 0)
        pairings.push({first: teamIds[teamIds.length-1], second: null})

    enterPairings(pairings)
    return pairings
}

function PairingSelector ({teams, pairing_id}: {teams: APITeam[], pairing_id: number}){

    const options: {label: string, value: number}[] = [
        {label: "-", value: TEAM_ID_NOT_PAIRED},
        ...teams.map(t => ({label:t.name, value: t.id})),
        {label: "Spielfrei", value: TEAM_ID_BYE}
    ]

    const TeamSelect = (id: string) => {
        return(
            <Col key={pairing_id + "-" + id}>
                <Form.Group>
                   <Form.Control id={INPUT_ID_PREFIX+id} as="select">
                        { options.map((option, key) =>
                            <option value={option.value} key={pairing_id + "-" + key}>{option.label}</option>
                        )}
                    </Form.Control>
                </Form.Group>
            </Col>
        )
    }

    return (
        <Row>
            <Col style={{width: "3rem", maxWidth: "3rem"}} className="text-center pt-1">
                {pairing_id+1}
            </Col>
            {[0,1].map((index) => TeamSelect(pairing_id+"_"+index))}
        </Row>
    )
}

        
export function SetPairings({tournament, updateTournament}: {tournament: APITournament, updateTournament: Function}){
    const teamsLinks = tournament.teams.slice(0)
    teamsLinks.sort(sortFunctionForTeams("alphabetical"))

    return(
        <Form>
            <h2 className="p-3">
                {t("ta_pair_title")}
            </h2>
            {Array.from({length: Math.ceil(teamsLinks.length/2)}).map((_,key) =>
                <PairingSelector teams={teamsLinks} pairing_id={key} key={key}/>)
            }
            <Row>
                <Col>
                    <SetPairingsConfirm tournament={tournament} rerenderParent={updateTournament}/>
                </Col>
            </Row>
            <br/>
            <br/>

            <Form.Group as={Row}>
                <Form.Label column xs="3">
                    {t("nextRound")} ({t("round")} {tournament.rounds.length+1})
                </Form.Label>
                <Col xs="7">
                    <ButtonGroup>
                        { [0,1].includes(tournament.settings.type) &&
                            <Button variant="outline-secondary" onClick={()=>RoundRobinPairing(tournament.teams, tournament.rounds.length)}>
                            {t("roundRobin")}
                            </Button>
                        }
                        { [0,2].includes(tournament.settings.type) && 
                            <SwissPairing tournament={tournament} handlePairings={(pairings: APIPairing[]) => handlePairings(Math.ceil(tournament.teams.length/2), pairings)}/>
                        }
                        { [0,5].includes(tournament.settings.type) && 
                            <Button variant="outline-secondary" onClick={()=>KoPairing(tournament)}>
                            K.o.-System
                            </Button>
                        }
                    </ButtonGroup>     
                </Col>
            </Form.Group>

            <Row className="mt-5">
                <RankCrossTable tournament={tournament} />
            </Row>
        </Form>
    )
}