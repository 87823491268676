import React from 'react'
import { Link, useParams } from "react-router-dom";
import Xarrow from "react-xarrows";
import { Row, Col, Button} from 'react-bootstrap';
import { APIMatch, APITournament } from '../../../Types';

import { TeamParticipantsList } from '../TeamParticipantsList';
import { KoMatch } from './KoMatch';

import './Ko.css';
import { ChevronLeft, ChevronRight} from 'react-bootstrap-icons';
import { getKoRoundName } from '../../../util/Round';

const DEFAULT_WIDTH = 1000
const DEFAULT_COL_WIDTH = 405

//const NUM_LUCKY_LOSER = -1
const NUM_EMPTY_MATCH = -2
const NUM_3RD_PLACE = -3

function createEmptyMatches (count: number): number[] {
    var matches: number[] = []
    for (let i=0; i<count; i++)
        matches.push(NUM_EMPTY_MATCH)
    return matches    
}

/**
 * to be used before printing the tree
 * @param rounds 
 * @param roundNum 
 * @param luckyLoser 
 * @returns fills luckyLoser and empty matches in the played matches
 */
function matchesForTree(tournament: APITournament, roundNum: number, roundsToDisplay:number[]): (APIMatch|number|null)[] {
    var matches: (APIMatch|number|null)[] = tournament.rounds[roundNum].matches.slice()
    const luckyLoser = tournament.settings.koSettings?.luckyLoser??[]
    
    luckyLoser.filter(ll=>roundsToDisplay.includes(ll.round)||roundsToDisplay.includes(ll.round-1)).forEach(ll=> {
        const delta = ll.round - roundNum
        if (delta === 1) 
            matches.splice(ll.position,0,ll.teamId)
        if (delta > 1) {
            const branchingFactor = Math.pow(2,delta-1)
            matches.splice(ll.position * branchingFactor,0,...createEmptyMatches(branchingFactor))
        }
    })

    // Do stuff for 3rd place match
    if (tournament.settings.roundCnt === roundNum+1)
        matches.unshift(NUM_3RD_PLACE)

    return matches
}

function drawLines(roundsToDisplay: number[], allMatches: (APIMatch|number|null)[][]) {
    return (
        <>
            {
                roundsToDisplay.slice(0,-1).map((r,i)=>
                    allMatches[i].map((m,j)=>{
                        // check start
                        if(m === NUM_EMPTY_MATCH)
                            return <></>
                        var id1 = "match-"+(r)+"-"+j
                        var id2 = "match-"+(r+1)+"-"+Math.floor(j/2)

                        // TODO: hässlich
                        if(allMatches[i+1][0] === NUM_3RD_PLACE)
                            id2 = "match-"+(r+1)+"-"+Math.floor(1)
                        else if(typeof(allMatches[i+1][Math.floor(j/2)]) === "number")
                            return <></>

                        if(!document.getElementById(id1) || !document.getElementById(id2))
                            return <></>


                        return (
                            <Xarrow start={id1} end={id2} strokeWidth={2} headSize={6} color="grey" key={r + "-" + i + "-" + j}/>
                        )
                    })
                )
            }
        </>
    )
}

function calcPrevRounds (width:number,tournament:APITournament): number{
    return Math.min(Math.max(Math.floor(width/DEFAULT_COL_WIDTH)-1,0),tournament.rounds.length-1)
}

function initLastRound (roundId: string|null,roundCount: number) {
    if (roundId && parseInt(roundId)-1<roundCount)
        return parseInt(roundId)-1
    else
        return roundCount-1
}

interface RouteParams {
    tournamentId: string
    roundId: string
}

export function KoTree({tournament}: {tournament: APITournament}){
    const params = useParams<RouteParams>();

    const [width, setWidth] = React.useState(DEFAULT_WIDTH)
    const [roundLength, setRoundLength] = React.useState(tournament.rounds.length)
    const [lastRoundInTree, setLastRoundInTree] = React.useState(initLastRound(params.roundId, tournament.rounds.length))
    const [render, setRender] = React.useState(0)

    if (roundLength !== tournament.rounds.length)
        setRoundLength(tournament.rounds.length)

    const previousRounds = calcPrevRounds(width,tournament)
    const mainRef = React.useRef<HTMLDivElement>(null)
        
    React.useEffect(() => {
        setWidth(mainRef.current?.offsetWidth??DEFAULT_WIDTH)

        const resizeHandler = () => {
            setWidth(mainRef.current?.offsetWidth??DEFAULT_WIDTH)
        }

        window.addEventListener('resize', resizeHandler);

        return () => {
            window.removeEventListener('resize', resizeHandler)
        }
    },[])

    React.useEffect(() => {
        setLastRoundInTree(initLastRound(params.roundId,roundLength))
    },[params.roundId,roundLength])

    React.useEffect(() => {
        setRender(render+1)
    },[lastRoundInTree])

    console.log(render)
    if (lastRoundInTree<0)
        return (<><TeamParticipantsList tournament={tournament}/></>)

    var roundsToDisplay: number[] = []
    for (let i=0; i<=previousRounds && lastRoundInTree-i >= 0; i++) {
        roundsToDisplay.unshift(lastRoundInTree-i)
    }
    
    var allMatches: (APIMatch|number|null)[][] =
    roundsToDisplay.map(roundNum=>
        matchesForTree(tournament,roundNum,roundsToDisplay)
    )
    return(
        <>
            <Row className="flex-row-adapt ko-row ko-headline-row">
                {roundsToDisplay.map(roundNum=> {
                    return (
                        <Col className="ko-round-col" key={roundNum}>
                        <h3 className='text-center my-0'>{getKoRoundName(tournament.settings.roundCnt,roundNum+1)}</h3>
                    </Col>)
                    }
                )}
                <Link to={"/"+tournament.id+"/ko/"+(lastRoundInTree)} style={{position: "absolute", left: "9px"}}>
                    <Button size="sm" onClick={()=>{setLastRoundInTree(lastRoundInTree-1)}} variant="outline-secondary" disabled={roundsToDisplay.includes(0)}>
                        <ChevronLeft size="30"/>
                    </Button>
                </Link>
                <Link to={"/"+tournament.id+"/ko/"+(tournament.rounds.length===lastRoundInTree+2 ? "" : lastRoundInTree+2)}  style={{position: "absolute", right: "9px"}}>
                    <Button size="sm" onClick={()=>{setLastRoundInTree(lastRoundInTree+1)}} variant="outline-secondary" disabled={roundsToDisplay.includes(tournament.rounds.length-1)}>
                        <ChevronRight size="30"/>
                    </Button>
                </Link>
            </Row>
            <Row className="flex-row-fill ko-row" ref={mainRef}>
                {roundsToDisplay.map((roundNum,i)=>
                    <Col className="ko-round-col" key={roundNum}>
                        {allMatches[i].map((match,j)=>
                            <KoMatch tournament={tournament} matchOrNum={match} key={roundNum+"-"+j} id={"match-"+roundNum+"-"+j}/>
                            )}
                    </Col>
                )}
            </Row>
            {drawLines(roundsToDisplay, allMatches)}
        </>
    )
}