import React from 'react'
import { Table, Button, ButtonGroup, Alert} from 'react-bootstrap';
import {ArrowUp, ArrowDown, Trash, Pause } from 'react-bootstrap-icons';
import { APITournament, APITeam, User, UIAlertAction, UINomination, APIPlayer, APITournamentSettings } from '../../Types';

import {DOMAIN, ALERT_ADD} from '../../constants'
import {getRequestHeader} from '../../util/ApiRequest'
import { t } from '../../language/translations';
import { isTournamentAdminOrHigher } from '../../util/Rights';
import { isPlayerPaused } from '../../util/Player';
import { alertDispatch } from '../../util/Alerts';

const NAME_TOURNAMENT_ID= "tournamentId"
const NAME_TEAM_ID      = "teamId"
const NAME_LICHESS_NAME = "lichessName"
const NAME_ACTION       = "action"
const NAME_NOMINATIONS  = "nominations"
const ACTION_PROMOTE    = "promote"
const ACTION_DEGRADE    = "degrade"
const ACTION_DELETE     = "delete"
const ACTION_PAUSE      = "pause"

const URL_ALIAS         = "editTeam"

async function sendRequestToBackend(token: string|null, tournamentId:string,
                                    teamId: number, lichessName: string|null, 
                                    action: string, nominations: UINomination[]){
    if (lichessName===null)
        return

    const requestHeader = getRequestHeader (token)

    const target = DOMAIN + "/teamlead/" + URL_ALIAS    + "?" + NAME_TOURNAMENT_ID  + "=" + tournamentId
                                                        + "&" + NAME_TEAM_ID        + "=" + teamId
                                                        + "&" + NAME_LICHESS_NAME   + "=" + lichessName
                                                        + "&" + NAME_ACTION         + "=" + action
                                                        + "&" + NAME_NOMINATIONS    + "=" + JSON.stringify(nominations)

    const fetchRes = await fetch(target, {
        method: 'GET',
        headers: requestHeader
    });
    
    if(fetchRes.ok){
        const txt = await fetchRes.text()
        alertDispatch({type: ALERT_ADD, payload: {title: "Gespeichert", text: txt, variant: "success"}} as UIAlertAction)
    }
    else{
        const txt = await fetchRes.text()
        alertDispatch({type: ALERT_ADD, payload: {title: t("alert_error"), text: txt, variant: "danger"}} as UIAlertAction)
    }
}

function changeNominations (lichessName: string, action: string, nominations: UINomination[]): UINomination[]{
    function flipRanks(arr: UINomination[], index1:number, index2:number) {
        let tmpEle       = arr[index1].rank
        arr[index1].rank = arr[index2].rank
        arr[index2].rank = tmpEle
        return arr
    }

    let index = nominations.findIndex(nom => nom.lichessName===lichessName)
    switch (action) {
        case ACTION_PROMOTE:
            if (!index || index===0) return nominations
            return flipRanks(nominations, index, index-1)
        case ACTION_DEGRADE:
            if (!index || index===nominations.length-1) return nominations
            return flipRanks(nominations, index, index+1)
        case ACTION_DELETE:
            return nominations.filter(nom => nom.lichessName!==lichessName)
        case ACTION_PAUSE:
            return nominations.map(nom => {
                if (nom.lichessName===lichessName)
                    nom.isPaused=!nom.isPaused
                return nom
            })
        default:
            return nominations
    }
}

type Props = {
    user: User,
    tournament: APITournament,
    updateTeams: Function,
    team: APITeam
}



function checkLoginWithLichess (unavailablePlayers: APIPlayer[], isStrictMode: boolean) {
    if (unavailablePlayers.length === 0)
        return(<></>)
    return (
        <>
            {t("tlLoginWithLichess")} {isStrictMode? t("tlStrictMode"): ""}:<br/>
            {unavailablePlayers.map(p=>p.name).join(", ")}
        </>
    )
}

function printWarnings (players: APIPlayer[], settings:APITournamentSettings, mayEdit: boolean) {
    const availablePlayers = players.filter(p => !isPlayerPaused(p, 1, settings))
    const unavailablePlayers = players.filter(p => !p.isRegistered)

    if (availablePlayers.length < settings.boardCount)
        return(
            <Alert variant="danger">
                <Alert.Heading>
                    {t("tlDangerHeading")}
                </Alert.Heading>
                {mayEdit ? <>{t("tlDangerText")}<br/></> : <></>}
                {checkLoginWithLichess(unavailablePlayers,settings.isStrictMode)}
            </Alert>
        )
    else if (unavailablePlayers.length > 0)
        return(
            <Alert variant="warning">
                <Alert.Heading>
                    {t("tlWarningHeading")}
                </Alert.Heading>
                {checkLoginWithLichess(unavailablePlayers,settings.isStrictMode)}
            </Alert>
        )
    else if (availablePlayers.length >= settings.boardCount)
        return(
            <Alert variant="success">
                <Alert.Heading>
                    {t("tlSuccessHeading")}
                </Alert.Heading>
            </Alert>
        )
}

export function EditTeamTable({user, tournament, updateTeams, team}: Props){
    const nominations: UINomination[] = team.players.map((player, key) => {return {lichessName: player.lichessName??"", rank: key+1, isPaused: player.isPaused??false}})
    
    const handleClick = async (lichessName: string|null, action: string) => {
        if (action === ACTION_DELETE && !window.confirm(t("playerDeleteText")))
            return

        await sendRequestToBackend(user.token, tournament.id, team.id, lichessName, action, changeNominations(lichessName??"", action, nominations))
        updateTeams()
    }
    
    var board = 0
    const mayEdit = isTournamentAdminOrHigher(tournament,user) || tournament.rounds.length === 0
    return(
        <>
        <Table>
            <thead>
                <tr>
                    <th style={{width: "10%"}}>{t("board")}</th>
                    <th style={{width: "30%"}}>{t("lichessName")}</th>
                    <th style={{width: "30%"}}>{t("name")}</th>
                    <th style={{width: "10%"}}>{t("rating")}</th>
                    <th style={{width: "20%"}}>{t("actions")}</th>
                </tr>
            </thead>
            <tbody>
                { team.players.map((player, key) => {
                    let pauseHim = isPlayerPaused(player, board+1, tournament.settings)
                    let thisBoard = pauseHim ? null : ++board
                    return(
                        <tr key={key} className={(player.isRegistered ? "isRegistered " : "") + (pauseHim ? "isPaused" : "")}>
                            <td>{thisBoard??""}</td>
                            <td>{player.lichessName}</td>
                            <td>{player.name}</td>
                            <td>{player.rating}</td>
                            <td>
                            <ButtonGroup>
                                <Button active={player.isPaused??false} size="sm" variant="outline-secondary" onClick={()=>handleClick(player.lichessName, ACTION_PAUSE)}><Pause /></Button>
                                {mayEdit &&
                                <>
                                    <Button size="sm" variant="outline-secondary" onClick={()=>handleClick(player.lichessName, ACTION_PROMOTE)} disabled={key===0}><ArrowUp /></Button>
                                    <Button size="sm" variant="outline-secondary" onClick={()=>handleClick(player.lichessName, ACTION_DEGRADE)} disabled={key===team.players.length-1}><ArrowDown /></Button>
                                    <Button size="sm" variant="outline-secondary" onClick={()=>handleClick(player.lichessName, ACTION_DELETE)}><Trash /></Button>
                                </>
                                }
                            </ButtonGroup>
                            </td>
                        </tr>
                        )
                    }
                )}
                {Array.from({length: tournament.settings.boardCount-board}).map(()=> {
                    let thisBoard = ++board
                    return(
                        <tr key={thisBoard} className={"isRegistered"}>
                            <td>{thisBoard??""}</td>
                            <td></td>
                            <td>[Spielfrei]</td>
                            <td></td>
                            <td></td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
        {printWarnings(team.players, tournament.settings, mayEdit)}
        </>
    )
}