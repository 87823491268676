import { User } from '../../Types';
import {NavBar} from '../NavBar';
import {TournamentSelector} from './TournamentSelector';
import {HomePageInfo} from './HomePageInfo';
import { TITLE_PREFIX } from '../../constants';

type Props = {
    user: User|null
}

export function HomePage({user}: Props) {
    document.title = TITLE_PREFIX + "Turniere"

    return (
        <>
            <NavBar user={user} tournament={null}/>
            <TournamentSelector/>
            <HomePageInfo/>
        </>
    )
}