import {APIGame} from '../Types'

function containNoToken (gameId: string){
    return gameId.indexOf("notoken") >= 0
}

export function isSpecialGame (game: APIGame|null): boolean {
    return game!==null && game.id!==null && game.id.indexOf("_") >= 0
}

function containSpielfrei (gameId: string){
    return gameId.indexOf("spielfrei") >= 0
}

export function isSpielfreiGame (game: APIGame|null): boolean {
    return game!==null && game.id!==null && containSpielfrei(game.id)
}

export function isNoTokenGame (game: APIGame|null): boolean {
    return game!==null && game.id!==null && containNoToken(game.id)
}

export function isLinkId (gameId: string|null) {
    return gameId !== null && !containNoToken(gameId) && !containSpielfrei(gameId)
}

export function resultToString (result: number) {
    switch(result){
        case 2: return "1 - 0"
        case 0: return "0 - 1"
        default: return "½"
    }
}