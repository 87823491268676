import React, { RefObject, useRef } from 'react';
import { Form, Col, Row, Button, InputGroup} from 'react-bootstrap';
import { DOMAIN_ADMINISTRATOR, refreshInterval} from '../../constants';
import { sendRequestToBackendText} from '../../util/ApiRequest'

const DOMAIN_CHANGE_RATES = DOMAIN_ADMINISTRATOR + "/set_rates"


async function ChangeRates         (getUserRef: RefObject<HTMLInputElement>,
                                    getTournamentsRef: RefObject<HTMLInputElement>, 
                                    getTournamentRef: RefObject<HTMLInputElement>,
                                    getMyTeamsRef: RefObject<HTMLInputElement>){

    
    const data = {
        get_user: parseInt(getUserRef.current?.value??''+refreshInterval.get_user),
        get_tournaments: parseInt(getTournamentsRef.current?.value??''+refreshInterval.get_tournaments),
        get_tournament: parseInt(getTournamentRef.current?.value??''+refreshInterval.get_tournament),
        get_my_teams: parseInt(getMyTeamsRef.current?.value??''+refreshInterval.get_myTeams),
    }

    if (Math.min(data.get_my_teams,data.get_tournament,data.get_tournaments,data.get_user) < 1000)
        if (!window.confirm("Die Refresh Rate unter 1s zu senken gefährdet möglicherweise die Stabilität des Backends. Trotzdem senden?"))
            return

    sendRequestToBackendText(DOMAIN_CHANGE_RATES, data)
}

export function SetRefreshRates(){
    const getUserRef = useRef<HTMLInputElement>(null)
    const getTournamentsRef = useRef<HTMLInputElement>(null)
    const getTournamentRef = useRef<HTMLInputElement>(null)
    const getMyTeamsRef = useRef<HTMLInputElement>(null)

    const handleClick = () => ChangeRates(getUserRef, getTournamentsRef, getTournamentRef, getMyTeamsRef);

    const forms = [
        {label: "get_user", ref: getUserRef, placeholder: refreshInterval.get_user},
        {label: "get_tournaments", ref: getTournamentsRef, placeholder: refreshInterval.get_tournaments},
        {label: "get_tournament", ref: getTournamentRef, placeholder: refreshInterval.get_tournament},
        {label: "get_myTeams", ref: getMyTeamsRef, placeholder: refreshInterval.get_myTeams},
    ]
    return(
        <Form>
            <h2>
                Refresh raten ändern
            </h2>
            {forms.map((form,key)=>
                <Form.Group as={Row} key={key}>
                    <Form.Label column md="3" xs="4">
                        {form.label}
                    </Form.Label>
                    <Col md="4" xs="4">
                        <InputGroup className="mb-2">
                        <Form.Control ref={form.ref} defaultValue={form.placeholder.toString()} />
                            <InputGroup.Append>
                                <InputGroup.Text>ms</InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>
                </Form.Group>
            )}
            <Row>
                <Col>
                    <Button type="button" className="mb-2" onClick={() => {handleClick()}}>
                        Speichern
                    </Button>
                </Col>
            </Row>
        </Form>
    )
}