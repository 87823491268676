import { CLOCK_LIMIT, CLOCK_INCREMENT, LICHESS_API_PLAYER } from '../constants'
import { APIMatch, APIPairing, APIRound } from '../Types'

type ClockRules = {
    from: number,
    to: number,
    step: number
}

// Input Validation
export function validateClock (limit: number, increment: number) {
    const checkForRule = (input: number, rules: ClockRules[]) => {
        return rules.some(rule => input<=rule.to && input >= rule.from && (input-rule.from)%rule.step===0)
    }

    const isInClockLimit = checkForRule(limit, CLOCK_LIMIT)
    const isInClockIncrement = checkForRule(increment, CLOCK_INCREMENT)

    return (isInClockLimit && isInClockIncrement)
}

export function isRating (input: string|number) {
    return /^[0-9]{3,4}$/.test(input.toString())
}
export function isDate (input: string) {
    return /^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$/.test(input.toString())
}

export async function isLichessUserName (input: string) {
    const response = await fetch(LICHESS_API_PLAYER + input, { method: 'GET', headers: { } })
    const jsonResponse: string = await response.json()
    return jsonResponse.length !== 0
}

// Validation of Pairings
function isPairingEqual (match: APIMatch, pairing: APIPairing) {
    return ((match.firstTeamId === pairing.first && match.secondTeamId === pairing.second) ||
            (match.firstTeamId === pairing.second && match.secondTeamId === pairing.first))  
}

function isPairingPlayed (rounds: APIRound[], pairing: APIPairing) {
    return rounds.some(round =>
        round.matches.some(match =>
            isPairingEqual(match, pairing)
        )
    )
}

/*
export function isUniquePairing (rounds: APIRound[], pairings: (number|null)[][]) {
    return !pairings.some(pairing => isPairingPlayed(rounds, pairing))
}*/

export function getRepeatedPairing(rounds: APIRound[], pairings: APIPairing[]) : APIPairing[] {
    return pairings.filter(pair=> isPairingPlayed(rounds, pair))
}

function getTeamIds (pairings: APIPairing[]) {
    var teamIds = [] as (number|null)[]
    pairings.forEach(pair => {
        teamIds.push(pair.first)
        teamIds.push(pair.second)
    })
    return teamIds
}

export function getDoublePairedTeamIds(pairings: APIPairing[]): (number|null)[] {
    const ids = getTeamIds(pairings)
    return ids.filter((id, key) => id !== null && key !== ids.indexOf(id))
}