import React from 'react';
import { Col, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import {getFlag, getFlags, t, translation_settings} from '../language/translations'

export function Footer({setLang} : {setLang: Function}) {
    return (
        <footer className="d-flex mt-auto py-3 row row-cols-1 row-cols-sm-2 row-cols-md-5 mx-0">
            <Col className="text-center">
                <Dropdown drop="up">
                    <Dropdown.Toggle id="dropdown-custom-components" as="a" style={{cursor: "pointer"}}>
                        {getFlag(translation_settings.language).flag30}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        { getFlags().map((flag, key) => 
                            <Dropdown.Item key={key} eventKey={flag.abbreviation} onClick={()=>setLang(flag.abbreviation)}>{flag.flag50}</Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            </Col>
            <Col className="text-center">
                <a href="/FAQ">{t("faqLabel")}</a>
            </Col>
            <Col className="text-center">
                <Link to={"/about"}>{t("about")}</Link>
            </Col>
            <Col className="text-center">
                <Link to={"/feedback"}>{t("feedback")}</Link>
            </Col>
            <Col className="text-center">
                <small>
                <a href="https://www.schachklub-kelheim.de/impressum/" target="_blank" rel="noreferrer">{t("imprint")}</a> | <Link to={"/privacy-policy"}>{t("privacyPolicy")}</Link>
                </small>
            </Col>
        </footer>
    )
  }