import {LOCAL_STORAGE_LOGIN_REDIRECT} from '../constants'

export function onClickLoginWithLichess() {
    try {
        localStorage.setItem(LOCAL_STORAGE_LOGIN_REDIRECT, window.location.href)
    }
    catch (e) {
        console.log(e)
    }
}

export function onPageLoad() {
    const storedSubpage = localStorage.getItem(LOCAL_STORAGE_LOGIN_REDIRECT)
    if (storedSubpage && storedSubpage.length > 0) {
        localStorage.removeItem(LOCAL_STORAGE_LOGIN_REDIRECT)
        window.location.href = storedSubpage
    }
}
