import React from 'react';
import {Card, Table } from 'react-bootstrap';
import { LICHESS_URL } from '../../constants';
import { t } from '../../language/translations';

import {APIGame, APIRound, APITournament} from '../../Types';
import { isNoTokenGame, isSpielfreiGame, resultToString } from '../../util/Game';
import { getTeamsNotComplete } from '../../util/Team';



function RegistrationNumbers({tournament}: {tournament: APITournament}) {
    return (
        <Table className="col-6" size="sm">
            <tbody>
                <tr>
                    <td>{t("teams")} (gesamt)</td>
                    <td>{tournament.teams.length}</td>
                </tr>
                <tr>
                    <td>{t("teams")} (vollzählig)</td>
                    <td>{tournament.teams.filter(t=>t.players.length>=tournament.settings.boardCount).length}</td>
                </tr>
                <tr>
                    <td>{t("teams")} (vollzählig und registriert)</td>
                    <td>{tournament.teams.filter(t=>t.players.filter(p=>p.isRegistered).length>=tournament.settings.boardCount).length}</td>
                </tr>
                <tr>
                    <td>{t("players")} (gesamt)</td>
                    <td>{tournament.teams.reduce((sum,t)=>sum+t.players.length,0)}</td>
                </tr>
                <tr>
                    <td>{t("players")} (registriert)</td>
                    <td>{tournament.teams.reduce((sum,t)=>sum+t.players.filter(p=>p.isRegistered).length,0)}</td>
                </tr>
            </tbody>
        </Table>
    )
}

function StatusCurrentRound ({round}: {round: APIRound|null}) {
    const games: APIGame[] = (round?.matches.map(m=>m.games) ?? []).flat()

    if (!round)
        return(<>{t("noRoundShort")}</>)
    return (
        <>
        <Table className="col-6" size="sm">
            <tbody>
                <tr>
                    <td>Laufende Partien</td>
                    <td>{games.filter(g=>g.result===null).length}</td>
                </tr>
                <tr>
                    <td>Beendete Partien</td>
                    <td>{games.filter(g=>g.result!==null).length}</td>
                </tr>
                <tr>
                    <td>Spielfreie Partien</td>
                    <td>{games.filter(g=>isSpielfreiGame(g)).length}</td>
                </tr>
                <tr>
                    <td>NoToken Partien</td>
                    <td>{games.filter(g=>isNoTokenGame(g)).length}</td>
                </tr>
            </tbody>
        </Table>
        <strong>Partien mit nur einem Token:</strong>
        <ul>
            {games.filter(game=>!game.player1?.isRegistered || !game.player1?.isRegistered).map((game,key) =>
                <li key={key}>
                    <a href={LICHESS_URL+game.id}>
                        {game.id} {game.result!==null ? "("+resultToString(game.result)+")" : ""}
                    </a>
                </li>
            )}
        </ul>
        </>
    )
}

function StatusNextRound ({tournament}: {tournament: APITournament}) {
    const teamsNotComplete = getTeamsNotComplete(tournament.teams, tournament.settings.boardCount, tournament.settings.isStrictMode)
    return (
        <Table size="sm">
            <tbody>
                <tr>
                    <td>Mannschaften nicht vollständig registiert</td>
                    <td>{teamsNotComplete.map(t=>t.name).join(", ")}</td>
                </tr>
            </tbody>
        </Table>
    )
}

type Props = {
    tournament: APITournament,
    updateTournament: any
}

export function TournamentOverview({tournament}: Props){
   
    return(
        <>
        <h2 className="p-3">Turnierübersicht</h2>
            <Card className="mb-3">
                <Card.Header>Anmeldungen (Zahlen, vor Turnierstart)</Card.Header>
                <Card.Body><RegistrationNumbers tournament={tournament} /></Card.Body>
            </Card>
            <Card className="mb-3">
                <Card.Header>Rundenübersicht (ohne Token, nur 1 Token, Summe noch laufende Partien)</Card.Header>
                <Card.Body><StatusCurrentRound round={tournament.rounds.length>0 ? tournament.rounds[tournament.rounds.length-1]: null}/></Card.Body>
            </Card>
            <Card className="mb-3">
                <Card.Header>Nächste Runde</Card.Header>
                <Card.Body><StatusNextRound tournament={tournament}/></Card.Body>
            </Card>
            { false &&
            <>
            <Card className="mb-3">
                <Card.Header>Server-Status</Card.Header>
                <Card.Body>TBD was hier genau rausgeschickt werden soll</Card.Body>
            </Card>
            <Card className="mb-3">
                <Card.Header>Log</Card.Header>
                <Card.Body>TBD was hier genau rausgeschickt werden soll</Card.Body>
            </Card>
            </>
            }
        </>
    )
}