import React, {useCallback, useState} from 'react';
import {Container, Table, NavLink, Modal, Button, Form, Col, Row, Tabs, Tab} from 'react-bootstrap'

import {APITournament} from '../../Types'
import {refreshInterval, RIGHTS_NAME} from '../../constants'
import {FetchTournaments, LoadTournaments, StoreTournaments} from '../../util/api/FetchTournaments'
import { Link } from 'react-router-dom';
import { t } from '../../language/translations';
import useTimeout from '../../util/CustomHook/useTimeout';
import { InfoCircle } from 'react-bootstrap-icons';
import { isCurrent, sortByDateDESC, sortForSelector } from '../../util/Tournament';

const DISPLAY_N_TOURNAMENTS = 5
const DISPLAY_ADD_N_TOURNAMENTS = 15

function TournamentInfoModal({tournament, onHide}:{tournament:APITournament|null, onHide: Function}) {
    
    if (!tournament)
        return <></>
    return (
        <Modal
            show={true}
            onHide={onHide}
            size="lg"
            aria-labelledby="modal-tournament-info"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="modal-tournament-info">
                    {tournament.name}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table>
                    <tbody>
                        <tr>
                            <td>{t("startDate")}</td>
                            <td>{tournament.settings.startDate}</td>
                        </tr>
                        <tr>
                            <td>{t("boardCount")}</td>
                            <td>{tournament.settings.boardCount}</td>
                        </tr>
                        <tr>
                            <td>{t("clockBase")}</td>
                            <td>{tournament.settings.base/60} min</td>
                        </tr>
                        <tr>
                            <td>{t("clockIncrement")}</td>
                            <td>{tournament.settings.increment} s</td>
                        </tr>
                        <tr>
                            <td>{t("strictMode")}</td>
                            <td>{tournament.settings.isStrictMode ? t("yes") : t("no")}</td>
                        </tr>
                        <tr>
                            <td>{t("ratedGames")}</td>
                            <td>{tournament.settings.isRated ? t("yes") : t("no")}</td>
                        </tr>
                        {tournament.settings.fen &&
                            <tr>
                                <td>{t("startPosition")}</td>
                                <td>{tournament.settings.fen}</td>
                            </tr>
                        }
                        <tr>
                            <td>{t("tournamentAdmin")}</td>
                            <td>{tournament.admins.join(", ")}</td>
                        </tr>
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={()=>onHide()}>{t("close")}</Button>
            </Modal.Footer>
        </Modal>
    )
}

function TournamentTable ({tournaments, searchString}: {tournaments: APITournament[], searchString: string}) {
    const [tournamentsCount, setTournamentsCount] = React.useState(DISPLAY_N_TOURNAMENTS as number)
    const [tournamentForInfo, setTournamentForInfo] = React.useState(null as APITournament|null)
    
    const handleClickMore = () => setTournamentsCount(Math.min(tournamentsCount+DISPLAY_ADD_N_TOURNAMENTS,tournaments.length))

    const tournamentsToShow = tournaments.filter(t=>
        [t.name,t.id,t.settings.tags].some(s=>s.toLowerCase().includes(searchString.toLowerCase()))
    ).filter(t=>!t.settings.tags || !t.settings.tags.toLowerCase().includes("hidden"))

    return (
        <>
        <TournamentInfoModal tournament={tournamentForInfo} onHide={()=>setTournamentForInfo(null)}/>

        <Table className="mb-0 table-bordered">
            <thead>
                <tr>
                    <th className="">{t("startDate")}</th>
                    <th>{t("tournamentName")}</th>
                    <th className="text-center">Info</th>
                    <th className="d-none d-md-table-cell">{t("myRole")}</th>
                </tr>
            </thead>
            <tbody>
                {tournamentsToShow.filter((_,n) => n<tournamentsCount).map((tournament, key) => 
                    <tr key={key}>
                        <td className="align-middle" style={{width: "8rem"}}>{tournament.settings.startDate}</td>
                        <td className="align-middle">
                            <Link to={"/"+tournament.id}>{tournament.name}</Link>
                        </td>
                        <td className="text-center" style={{width: "4rem"}}>
                            <Button size="sm" onClick={()=>setTournamentForInfo(tournament)} className="" variant="outline-info"><InfoCircle/></Button>
                        </td>
                        <td className="d-none d-md-table-cell align-middle"  style={{width: "12rem"}}>
                            { RIGHTS_NAME.filter(n => n.level <= (tournament.rights??0)).pop()?.name ?? "" }
                        </td>
                    </tr>
                )}
                {tournamentsToShow.length === 0 &&
                    <tr>
                        <td colSpan={4}>
                            Keine Turniere gefunden &#128532;
                        </td>
                    </tr>
                }
            </tbody>
        </Table>
        {tournamentsToShow.length > tournamentsCount &&
            <NavLink onClick={handleClickMore} className="clickable">{t("moreTournaments")}</NavLink>
        }
        </>
    )
}

export function TournamentSelector() {
    const [tournaments, setTournaments] = useState(LoadTournaments() as APITournament[])
    const [searchString, setSearchString] = React.useState("")
    const serachRef = React.useRef(null)

    const updateTournaments = useCallback(async function () {
        var res: APITournament[] = await FetchTournaments()
        res.sort(sortForSelector)
        StoreTournaments(res)
        setTournaments(res)
    }, [])

    const handleSearchChange = (event: React.KeyboardEvent<HTMLInputElement>) => {
        setSearchString(event.currentTarget.value)
    }
    
    useTimeout(updateTournaments, refreshInterval.get_tournaments)

    const myTournaments = tournaments.filter(t=>(t.rights??0)>0)
    const currentTournaments = tournaments.filter(t=>isCurrent(t)).sort(sortByDateDESC)
    const pastTournaments = tournaments.filter(t=>!isCurrent(t))

    const defaultTabOpen = (myTournaments.length > 0 ? "my" : "current")
    const [tabOpen, setTabOpen] = React.useState(defaultTabOpen);

    return (
        <Container className="border rounded mt-1 px-0 pb-0 mainFrame">
            <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3">
                <Col className="d-none d-md-block"></Col>
                <Col><h3 className="text-center">{t("tournaments")}</h3></Col>
                <Col><Form.Control ref={serachRef} placeholder={t("search")} onKeyUp={handleSearchChange}/></Col>
            </Row>

            <Tabs
            id="controlled-tab-example"
            activeKey={tabOpen}
            onSelect={(k) => setTabOpen(k??defaultTabOpen)}
            >
            {myTournaments.length>0 &&
                <Tab eventKey="my" title={t("my")}>
                    <TournamentTable tournaments={myTournaments} searchString={searchString}/>
                </Tab>
            }
                <Tab eventKey="current" title={t("current")}>
                    <TournamentTable tournaments={currentTournaments} searchString={searchString}/>
                </Tab>
                <Tab eventKey="past" title={t("past")}>
                    <TournamentTable tournaments={pastTournaments} searchString={searchString}/>
                </Tab>
                <Tab eventKey="all" title={t("all")}>
                    <TournamentTable tournaments={tournaments} searchString={searchString}/>
                </Tab>
            </Tabs>
        </Container>
    );
}