import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { t } from '../../language/translations';

import {User} from '../../Types'

export function LinkModal({user, show, onHide}:{user: User, show: boolean, onHide: any}) {
    if (!user.nextLink)
        return null

    return (
        <Modal
            show={show}
            size="lg"
            aria-labelledby="newLinkModal"
            animation={false}
            onHide={onHide}
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="newLinkModal">
                    {t("nextGameHeader")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    {t("nextGameText")}
                </p>
                    <a href={user.nextLink} target="_blank" rel="noreferrer">{user.nextLink}</a>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide}>OK</Button>
            </Modal.Footer>
        </Modal>
    );
}