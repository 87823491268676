import React, {useState} from 'react'
import {Table, ButtonGroup, Button} from 'react-bootstrap'
import { PencilFill, Trash } from 'react-bootstrap-icons'
import { APITournament } from '../../Types'
import {sendDeleteTournament} from '../../util/ApiRequest'
import {FetchTournaments} from '../../util/api/FetchTournaments'
import {refreshInterval} from '../../constants'
import { Link } from 'react-router-dom'
import { t } from '../../language/translations'
import {EditTournamentModal} from './EditTournamentModal'
import { sortByNameAndDate } from '../../util/Tournament'
import {TournamentClone} from './TournamentClone'

export function TournamentOptions () {
    const [tournaments, setTournaments] = useState([] as APITournament[])
    const [editTournament, setEditTournament] = React.useState(null as APITournament|null)
    
    const updateTournaments = React.useCallback(async () => {
        const newTournaments: APITournament[] = await FetchTournaments()
        newTournaments.sort(sortByNameAndDate)
        setTournaments(newTournaments)
    }, [])

    React.useEffect(() => {
        updateTournaments()

        const interval = setInterval(updateTournaments, refreshInterval.get_tournaments_admin)
        return()=>clearInterval(interval)
    },[updateTournaments]);


    return (
        <>
        {editTournament !== null && 
            <EditTournamentModal tournament={editTournament} onHide={()=>setEditTournament(null)} updateTournaments={updateTournaments}/> 
        }
        <Table>
            <thead>
                <tr>
                    <th>#</th>
                    <th>{t("tournamentName")}</th>
                    <th>{t("tournamentAdmin")}</th>
                    <th>{t("actions")}</th>
                </tr>
            </thead>
            <tbody>
                { tournaments.map((tournament, key) => 
                    <tr key={key}>
                        <td>{key+1}</td>
                        <td><Link to={tournament.id}>{tournament.name}</Link></td>
                        <td>{tournament.admins.join(",")}</td>
                        <td>
                        <ButtonGroup>
                            <TournamentClone origin={tournament}/>
                            <Button size="sm" variant="outline-secondary" onClick={()=>setEditTournament(tournament)}><PencilFill /></Button>
                            <Button size="sm" variant="outline-secondary" onClick={()=>sendDeleteTournament(tournament.id)}><Trash /></Button>
                        </ButtonGroup>
                        </td>
                    </tr> 
                )}
            </tbody>
        </Table>
        </>
    )
}