import React from 'react';
import { Accordion, Card, Col, Container, Row, Table } from 'react-bootstrap';
import { ArrowsCollapse, ArrowsExpand, ChevronDoubleDown, Dice5, SortAlphaDown, SortNumericUpAlt } from 'react-bootstrap-icons';
import { t } from '../../language/translations';
import { APITeam, APITournament } from '../../Types';
import { isComplete, printRatingAverage, sortFunctionForTeams } from '../../util/Team';
import {ButtonGroupSelector} from './ExpansionSelector'

function TeamTable ({team}: {team: APITeam}){
    return(
        <Table>
            <thead>
                <tr>
                    <th className="text-center" style={{width: "6rem"}}>{t("board")}</th>
                    <th>{t("name")}</th>
                    <th className="text-right" style={{width: "6rem"}}>{t("rating")}</th>
                    <th className="" style={{width: "5rem"}}> </th>
                </tr>
            </thead>
            <tbody>
                { team.players.map((player, key) => 
                        <tr key={key} className={"" + (player.isRegistered ? " isRegistered" : "")}>
                            <td className="pl-3 text-center">{key+1}</td>
                            <td className="">{player.name}</td>
                            <td className=" text-right">{(player.rating??-1)>0 ? player.rating : ""}</td>
                            <td className=""> </td>
                        </tr> 
                )}
                { team.players.length === 0 &&
                   <tr>
                       <td></td>
                       <td>
                            {t("noTeamMemberYet")}
                       </td>
                    </tr>
                }
            </tbody>
        </Table>
    )
}

function ParticipantCard ({team, show, onShow, boardCount, isStrictMode, count} : {team: APITeam, show: boolean, onShow: Function, boardCount: number, isStrictMode: boolean, count: number}) {
    return (
        <Card>
            <Card.Header className={"d-flex flex-row px-2 " + (isComplete(team, boardCount, isStrictMode) ? "isRegistered" : "")} onClick={()=>onShow()}>
                <Col className="text-center d-none d-md-block" style={{maxWidth: "4rem"}}>
                {count}
                </Col>
                <Col className={"px-0 "} style={{flexGrow: 2}}>
                {team.name}
                </Col>
                <Col>
                    <Row>
                        <Col className="text-right">
                            {printRatingAverage(team, boardCount)}
                        </Col>
                        <Col className="text-right" style={{maxWidth: "5rem"}}>
                            <ChevronDoubleDown/>
                        </Col>
                    </Row>
                </Col>
            </Card.Header>
            <Card.Body className={(show?"": "d-none") + " p-0"}>
                <TeamTable team={team}/>
            </Card.Body>
        </Card>
    )
}

const sortButtons = [
    {action: "rating", tooltip: "tt_sortByRating", label: <SortNumericUpAlt size={20}/>},
    {action: "alphabetical", tooltip: "tt_sortByAlphabet", label: <SortAlphaDown size={20}/>},
    {action: "random", tooltip: "tt_sortByRandom", label: <Dice5 size={20}/>}
] as {action: string, tooltip: string, label: JSX.Element}[]

const expansionSelectorButtons = [
    {action: "none", tooltip: "tt_hideAllTeammembers", label: <ArrowsCollapse size={20}/>},
    {action: "all", tooltip: "tt_showAllTeammembers", label: <ArrowsExpand size={20}/>}
] as {action: string, tooltip: string, label: JSX.Element}[]

export function TeamParticipantsList({tournament}: {tournament: APITournament}){
    const [teamIdsToShow, SetTeamIdsToShow] = React.useState([] as number[])
    const [sortMode, setSortMode] = React.useState("rating" as string)

    const handleShow = (mode: string) => {
        const ids = mode === "all" ? tournament.teams.map(t=>t.id) : []
        SetTeamIdsToShow(ids)
    }
    const handleToggleTeam = (id: number|null) => {
        if (id === null)
            return
        if (teamIdsToShow.some(exp_id=>exp_id===id))
            SetTeamIdsToShow(teamIdsToShow.filter(exp_id=>exp_id!==id))
        else
            SetTeamIdsToShow([...teamIdsToShow, id])
    }

    const teamsToDisplay = tournament.teams.slice(0).sort(sortFunctionForTeams(sortMode,tournament.settings.boardCount))
    return(
        <Container fluid="md" className="px-0">
            <Row className="p-3 m-0 d-md-flex justify-content-between">
                <h2 className="px-3 px-md-0">
                    {t("participantsList")}
                </h2>
                <ButtonGroupSelector buttons={sortButtons} handleClick={setSortMode} />
                <div className="d-none d-md-flex">
                <ButtonGroupSelector buttons={expansionSelectorButtons} handleClick={handleShow}/>
                </div>
            </Row>
            
            <Accordion defaultActiveKey="0">
                { teamsToDisplay.map( (team,key) =>
                    <ParticipantCard key={key} team={team} show={teamIdsToShow.some(id=>id===team.id) && tournament.settings.type !== 5} onShow={()=>handleToggleTeam(team.id)} boardCount={tournament.settings.boardCount} isStrictMode={tournament.settings.isStrictMode} count={key+1}/>
                )}
                { tournament.teams.length === 0 &&
                   <strong>{t("noTeamsYet")}</strong>
                }
            </Accordion>
        </Container>
    )
}