import React from 'react'
import { Button, Modal, Table } from 'react-bootstrap'
import { ALERT_ADD, DOMAIN_TOURNAMENT_ADMIN } from '../../constants'
import { t } from '../../language/translations'
import {APITournament, APIPairing, UIAlertAction} from '../../Types'
import { alertDispatch } from '../../util/Alerts'
import { getRequestHeaderFromStorage } from '../../util/ApiRequest'
import { getDoublePairedTeamIds, getRepeatedPairing } from '../../util/Validation'

import {getPairings} from './SetPairings'

const TOURNAMENT_ID = "tournamentId"
const ALIAS = "/add_pairings"

async function storePairings (tournament: APITournament, pairings: APIPairing[]){
    if (tournament.rounds.length===0)
        if (!window.confirm("Das Turnier wird jetzt gestartet. In jedem Fall solltest Du davor nochmal die Turniereinstellungen überprüfen. Bist Du sicher, dass die erste Runde gepaart werden soll?"))
            return

    const requestHeader = getRequestHeaderFromStorage ()

    var target = DOMAIN_TOURNAMENT_ADMIN + ALIAS + "?pairings=" + 
                JSON.stringify(pairings.map(pair=> [pair.first, pair.second])) 
                + "&" + TOURNAMENT_ID + "=" + tournament.id

    const fetchRes = await fetch(target, {
        method: 'GET',
        headers: requestHeader,
    })
    if(fetchRes.ok){
        const txt = await fetchRes.text()
        alertDispatch(  {type: ALERT_ADD, payload: {title: "Paarungen wurden gespeichert",
                        text: txt, variant: "success"}} as UIAlertAction)
    }
    else{
        const txt = await fetchRes.text()
        alertDispatch(  {type: ALERT_ADD, payload: {title: "Paarungen wurden nicht gespeichert",
            	        text: "Der Befehl wurde an '" + target + "' gesendet, lieferte aber den Status " + fetchRes.status + " zurück. Fehlermeldung: " + txt, variant: "danger"}} as UIAlertAction)
    }
}

type Props = {
    tournament: APITournament,
    rerenderParent: Function
}

export function SetPairingsConfirm ({tournament, rerenderParent}: Props) {
    const [show, setShow] = React.useState(false)
    var pairings = React.useRef([] as APIPairing[])

    const handleOpenModal = () => {
        pairings.current = getPairings(Math.ceil(tournament.teams.length/2))
        setShow(true)
    }
    const handleSubmit = async() => {
        await storePairings(tournament, pairings.current)
        setShow(false)
        rerenderParent()
    }

    const repeatedPairings  = getRepeatedPairing(tournament.rounds, pairings.current)
    const doublePaired      = getDoublePairedTeamIds(pairings.current)

    return (
        <>
        <Button type="button" className="mb-2" onClick={handleOpenModal}>
            {t("savePairings")}
        </Button>
        <Modal
            show={show}
            onHide={()=>setShow(false)}
            size="lg"
            aria-labelledby="modal-tournament-info"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="modal-tournament-info">
                    Soll die Runde gespeichert werden?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table>
                    <tbody>
                        <tr className={pairings.current.length===0 ? "table-danger" : "table-success"}>
                            <td>Zahl der Paarungen</td>
                            <td>
                                {pairings.current.length}
                            </td>
                        </tr>
                        <tr className={repeatedPairings.length===0 ? "table-success" : "table-warning"}>
                            <td>Wiederholte Paarungen:</td>
                            <td>
                                {repeatedPairings.map(pair => 
                                    <>
                                    {
                                        (tournament.teams.find(t=> t.id === pair.first)?.name ?? "Spielfrei")
                                        + " - " + 
                                        (tournament.teams.find(t=> t.id === pair.second)?.name ?? "Spielfrei")
                                    }
                                    <br/>
                                    </>
                                )}
                                {repeatedPairings.length===0 && "Keine, alles gut!"}
                            </td>
                        </tr>
                        <tr className={doublePaired.length===0 ? "table-success" : "table-danger"}>
                            <td>Doppelt gepaarte Teams:</td>
                            <td>
                                {doublePaired.map(id => 
                                    <>
                                    {
                                        tournament.teams.find(t=>t.id===id)?.name ?? "Spielfrei"
                                    }
                                    <br/>
                                    </>
                                )}
                                {doublePaired.length===0 && "Keine, alles gut!"}
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={()=>handleSubmit()} variant="warning">Los!</Button>
                <Button onClick={()=>setShow(false)} variant="outline-success">{t("abort")}</Button>
            </Modal.Footer>
        </Modal>
        </>
    )
}