import {APIGame, APIRound, APITeam, APITournament} from '../Types'
import {getAllGamesFromRound, getAllGamesFromRoundForTeam, getLiveGamesCount} from './Round'

export function getAllGames(tournament: APITournament):APIGame[] {
    return tournament.rounds.reduce((acc,r)=>acc.concat(getAllGamesFromRound(r)),[] as APIGame[])
}

export function findGame(tournament: APITournament, gameId: string): APIGame|null {
    return (getAllGames(tournament).find(g=>g.id===gameId))??null
}

function costOfDateForSort(date: string|null) {
    if (!date)
        return 0

    //format: "DD.MM.YYYY"
    const dateStr = date.split(".")

    if (dateStr.length < 3)
        return 0

    return parseInt(dateStr[2]) * 10000 + parseInt(dateStr[1]) * 100 + parseInt(dateStr[0])
}

export function sortByDate(a: APITournament, b: APITournament) {
    return costOfDateForSort(b.settings.startDate)-costOfDateForSort(a.settings.startDate)
}

export function sortByName(a: APITournament, b: APITournament) {
    return a.name.localeCompare(b.name)
}

export function sortByNameAndDate(a: APITournament, b: APITournament) {
    const dateDiff = sortByDate(a,b)
    if (dateDiff === 0)
        return sortByName(a,b)
    else
        return dateDiff
}

export function sortForSelector(a: APITournament, b: APITournament) {
    return sortByNameAndDate(a,b)
}

export function sortByDateDESC(a: APITournament, b: APITournament) {
    const dateA = getDDMMYYYYAsDate(a.settings.startDate)
    const dateB = getDDMMYYYYAsDate(b.settings.startDate)

    return dateA.getTime() - dateB.getTime()
}

export function getAllGamesFromTeam(tournament: APITournament, teamId: number) {
    return tournament.rounds.reduce((acc,r)=>acc.concat(getAllGamesFromRoundForTeam(r,teamId)),[] as APIGame[])
}

export function getAllGamesFromTeams(tournament: APITournament, teams: APITeam[]) {
    return teams.map(t=>getAllGamesFromTeam(tournament, t.id))
}

function getDDMMYYYYAsDate(date: string|null): Date {
    if (!date)
        return new Date(0)
    
    const split = date.split(".")

    if (split.length !== 3)
        return new Date(0)
    return new Date(parseInt(split[2]),parseInt(split[1])-1,parseInt(split[0]))
}

function getTodayMorning(): Date {
    const now = new Date()
    return new Date(now.getFullYear(), now.getMonth(), now.getDate())
}

export function isCurrent(tournament: APITournament): boolean {
    if (!tournament.settings.startDate)
        return false

    const tournamentStart = getDDMMYYYYAsDate(tournament.settings.startDate)
    const today = getTodayMorning()

    if (tournamentStart >= today)
        return true
    else
        return false
}

function getLastRound(tournament: APITournament): APIRound|undefined {
    if (tournament.rounds.length === 0)
        return undefined
    return tournament.rounds[tournament.rounds.length-1]
}

export function shouldSetPairings(tournament: APITournament): boolean {
    const round = getLastRound(tournament)
    if (round === undefined)
        return true

    const games = getAllGamesFromRound(round)

    return games.length>0 && !games.some(game=>game.result===null)
}

export function shouldNextRound(tournament: APITournament): boolean {
    const round = getLastRound(tournament)
    if (round === undefined)
        return false
    
    return !round.matches.some(match=>match.games.length!==0)
}

export function shouldStartRound(tournament: APITournament): boolean {
    const round = getLastRound(tournament)
    if (round === undefined)
        return false

    const games = getAllGamesFromRound(round)
    return games.some(game=>game.id===null)
}

export function shouldStartClock(tournament: APITournament): number|boolean {
    const round = getLastRound(tournament)
    if (round === undefined || shouldStartRound(tournament))
        return false
    
    return getLiveGamesCount(round)
}

export function isBuchholz(tournament: APITournament): boolean {
    return tournament.settings.tags.toLocaleLowerCase().includes("buchholz")
}