import {ALERT_ADD, DOMAIN_TOURNAMENT_ADMIN} from '../../constants'
import { UIAlertAction } from '../../Types';
import { alertDispatch } from '../Alerts';
import {getRequestHeaderFromStorage} from '../ApiRequest'

const DOMAIN_SET_RESULT = DOMAIN_TOURNAMENT_ADMIN + "/set_game_result"
const DOMAIN_DELETE_GAME = DOMAIN_TOURNAMENT_ADMIN + "/delete_game"

export async function SetResult(tournamentId: string, gameId: string, action: string){
    const requestHeader = getRequestHeaderFromStorage()

    var data = "game_id=" + gameId + "&action=" + action + "&tournamentId=" + tournamentId; 

    const target = DOMAIN_SET_RESULT + "?" + data;

    const fetchRes = await fetch(target, {
        method: 'GET',
        headers: requestHeader
    })

    if(fetchRes.ok){
        const txt = await fetchRes.text()
        
        alertDispatch({type: ALERT_ADD, payload: {title: "Ergebnis wurde gesetzt.", text: txt, variant: "success"}} as UIAlertAction)
    }
    else{
        const txt = await fetchRes.text()
        alertDispatch({type: ALERT_ADD, payload: {title: "Ergebnis konnte nicht gesetzt werden", text: txt, variant: "danger"}} as UIAlertAction)
    }
}

export async function DeleteGame(tournamentId: string, gameId: string, action: string){
    const requestHeader = getRequestHeaderFromStorage()

    var data = "game_id=" + gameId + "&action=" + action + "&tournamentId=" + tournamentId; 

    const target = DOMAIN_DELETE_GAME + "?" + data;

    const fetchRes = await fetch(target, {
        method: 'GET',
        headers: requestHeader
    })

    if(fetchRes.ok){
        const txt = await fetchRes.text()
        
        alertDispatch({type: ALERT_ADD, payload: {title: "Partie wurde gelöscht.", text: txt, variant: "success"}} as UIAlertAction)
    }
    else{
        const txt = await fetchRes.text()
        alertDispatch({type: ALERT_ADD, payload: {title: "Partie konnte nicht gelöscht werden.", text: txt, variant: "danger"}} as UIAlertAction)
    }
}