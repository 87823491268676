import { DOMAIN_TEAMLEAD, LOCAL_STORAGE_MY_TEAMS } from "../../constants";
import { APITeam } from "../../Types";
import { getRequestHeaderFromStorage } from "../ApiRequest";

const DOMAIN_GET_MY_TEAM = DOMAIN_TEAMLEAD + "/get_my_teams"

export async function GetMyTeams (tournamentId: string): Promise<APITeam[]> {
    const requestHeader = getRequestHeaderFromStorage ()

    const fetchRes = await fetch(DOMAIN_GET_MY_TEAM + "?tournamentId=" + tournamentId, {
        method: 'GET',
        headers: requestHeader
    });
    const res: APITeam[] = await fetchRes.json();
    return res
}

export function StoreMyTeams (teams: APITeam[]) {
    var tempTeams = teams.slice()
    const storedTeams = LoadMyTeams()  
    storedTeams.filter(st=> !tempTeams.some(tt=>tt.id===st.id))
               .forEach(team=>tempTeams.push(team))

    try {
        localStorage.setItem(LOCAL_STORAGE_MY_TEAMS, JSON.stringify(tempTeams))
    }
    catch (e) {
        console.log(e)
    }
}

export function LoadMyTeams (): APITeam[] {
    const storedTeams = localStorage.getItem(LOCAL_STORAGE_MY_TEAMS)
    if(storedTeams)
        return JSON.parse(storedTeams)
    else
        return []
}