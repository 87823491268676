import { RefObject } from 'react';
import {DOMAIN_TOURNAMENT_ADMIN, DOMAIN_ADMINISTRATOR, LOCAL_STORAGE_TOKEN, ALERT_ADD, DOMAIN_TEAMLEAD} from '../constants'
import {APIPlayerQuery, User} from '../Types'
import { alertDispatch } from './Alerts';

const DOMAIN_DELETE_TOURNAMENT  = DOMAIN_ADMINISTRATOR      + "/delete_tournament"
const DOMAIN_DELETE_TEAM        = DOMAIN_TOURNAMENT_ADMIN   + "/delete_team"
const DOMAIN_ADD_TEAM           = DOMAIN_TOURNAMENT_ADMIN   + "/add_team"
//const DOMAIN_ADD_PLAYER         = DOMAIN_TEAMLEAD           + "/add_player"
const DOMAIN_QUERY_PLAYER       = DOMAIN_TEAMLEAD           + "/query_player"


function getTokenFromStorage() {
    return localStorage.getItem(LOCAL_STORAGE_TOKEN);
}

export function getValueOrNull (ref: RefObject<HTMLInputElement>) {
    var value = ref.current?.value ?? null
    if(value === "")
        value = null
    return value
}


export function getRequestHeader (input: User|string|null) {
    const token = (!input ? "" : (typeof(input)==='string' ? input : input.token))
    const requestHeader = new Headers()
    requestHeader.set('Authorization', "Basic " + btoa(token + ":"))
    return requestHeader
}

export function getRequestHeaderFromStorage() {
    return getRequestHeader(getTokenFromStorage())
}

export async function sendRequestToBackend(url: string, data: any){
    var requestHeader = getRequestHeaderFromStorage()
    requestHeader.set("Content-Type", "application/json")

    const fetchRes = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: requestHeader
    })
    if(fetchRes.ok){
        return await fetchRes
    }
    else{
        const txt = await fetchRes.text()
        alertDispatch(  {type: ALERT_ADD, payload: {title: "Hat nicht funktioniert. Fehlermeldung: " + txt, text: "", variant: "danger"}})
        return false
    }
}

export async function sendRequestToBackendText(url: string, data: any){
    const res = await sendRequestToBackend(url, data)
    
    if (res === false)
        return false
    
    return res.text()
}

export async function sendRequestToBackendJSON(url: string, data: any){
    const res = await sendRequestToBackend(url, data)
    
    if (res === false)
        return false
    
    return res.json()
}

function deleteConfirmation() {
    return window.confirm("You really want to delete?");
}

/*
export async function sendAddPlayer(tournamentId: string, teamId: number, lichessName: string, prename: string, lastname: string, dwz : number|null) {
    const data = {
        tournamentId: tournamentId,
        teamId: teamId,
        lichessName: lichessName,
        teamName: teamName,
        teamAdmin: teamAdmin
    }
    return await sendRequestToBackend(DOMAIN_ADD_PLAYER, data)
}*/

export async function sendQueryPlayer(tournamentId: string, teamId: number|null, prename: string, surname: string) {
    const data = {
        tournamentId: tournamentId,
        teamId: teamId,
        firstnamePart: prename,
        lastnamePart: surname
    }
    var player: APIPlayerQuery[] = await sendRequestToBackendJSON(DOMAIN_QUERY_PLAYER, data)
    return await player
}

export async function sendAddTeam(tournamentId: string, teamname: string, admin: string) {
    const data = {
        tournamentId: tournamentId,
        teamname: teamname,
        admin: admin
    }
    return await sendRequestToBackendText(DOMAIN_ADD_TEAM, data)
}

export async function sendDeleteTeam(tournamentId: string, teamId: number) {
    const data = {
        tournamentId: tournamentId,
        teamId: teamId
    }
    if (deleteConfirmation())
        await sendRequestToBackendText(DOMAIN_DELETE_TEAM, data)
}

export async function sendDeleteTournament(tournamentId: string) {
    const data = {
        tournamentId: tournamentId
    }
    if (deleteConfirmation())
        await sendRequestToBackendText(DOMAIN_DELETE_TOURNAMENT, data)
}

export async function ApiPostRequest (url: string, data: any) {
    var requestHeader = getRequestHeaderFromStorage()
    requestHeader.set("Content-Type", "application/json")

    const fetchRes = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: requestHeader
    })
    return fetchRes
}