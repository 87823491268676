import React, { RefObject, useRef } from 'react';
import { Form, Col, Row, Button, InputGroup } from 'react-bootstrap';
import { DOMAIN, ALERT_ADD, TOURNAMENT_TYPE, refreshInterval, KO_MATCH } from '../../constants';
import {getRequestHeader, getValueOrNull} from '../../util/ApiRequest'
import { isDate, validateClock } from '../../util/Validation'
import { APITournament, UIAlertAction, SetSettings, APILuckyLoser, APIKOSettings, APITeam } from '../../Types';
import { alertDispatch } from '../../util/Alerts';
import { t } from '../../language/translations';
import { getLichessNameFromTeamId, getTeamFromLichessName } from '../../util/Team';
import useTimeout from '../../util/CustomHook/useTimeout';
import { GetMyTeams } from '../../util/api/FetchMyTeams';

const URL_ALIAS = "settings"

function extractLuckyLoser(luckyLoserRef: RefObject<HTMLTextAreaElement>, myTeams: APITeam[]) {
    const luckyLoserInput = luckyLoserRef.current?.value ?? ""
    const luckyLoserArray = luckyLoserInput.split("\n").map(line=>
        line.split(",")
    ).filter(line=>line.length===3)
    const luckyLoser: APILuckyLoser[] = luckyLoserArray.map(line => ({
        teamId: (line[0]==="_" ? KO_MATCH.unknown : getTeamFromLichessName(myTeams,line[0])),
        round: parseInt(line[1])-1,
        position: parseInt(line[2])-1
    } as APILuckyLoser))
    

    // Check for wrong lichess names
    const notFoundLL = luckyLoser.findIndex(ll=>ll.teamId===undefined)
    if (notFoundLL !== -1) {
        alertDispatch(  {type: ALERT_ADD, payload: {title: "Die Einstellungen wurden nicht gespeichert",
                        text: "Der Lichessname \""+luckyLoserArray[notFoundLL][0]+"\" wurde nicht in den Teams gefunden.", variant: "danger"}} as UIAlertAction)
        return false
    }

    return luckyLoser
}

async function sendRequestToBackend(alias: string,  nameRef: RefObject<HTMLInputElement>,
                                                    adminsRef: RefObject<HTMLInputElement>,
                                                    typeRef: RefObject<HTMLSelectElement>,
                                                    roundCountRef: RefObject<HTMLInputElement>,
                                                    boardCountRef: RefObject<HTMLInputElement>,
                                                    timeBaseMinutesRef: RefObject<HTMLInputElement>,
                                                    timeBaseSecondsRef: RefObject<HTMLInputElement>, 
                                                    timeIncrementMinutesRef: RefObject<HTMLInputElement>, 
                                                    timeIncrementSecondsRef: RefObject<HTMLInputElement>, 
                                                    isRatedRef: RefObject<HTMLInputElement>, 
                                                    fenRef: RefObject<HTMLInputElement>, 
                                                    startDateRef: RefObject<HTMLInputElement>,
                                                    isStrictModeRef: RefObject<HTMLInputElement>,
                                                    tagsRef: RefObject<HTMLInputElement>,
                                                    luckyLoserRef: RefObject<HTMLTextAreaElement>,
                                                    token: string|null,
                                                    tournament: APITournament, myTeams: APITeam[]){
    const requestHeader = getRequestHeader (token)
    requestHeader.set("Content-Type", "application/json")

    const base = parseInt(timeBaseMinutesRef.current?.value ?? "0")*60 + parseInt(timeBaseSecondsRef.current?.value ?? "0")
    const incr = parseInt(timeIncrementMinutesRef.current?.value ?? "0")*60 + parseInt(timeIncrementSecondsRef.current?.value ?? "0")

    if(!validateClock(base, incr)) {
        alertDispatch(  {type: ALERT_ADD, payload: {title: "Die Einstellungen wurden nicht gespeichert",
                        text: "Die Bedenkzeit ist von der API nicht erlaubt!", variant: "danger"}} as UIAlertAction)
        return
    }
    
    const boards = boardCountRef.current?.value ?? "0"
    if(parseInt(boards)<1) {
        alertDispatch(  {type: ALERT_ADD, payload: {title: "Die Einstellungen wurden nicht gespeichert",
                        text: "Es muss an mindestens einem Brett gespielt werden", variant: "danger"}} as UIAlertAction)
        return
    }

    const name = nameRef.current?.value ?? ""
    const admins = (adminsRef.current?.value ?? "").split(",").map(x => x.trim())
    const luckyLoser = extractLuckyLoser(luckyLoserRef, myTeams)
    if (!luckyLoser)
        return //send nothing
    const tournamentType = parseInt(typeRef.current?.value ?? "0")
    const url = DOMAIN + "/tournament_admin/" + alias

    const data: SetSettings = {
        tournamentId:   tournament.id,
        name:           name,
        admins:         admins,
        base:           base,
        incr:           incr,
        is_rated:       isRatedRef.current?.checked ?? false,
        boardCount:     (tournamentType===5 ? 1 : parseInt(boards)),
        fen:            getValueOrNull(fenRef),
        startDate:      getValueOrNull(startDateRef),
        isStrictMode:   isStrictModeRef.current?.checked ?? false,
        roundCnt:       parseInt(roundCountRef.current?.value ?? "0"),
        type:           tournamentType,
        tags:           tagsRef.current?.value ?? "",
        koSettings:     {luckyLoser: luckyLoser} as APIKOSettings
    }
    
    if (data.startDate && !isDate(data.startDate)) {
        alertDispatch(  {type: ALERT_ADD, payload: {title: "Die Einstellungen wurden nicht gespeichert",
                        text: "Das Startdatum wurde nicht im richtigen Format angegeben", variant: "danger"}} as UIAlertAction)
        return
    }

    const fetchRes = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: requestHeader
    })
    if(fetchRes.ok){
        const txt = await fetchRes.text()
        alertDispatch(  {type: ALERT_ADD, payload: {title: "Die Einstellungen wurden gespeichert",
                        text: txt, variant: "success"}} as UIAlertAction)
    }
    else{
        const txt = await fetchRes.text()
        alertDispatch(  {type: ALERT_ADD, payload: {title: "Die Einstellungen wurden nicht gespeichert",
            	        text: "Der Befehl wurde an '" + url + "' gesendet, lieferte aber den Status " + fetchRes.status + " zurück. Fehlermeldung: " + txt, variant: "danger"}} as UIAlertAction)
    }
}

function luckyLoserToText(teams: APITeam[], luckyLoser: APILuckyLoser[]) {
    return luckyLoser.map(ll=>[
        getLichessNameFromTeamId(teams,ll.teamId)??"_",
        ll.round+1,
        ll.position+1
    ].join(",")).join("\n")
}

export function TournamentSettings({token, tournament, updateTournament}: {token: string|null, tournament: APITournament, updateTournament: Function}){
    const [myTeams, setMyTeams] = React.useState([] as APITeam[])
    const [luckyLoserText, setluckyLoserText] = React.useState("")
    const [tournamentType, setTournamentType] = React.useState(tournament.settings.type)

    const updateTeams = React.useCallback(async function () {
        const res: APITeam[] = await GetMyTeams(tournament.id)
        //res.sort(sortFunctionForTeams("alphabetical"))
        //StoreMyTeams(res)
        setMyTeams(res)
        
    },[tournament.id])
    React.useEffect(() => {updateTeams()},[updateTeams])
    React.useEffect(() => {
        setluckyLoserText(luckyLoserToText(myTeams,tournament.settings.koSettings?.luckyLoser??[]))
    },[myTeams])
    React.useEffect(() => {
        luckyLoserRef.current!.value = luckyLoserText
    },[luckyLoserText])
    useTimeout(updateTeams, refreshInterval.get_myTeams)

    const handleClick = async (alias: string) => {
        await sendRequestToBackend( alias,
                                    nameRef,
                                    adminsRef,
                                    typeRef,
                                    roundCountRef,
                                    boardCountRef, 
                                    timeBaseMinutesRef, 
                                    timeBaseSecondsRef, 
                                    timeIncrementMinutesRef, 
                                    timeIncrementSecondsRef, 
                                    isRatedRef,
                                    fenRef,
                                    startDateRef,
                                    isStrictModeRef,
                                    tagsRef,
                                    luckyLoserRef,
                                    token, tournament, myTeams)
        updateTournament()
    }
    const nameRef = useRef<HTMLInputElement>(null)
    const adminsRef = useRef<HTMLInputElement>(null)
    const typeRef = useRef<HTMLSelectElement>(null)
    const roundCountRef = useRef<HTMLInputElement>(null)
    const boardCountRef = useRef<HTMLInputElement>(null)
    const timeBaseMinutesRef = useRef<HTMLInputElement>(null)
    const timeBaseSecondsRef = useRef<HTMLInputElement>(null)
    const timeIncrementMinutesRef = useRef<HTMLInputElement>(null)
    const timeIncrementSecondsRef = useRef<HTMLInputElement>(null)
    const isRatedRef = useRef<HTMLInputElement>(null)
    const fenRef = useRef<HTMLInputElement>(null)
    const startDateRef = useRef<HTMLInputElement>(null)
    const isStrictModeRef = useRef<HTMLInputElement>(null)
    const tagsRef = useRef<HTMLInputElement>(null)
    const luckyLoserRef   = useRef<HTMLTextAreaElement>(null)
    const roundFormRef = useRef<HTMLElement>(null)

    return(
        <Form>
            <h2 className="p-3">
                {t("tournamentSettings")}
            </h2>
            <Form.Group as={Row}>
                <Form.Label column md="3" xs="4">
                {t("tournamentName")}
                </Form.Label>
                <Col md="7" xs="6">
                    <InputGroup className="mb-2">
                        <Form.Control ref={nameRef} defaultValue={tournament.name}/>
                        <InputGroup.Append>
                            <InputGroup.Text>max. 50 Zeichen</InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
            </Form.Group>

            <Form.Group as={Row}>
                <Form.Label column md="3" xs="4">
                {t("ta_set_tournament_admins")}
                </Form.Label>
                <Col md="7" xs="6">
                    <InputGroup className="mb-2">
                        <Form.Control ref={adminsRef} defaultValue={tournament.admins.join(", ")} placeholder="e.g. gemuesekinder,techtobi,lena2345,kapfel" />
                        <InputGroup.Append>
                            <InputGroup.Text>mit Komma getrennt</InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
            </Form.Group>

            <Form.Group as={Row}>
                <Form.Label column md="3" xs="4">
                {t("ta_set_type")}
                </Form.Label>
                <Col md="3" xs="4">
                   <Form.Control ref={typeRef} as="select" defaultValue={tournamentType} 
                                onChange={(event: React.ChangeEvent<HTMLSelectElement>)=>{
                                    setTournamentType(parseInt(event.target.value))
                                }}>
                        { TOURNAMENT_TYPE.map((option, key) =>
                            <option value={option.id} key={key}>{t(option.type)}</option>
                        )}
                    </Form.Control>
                </Col>
            </Form.Group>

            <Form.Group as={Row} hidden={tournamentType===1} ref={roundFormRef}>
                <Form.Label column md="3" xs="4">
                {t("ta_set_roundCount")}
                </Form.Label>
                <Col md="3" xs="4">
                    <InputGroup className="mb-2">
                        <Form.Control ref={roundCountRef} defaultValue={tournament.settings.roundCnt??""}/>
                    </InputGroup>
                </Col>
            </Form.Group>

            <Form.Group as={Row} hidden={tournamentType===5}>
                <Form.Label column md="3" xs="4">
                {t("ta_set_boardCount")}
                </Form.Label>
                <Col md="3" xs="4">
                    <InputGroup className="mb-2">
                        <Form.Control ref={boardCountRef} defaultValue={Math.floor(tournament.settings.boardCount)}/>
                    </InputGroup>
                </Col>
            </Form.Group>

            <Form.Group as={Row}>
                <Form.Label column md="3" xs="4">
                {t("ta_set_time_base")}
                </Form.Label>
                <Col md="3" xs="4">
                    <InputGroup className="mb-2">
                        <Form.Control ref={timeBaseMinutesRef} defaultValue={Math.floor(tournament.settings.base/60)}/>
                        <InputGroup.Append>
                            <InputGroup.Text>min</InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
                <Col md="3" xs="4">
                    <InputGroup className="mb-2">
                        <Form.Control ref={timeBaseSecondsRef} placeholder="Sekunden" defaultValue={tournament.settings.base%60}/>
                        <InputGroup.Append>
                            <InputGroup.Text>s</InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
            </Form.Group>

            <Form.Group as={Row}>
                <Form.Label column md="3" xs="4">
                {t("ta_set_time_incr")}
                </Form.Label>
                <Col md="3" xs="4">
                    <InputGroup className="mb-2">
                        <Form.Control ref={timeIncrementMinutesRef} placeholder="Minuten" defaultValue={Math.floor(tournament.settings.increment/60)}/>
                        <InputGroup.Append>
                            <InputGroup.Text>min</InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
                <Col md="3" xs="4">
                    <InputGroup className="mb-2">
                        <Form.Control ref={timeIncrementSecondsRef} placeholder="Sekunden" defaultValue={tournament.settings.increment%60}/>
                        <InputGroup.Append>
                            <InputGroup.Text>s</InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
            </Form.Group>
            
            <Form.Group as={Row}>
                <Form.Label column md="3" xs="4">
                    {t("ta_set_rated")}
                </Form.Label>
                <Col  md="3" xs="4">
                    <input type="checkbox" ref={isRatedRef} defaultChecked={tournament.settings.isRated}/>
                </Col>
            </Form.Group>

            <Form.Group as={Row}>
                <Form.Label column md="3" xs="4">
                    {t("ta_set_fen")}
                </Form.Label>
                <Col md="7" xs="6">
                    <InputGroup className="mb-2">
                        <Form.Control ref={fenRef} placeholder="FEN einfügen oder leerlassen für Grundstellung" defaultValue={tournament.settings.fen ??""}/>
                        <InputGroup.Append>
                            <InputGroup.Text>FEN</InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
            </Form.Group>

            <Form.Group as={Row}>
                <Form.Label column md="3" xs="4">
                    {t("ta_set_start")}
                </Form.Label>
                <Col md="7" xs="6">
                    <InputGroup className="mb-2">
                        <Form.Control ref={startDateRef} placeholder="z.B. 15.07.2021" defaultValue={tournament.settings.startDate ??""}/>
                        <InputGroup.Append>
                            <InputGroup.Text>DD.MM.YYYY</InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
            </Form.Group>

            <Form.Group as={Row}>
                <Form.Label column md="3" xs="4">
                    {t("ta_set_strict")}
                </Form.Label>
                <Col  md="3" xs="4">
                    <input type="checkbox" ref={isStrictModeRef} defaultChecked={tournament.settings.isStrictMode}/>
                </Col>
            </Form.Group>

            <Form.Group as={Row}>
                <Form.Label column md="3" xs="4">
                {t("ta_set_tags")}
                </Form.Label>
                <Col md="7" xs="8">
                    <InputGroup className="mb-2">
                        <Form.Control ref={tagsRef} defaultValue={tournament.settings.tags}/>
                        <InputGroup.Append>
                            <InputGroup.Text>{t("ta_set_tags_comma")}</InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
            </Form.Group>

            <Form.Group as={Row} hidden={tournamentType!==5}>
                <Form.Label column md="3" xs="4">
                Lucky Loser
                </Form.Label>
                <Col md="7" xs="8">
                    <InputGroup className="mb-2">
                        <Form.Control ref={luckyLoserRef} as="textarea" rows={5} placeholder="gemuesekinder,3,4&#10;_,3,8" defaultValue="loading..."/>
                        <InputGroup.Append>
                            <InputGroup.Text>lichessname,round,pos<br/>all 1-indexed<br/>"_" for unknown names</InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
            </Form.Group>

            <Row>
                <Col>
                    <Button type="button" className="mb-2" onClick={() => {handleClick(URL_ALIAS)}}>
                        {t("save")}
                    </Button>
                </Col>
            </Row>
        </Form>
    )
}