import React from 'react';
import { Navbar, Nav, NavDropdown, Alert } from 'react-bootstrap';
import { APITournament, User } from '../Types';
import {LinkContainer} from 'react-router-bootstrap'
import { HouseFill } from 'react-bootstrap-icons';

import { t } from '../language/translations';
import { isTeamLeadOrHigher, isTournamentAdmin, isTournamentAdminOrHigher, isTeamLeadOrHigherInTeam } from '../util/Rights';
import { sites } from './tournamentAdmin/TournamentAdminSideBar';
import {onClickLoginWithLichess} from '../util/RedirectLogin';

const TOURNAMENT_NAME_MAX_LENGTH_SM = 20
const TOURNAMENT_NAME_MAX_LENGTH = 80

function truncate(str: string, n: number){
    return (str.length > n) ? str.substr(0, n-1) + '...' : str;
};

function NavBarBrands (tournament: APITournament|null) {
    return (
        <>
        <LinkContainer to="/" className="d-flex" style={{alignItems: "center"}}>
            <Navbar.Brand>
                <HouseFill />
            </Navbar.Brand>
        </LinkContainer>
        {tournament &&
        <>
            <LinkContainer to={"/"+tournament.id}>
                <Navbar.Brand className="d-none d-sm-block">
                    {truncate(tournament.name, TOURNAMENT_NAME_MAX_LENGTH)}
                </Navbar.Brand>
            </LinkContainer>
            <LinkContainer to={"/"+tournament.id}>
                <Navbar.Brand className="d-block d-sm-none">
                    {truncate(tournament.name, TOURNAMENT_NAME_MAX_LENGTH_SM)}
                </Navbar.Brand>
            </LinkContainer>
        </>
        }
        </>
    )
}

function NavBarTournament (tournament: APITournament) {
    return(
        <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto d-md-none">
                {tournament.settings.type === 5 &&
                    <>
                    <LinkContainer to={"/"+tournament.id+"/ko"}>
                        <Nav.Link>K.o.-Baum</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to={"/" + tournament.id + "/round"}>
                        <Nav.Link>{t("currentRound")}</Nav.Link>
                    </LinkContainer>
                    </>
                }
                {tournament.settings.type !== 5 &&
                    <LinkContainer to={"/" + tournament.id}>
                        <Nav.Link>{t("currentRound")}</Nav.Link>
                    </LinkContainer>
                }
                {tournament.broadcastLink &&
                    <Nav.Link href={tournament.broadcastLink}>Live Partien</Nav.Link>
                }

                <NavDropdown title={t("results")} id="collasible-nav-dropdown">
                    {tournament.settings.type === 5 &&
                        <LinkContainer to={"/"+tournament.id+"/ko-ranking"}>
                            <NavDropdown.Item>Tabelle</NavDropdown.Item>
                        </LinkContainer>
                    }
                    {tournament.settings.type !== 5 &&
                        <LinkContainer to={"/" + tournament.id + "/tabelle"}>
                            <NavDropdown.Item>{t("crossTable")}</NavDropdown.Item>
                        </LinkContainer>
                    }
                    <LinkContainer to={"/" + tournament.id + "/fortschrittstabelle"}>
                        <NavDropdown.Item>{t("progressTable")}</NavDropdown.Item>
                    </LinkContainer>
                    {tournament.settings.type !== 5 &&
                        <LinkContainer to={"/" + tournament.id + "/einzel"}>
                            <NavDropdown.Item>{t("singlePlayerResults")}</NavDropdown.Item>
                        </LinkContainer>
                    }
                    <LinkContainer to={"/" + tournament.id + "/teilnehmer"}>
                        <NavDropdown.Item>{t("participantList")}</NavDropdown.Item>
                    </LinkContainer>
                </NavDropdown>
            </Nav>
        </Navbar.Collapse>
    )
}

function MenuLoggedIn (user: User, tournament: APITournament|null) {
    return (
        <Navbar.Collapse className="justify-content-end">
            <Nav>
                { tournament && isTeamLeadOrHigher(tournament, user)  && 
                    <>
                        <LinkContainer to={"/" + tournament.id + "/teamlead"} className="d-none d-md-flex">
                            <Nav.Link>{t("teamlead")}</Nav.Link>
                        </LinkContainer>
                        <NavDropdown title={t("teamlead")} id="dropdown-teamlead" className="d-md-none">
                            {tournament.teams.filter(team=>isTeamLeadOrHigherInTeam(tournament,team,user)).map((team,key)=>
                                <LinkContainer to={"/" + tournament.id + "/teamlead/"+team.id} key={key}>
                                    <NavDropdown.Item>{team.name}</NavDropdown.Item>
                                </LinkContainer>
                            )}
                        </NavDropdown>
                    </>
                }
                { tournament && isTournamentAdminOrHigher( tournament, user) &&
                    <>
                        <LinkContainer to={"/" + tournament.id + "/admin"} className="d-none d-md-flex">
                            <Nav.Link>{t("tournamentAdmin")}</Nav.Link>
                        </LinkContainer>
                        <NavDropdown title={t("tournamentAdmin")} id="dropdown-tournamentAdmin" className="d-md-none">
                            {sites.map((site,key)=>
                                <LinkContainer to={"/" + tournament.id + "/admin/"+site} key={key}>
                                    <NavDropdown.Item>{t(site)}</NavDropdown.Item>
                                </LinkContainer>
                            )}
                        </NavDropdown>
                    </>
                }
                <NavDropdown title={user.name} id="collasible-nav-dropdown">
                    { user.isSiteAdmin &&
                        <>
                            <LinkContainer to="/admin">
                                <NavDropdown.Item>{t("siteAdminMenu")}</NavDropdown.Item>
                            </LinkContainer>
                            <NavDropdown.Divider />
                        </>
                    }
                    
                    <NavDropdown.Item href="?token=" key="1">{t("logout")}</NavDropdown.Item>
                </NavDropdown>
            </Nav>
        </Navbar.Collapse>
    )
}

function LogIn () {
    return (
        <Navbar.Collapse className="justify-content-end">
            <Nav>
                <LinkContainer to="/login" onClick={onClickLoginWithLichess}>
                    <Nav.Link>{t("loginWithLichess")}</Nav.Link>
                </LinkContainer>
            </Nav>
        </Navbar.Collapse>
    )
}

function InfoText(infotext:string) {
    return (
        <Alert variant="info" className="mb-0">
            <div dangerouslySetInnerHTML={{__html: infotext}} /> 
        </Alert>
    )
}

export function NavBar({user, tournament}: {user: User|null, tournament: APITournament|null}) {

    return (
        <>
        <Navbar bg="dark" variant="dark" expand={isTournamentAdmin(tournament, user?.lichessName??null) ? "lg" : "md"} sticky="top" collapseOnSelect={true}>
            { NavBarBrands(tournament) }

            <Navbar.Toggle aria-controls="basic-navbar-nav" />

            { tournament !== null && NavBarTournament(tournament) }
            { !user && LogIn () }
            { user  && MenuLoggedIn(user, tournament) }
            
        </Navbar>

        {tournament?.infoText && InfoText(tournament.infoText)}
        </>
    )
  }