import { Button, ButtonGroup, OverlayTrigger, Tooltip } from "react-bootstrap"
import { t } from "../../language/translations"

type Props = {
    buttons: {action: string, tooltip: string, label: JSX.Element}[],
    handleClick: Function
}

export function ButtonGroupSelector({buttons, handleClick} : Props) {
    const ExpansionButton = (label:JSX.Element, handleClick: any, key: number) => 
        <Button size="sm" key={key} onClick={handleClick} variant="outline-secondary">
            {label}
        </Button>
    
    return (
        <ButtonGroup>
            { buttons.map((button, key) => {
                if (button.tooltip)
                    return (
                        <OverlayTrigger
                            placement="bottom"
                            key={key}
                            overlay={
                                <Tooltip id={key.toString()}>
                                    {t(button.tooltip)}
                                </Tooltip>
                            }>
                                {ExpansionButton(button.label, ()=>handleClick(button.action), key)}
                        </OverlayTrigger>
                    )
                else
                    return ExpansionButton(button.label, ()=>handleClick(button.action), key)
            })}
        </ButtonGroup>
    )
}