export const TITLE_PREFIX = "Kelheim Mannschaftsturniere | "

export const DOMAIN = "https://turniere.schachklub-kelheim.de:8443/api"
//export const DOMAIN = "http://localhost:8080"
//export const DOMAIN = "http://192.168.178.31:8080"
//export const DOMAIN = "http://84.129.203.40:8080"
export const LICHESS_URL = "https://lichess.org/"
export const LICHESS_API_PLAYER = "https://lichess.org/api/users/status?ids="
export const REGISTER = "https://turniere.schachklub-kelheim.de/api/login"

export const DOMAIN_ADMINISTRATOR       = DOMAIN + "/admin"
export const DOMAIN_TOURNAMENT_ADMIN    = DOMAIN + "/tournament_admin"
export const DOMAIN_TEAMLEAD            = DOMAIN + "/teamlead"
export const DOMAIN_GUEST               = DOMAIN + "/guest"

export const RIGHTS = {guest: 0, player: 10, teamlead: 20, tournamentAdmin: 30, siteAdmin: 50}
export const RIGHTS_NAME = [{level: 0, name: "Zuschauer"}, {level: 10, name: "Spieler"}, {level: 20, name: "Mannschaftsführer"}, {level: 30, name: "Turnierleiter"}, {level: 50, name: "Administrator"}]
export const RESULT = {player1Won: 2, draw: 1, player2Won: 0, notCounted: -1}


// Refresh Intervals
//export const TOURNAMENT_REFRESH_INTERVAL = 5*1000
//export const TOURNAMENT_REFRESH_INTERVAL_FOR_TEAMLEADS = 2*1000
//export const TOURNAMENT_LIST_REFRESH_INTERVAL = 60*1000
export const USER_REFRESH_INTERVAL = 3*1000
export const ADMIN_TOURNAMENTS_REFRESH_INTERVAL = 1*1000
export const REFRESH_INTERVAL_TEAMLEAD_GET_TEAMS = 7*1000

export var refreshInterval = {
    get_user:               5*1000,
    get_tournaments:      120*1000,
    get_tournament:         5*1000,
    get_myTeams:            7*1000,
    get_tournaments_admin:  2*1000
}


// Alert
export const ALERT_DISPLAY_COUNT = 3
export const ALERT_DISPLAY_INTERVAL = 8000
export const ALERT_ADD = 'add_alert'
export const ALERT_REMOVE = 'remove_alert'
export const ALERT_EMPTY = 'remove_alert'

// Lichess API Limits
export const CLOCK_LIMIT = [    {from:     0, to:     60, step:    15},
                                {from:    60, to:   2*60, step:    30},
                                {from:  2*60, to:  20*60, step:    60},
                                {from: 20*60, to:  45*60, step:  5*60},
                                {from: 45*60, to: 180*60, step: 15*60}]

export const CLOCK_INCREMENT =[ {from:  0, to:  20, step:  1},
                                {from: 20, to:  45, step:  5},
                                {from: 45, to:  60, step: 15},
                                {from: 60, to: 180, step: 30}]

export const LOCAL_STORAGE_TOKEN = 'API_TOKEN'
export const LOCAL_STORAGE_LANG  = 'LANGUAGE'
export const LOCAL_STORAGE_USER  = 'USER'
export const LOCAL_STORAGE_TOURNAMENT_DETAILS  = 'TOURNAMENT_DETAILS'
export const LOCAL_STORAGE_TOURNAMENTS  = 'TOURNAMENTS'
export const LOCAL_STORAGE_MY_TEAMS  = 'MY_TEAMS'
export const LOCAL_STORAGE_LOGIN_REDIRECT  = 'LOGIN_REDIRECT'

export const MAX_TOURNAMENT_DETAILS_IN_LOCALSTORAGE = 4


export const LANG_DEFAULT = "de"

export const MIN_RH_TO_DISPLAY = 700

export const TOURNAMENT_TYPE = [{id: 0, type: "type_manual"},
                                {id: 1, type: "type_roundRobin"},
                                {id: 2, type: "type_swissSystem"},
                                {id: 5, type: "type_KoSystem"}]

export const KO_ROUND_NAMES = ["Finale","Halbfinale","Viertelfinale"]

export const KO_MATCH = {unknown: null, empty: -2}
