import { OverlayTrigger, Tooltip, Table, Button, Container } from 'react-bootstrap';
import { APITournament, APIPlayer, APIGame, APIRound } from '../../Types';
import {CalculatePerformance} from '../../util/CalculatePerformance'
import { LICHESS_URL, MIN_RH_TO_DISPLAY } from '../../constants';
import { t } from '../../language/translations';
import { isLinkId } from '../../util/Game';

import './rankings.css';

function convertAPIGameToGame (game: APIGame|null, player: APIPlayer, board: number): Game|null {
    if (!game)
        return null
    return({
        id: game.id,
        myResult: getMyResult(game, player.name), 
        opponentName:   (game.player1?.name===player.name ? game.player2?.name??null : game.player1?.name??null),
        opponentRating: (game.player1?.name===player.name ? game.player2?.rating??null : game.player1?.rating??null),
        isWhite: (game.player1?.name===player.name ? true : false) !== (board%2===0)
    })
}

function getGameFromRound (round: APIRound, player: APIPlayer): (Game|null) {
    var res = null

    round.matches.forEach(match =>
        match.games.forEach((game, b) => {
            if (game.player1?.name === player.name || game.player2?.name === player.name)
                res = convertAPIGameToGame(game, player, b)
        })
    )
    return res
}

function getGames (tournament: APITournament, player: APIPlayer): (Game|null)[] {
    return tournament.rounds.map(round => {
        return getGameFromRound(round, player)
    })
}

function getMyResult (game: APIGame, playerName: string) {
    if (game.result === null)
        return null
    else if (game.player1?.name === playerName)
        return game.result / 2
    else
        return (0-(game.result-2)) / 2
}

export type Game = {
    id: string|null,
    myResult: number|null,
    opponentName: string|null,
    opponentRating: number|null,
    isWhite: boolean
}

type SinglePlayer = {
    player: APIPlayer,
    games: (Game|null)[],
    total: number,
    Rh: number
}

function convertResultToString (result: number|null) {
    if (result === null)
        return "-"
    else if (result === 0.5)
        return "½"
    else
        return ""+result
}

function SinglePlayerGame (game: Game|null) {
    if (!game)
        return (
            <Button size="sm" className="player-result-button" variant="" key={Math.random()*10000}>
                -
            </Button>
        )

    return(
        <OverlayTrigger
            placement="bottom"
            key={game.id}
            overlay={
                <Tooltip id={`tooltip-${game.id}`}>
                    <strong>({game.isWhite?"w":"s"}) {game.opponentName??"Spielfrei" + ((game.opponentRating??-1)>0 ? " ("+game.opponentRating+")" : "")}</strong>
                </Tooltip>
            }>
            <Button size="sm" className={"player-result-button" + (game.isWhite ? "" : "")} variant={game.myResult===1 ? "outline-success" : (game.myResult===0.5 ? "outline-primary" : "outline-secondary")} 
                    href={isLinkId(game.id) ? LICHESS_URL+game.id : ""} target="_blank">
                {game.opponentName === null ? "+" : convertResultToString(game.myResult)}
            </Button>
        </OverlayTrigger>
    )
}

function sortSinglePlayer (a: SinglePlayer, b: SinglePlayer){
    if ((b.total - a.total) === 0)
        return (b.Rh - a.Rh)
    else
        return (b.total - a.total)
}

type Props =  {
    tournament: APITournament,
    players: APIPlayer[],
    sort: boolean
}

export function SinglePlayerTable({tournament, players, sort}: Props) {
    var singlePlayers: SinglePlayer[] = players.map(player => {return {
        player: player,
        games: getGames(tournament, player),
        total: 0,
        Rh: 0
    } as SinglePlayer})

    singlePlayers.forEach(singlePlayer => {
        singlePlayer.total = singlePlayer.games.reduce((acc, cur)=> acc + (cur?.myResult??0), 0)
        singlePlayer.Rh = CalculatePerformance(singlePlayer.player.rating, singlePlayer.games) 
    })
    
    if (sort)
        singlePlayers.sort(sortSinglePlayer)
    return(
        <>
            <tr className="singlePlayerTableSnippet-header">
                <td className="text-center" colSpan={2}></td>
                <td>DWZ</td>
                <td colSpan={tournament.rounds.length}>Einzelergebnisse</td>
                <td>&sum;</td>
                <td colSpan={2}>Leistung</td>
            </tr>
            {singlePlayers.map((singlePlayer, key) => {
                return (
                    <tr key={key} className="singlePlayerTableSnippet-body">
                        <td>{key+1})</td>
                        <td className="text-left">{singlePlayer.player.name}</td>
                        <td className="text-center">
                            {singlePlayer.player.rating &&
                                singlePlayer.player.rating
                            }
                        </td>
                        {singlePlayer.games.map((game,i) => 
                            <td key={game?.id??i}>
                                {SinglePlayerGame(game)}
                            </td>)
                        }
                        <td className="text-center">
                            {singlePlayer.total}
                        </td>
                        <td className="text-center" colSpan={2}>
                            {(singlePlayer.Rh > MIN_RH_TO_DISPLAY ? singlePlayer.Rh : "")}
                        </td>
                    </tr>
                )
            })}
            {singlePlayers.length === 0 &&
                <caption>
                    {t("noTeamMemberYet")}
                </caption>
            }
        </>
    )
}

export function PlayerResults({tournament, players, sort}: Props){
    var singlePlayers: SinglePlayer[] = players.map(player => {return {
        player: player,
        games: getGames(tournament, player),
        total: 0,
        Rh: 0
    } as SinglePlayer})

    singlePlayers.forEach(singlePlayer => {
        singlePlayer.total = singlePlayer.games.reduce((acc, cur)=> acc + (cur?.myResult??0), 0)
        singlePlayer.Rh = CalculatePerformance(singlePlayer.player.rating, singlePlayer.games) 
    })
    
    if (sort)
        singlePlayers.sort(sortSinglePlayer)
    return(
        <Container>
            <Table size="sm" className="table-vcenter">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>{t("name")}</th>
                        <th className="text-center">{t("rating")}</th>
                        <th>{t("results")}</th>
                        <th className="text-center">{t("total")}</th>
                        <th className="text-center">{t("performance")}</th>
                    </tr>
                </thead>
                <tbody>
                    {singlePlayers.map((singlePlayer, key) => {
                        return (
                            <tr key={key}>
                                <td>{key+1}</td>
                                <td>{singlePlayer.player.name}</td>
                                <td className="text-center">
                                {singlePlayer.player.rating &&
                                    singlePlayer.player.rating
                                }
                                </td>
                                <td>{singlePlayer.games.map(game => SinglePlayerGame(game) )}</td>
                                <td className="text-center">
                                    {singlePlayer.total}
                                </td>
                                <td className="text-center">
                                    {(singlePlayer.Rh > MIN_RH_TO_DISPLAY ? singlePlayer.Rh : "")}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
                {singlePlayers.length === 0 &&
                    <caption>
                        {t("noTeamMemberYet")}
                    </caption>
                }
            </Table>
        </Container>
    )
}