import React from 'react'
import { Badge, Button, Modal, Table } from "react-bootstrap"
import { Pause } from 'react-bootstrap-icons'
import { t } from '../../language/translations'
import { APIPairing, APITournament } from "../../Types"
import { SwissPairingFromApi } from '../../util/api/SwissPairing'
import { getUnpairedTeams } from '../../util/Round'
import { getEmptyBoardCount, sortFunctionForTeams } from '../../util/Team'

async function RequestSwissPairings(tournamentId: string, pausedTeamIds: number[], handlePairings: Function) {
    const newPairings: APIPairing[]|null = await SwissPairingFromApi(tournamentId,pausedTeamIds)
    if (!newPairings)
        return

    handlePairings(newPairings)
}

type Props = {
    tournament: APITournament,
    handlePairings: Function
}
export function SwissPairing ({tournament, handlePairings}: Props) {
    const lastRoundPausedTeams: number[] = tournament.rounds.length < 1 ? [] : getUnpairedTeams(tournament.rounds[tournament.rounds.length-1], tournament.teams).map(t=>t.id)

    const [show, setShow] = React.useState(false)
    const [pausedTeamIds, setPausedTeamIds] = React.useState(lastRoundPausedTeams as number[])

    const triggerTeamPause = (teamId: number) => {
        const index = pausedTeamIds.indexOf(teamId)
        if (index === -1)
            setPausedTeamIds([...pausedTeamIds, teamId])
        else
            setPausedTeamIds(pausedTeamIds.filter(id=>id!==teamId))
    }

    return (
        <>
        <Button variant="outline-secondary" onClick={()=>setShow(true)}>
            {t("swissSystem")}
        </Button>
            
        <Modal
            show={show}
            onHide={()=>setShow(false)}
            size="lg"
            aria-labelledby="modal-tournament-info"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="modal-tournament-info">
                    Auslosen der Runde {tournament.rounds.length+1}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table size="sm">
                    <tbody>
                        <tr>
                            <td>Ausgeloste Teams</td>
                            <td>{tournament.teams.length-pausedTeamIds.length}</td>
                        </tr>
                        <tr>
                            <td>Pausierte Teams (letzte Runde)</td>
                            <td>{pausedTeamIds.length} ({tournament.rounds.length < 1 ? "-" : lastRoundPausedTeams.length.toString()})</td>
                        </tr>
                    </tbody>
                </Table>

                {t("swissSystemWhichPaused")}
                <Table size="sm">
                    <thead>
                        <tr>

                        </tr>
                    </thead>
                    <tbody>
                        {tournament.teams.slice(0).sort(sortFunctionForTeams("alphabetical")).map((team, key)=> {
                            let emptyBoardCount = getEmptyBoardCount(team, tournament.settings.boardCount, tournament.settings.isStrictMode)

                            return (
                            <tr key={key}>
                                <td>{key+1}</td>
                                <td>
                                    {team.name}
                                    {emptyBoardCount > 0 && 
                                        <Badge variant="danger ml-2">
                                            {emptyBoardCount}
                                        </Badge>
                                    }
                                </td>
                                <td>
                                    <Button size="sm" variant="outline-secondary" active={pausedTeamIds.some(id=>id===team.id)} onClick={()=>triggerTeamPause(team.id)}>
                                        <Pause/>
                                    </Button>
                                </td>
                            </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button 
                    variant="outline-success" 
                    onClick={()=>{
                        setShow(false);
                        RequestSwissPairings(tournament.id, pausedTeamIds, handlePairings)
                    }}>
                    {t("swissSystemCreatePrePairings")}
                </Button>
                <Button onClick={()=>setShow(false)} variant="outline-secondary">{t("abort")}</Button>
            </Modal.Footer>
        </Modal>
        </>
    )
}