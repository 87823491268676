import React from 'react';
import { Modal, Button, Form, Col, Row } from 'react-bootstrap';
import { ALERT_ADD, DOMAIN_TOURNAMENT_ADMIN } from '../../constants';
import { t } from '../../language/translations';

import {APITeam, UIAlertAction} from '../../Types'
import { alertDispatch } from '../../util/Alerts';
import { sendRequestToBackendText} from '../../util/ApiRequest'
import { isLichessUserName } from '../../util/Validation';

const DOMAIN_EDIT_TEAM = DOMAIN_TOURNAMENT_ADMIN + "/edit_teaminfo"

type Props = {
    tournamentId: string,
    team: APITeam,
    onHide: any,
    updateTournament: Function
}

export function EditTeamModal({tournamentId, team, onHide, updateTournament}: Props) {
    const teamNameRef = React.useRef<HTMLInputElement>(null)
    const teamAdminRef = React.useRef<HTMLInputElement>(null)

    const handleClick = async () => {
        if (!teamAdminRef.current || !(await isLichessUserName(teamAdminRef.current.value))){
            alertDispatch({type: ALERT_ADD, payload: {title: t("teamNotCreated"), text: t("lichessNameNotFound"), variant: "danger"}} as UIAlertAction)
            teamAdminRef.current?.classList.add("border-danger")
            return
        }

        var data = {
            tournamentId: tournamentId,
            teamId: team.id,
            teamname: teamNameRef.current?.value ?? "",
            admin: teamAdminRef.current?.value ?? ""
        }    
        if (await sendRequestToBackendText(DOMAIN_EDIT_TEAM, data)) {
            onHide()
            updateTournament()
        }
    }

    return (
        <Modal
            size="lg"
            show={true}
            aria-labelledby="editTeamModal"
            onHide={onHide}
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="editTeamModal">
                    {t("editTeam")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col>
                            <Form.Control ref={teamNameRef} placeholder={t("teamName")} defaultValue={team.name}/>
                        </Col>
                        <Col>
                            <Form.Control ref={teamAdminRef} placeholder={t("teamlead")+" Lichess"} defaultValue={team.teamleaders.join(",")}
                                onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>)  => {
                                    if(event.key==="Enter") handleClick()
                                }}
                            />
                        </Col>
                        <Col>
                            <Button type="button" className="mb-2" onClick={() => {handleClick()}}>
                                {t("save")}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide}>{t("close")}</Button>
            </Modal.Footer>
        </Modal>
    );
}