import React from 'react'
import { Button, Modal} from 'react-bootstrap'
import { APITeam, APITournament } from '../../Types'
import { FetchTournaments, LoadTournaments, StoreTournaments} from '../../util/api/FetchTournaments'
import { GetMyTeams } from '../../util/api/FetchMyTeams'
import { sortByName } from '../../util/Tournament'
import { sortFunctionForTeams } from '../../util/Team'

import { sendAddTeam, sendRequestToBackendText} from '../../util/ApiRequest'
import { DOMAIN_TEAMLEAD } from '../../constants'
import { Clipboard } from 'react-bootstrap-icons'

const DOMAIN_ADD_PLAYER = DOMAIN_TEAMLEAD + "/add_player"

async function addPlayers (tournamentId: string, teamId: number, team: APITeam) {
    team.players.forEach(player=> {
        const data = {
            rating: player.rating,
            tournamentId: tournamentId,
            teamId: teamId,
            lichess: player.lichessName,
            prename: null,
            surname: null,
        }
    
        sendRequestToBackendText(DOMAIN_ADD_PLAYER, data)
    })
}

async function copyTournament (origin: APITournament, destination: APITournament) {
    var teams: APITeam[] = await GetMyTeams(origin.id)

    teams.forEach(async (team) => {
        await sendAddTeam(destination.id, team.name, team.teamleaders.join(","))
        const newTeams = await GetMyTeams(destination.id)
        const teamId = newTeams.find(t=>t.name===team.name)?.id??null
        if (teamId !== null) {
            await addPlayers (destination.id, teamId, team)
        }
    })

    return true
}


type Props = {
    origin: APITournament
}

export function TournamentClone ({origin} : Props) {
    const [show, setShow] = React.useState(false)
    const tournamentsRef = React.useRef(LoadTournaments() as APITournament[])

    const handleShowModal = async () => {
        var res: APITournament[] = await FetchTournaments()
        res.sort(sortByName)
        StoreTournaments(res)
        tournamentsRef.current = res
        setShow(true)
    }

    const handleSelectTournament = async (destination: APITournament) => {
        var res: APITeam[] = await GetMyTeams(origin.id)
        res.sort(sortFunctionForTeams("alphabetical")) 
        await copyTournament(origin, destination)

        setShow(false)
    }

    return (
        <>
        <Button size="sm" variant="outline-secondary" onClick={()=>handleShowModal()}><Clipboard /></Button>
        <Modal
            size="lg"
            show={show}
            aria-labelledby="loadTeamModal"
            onHide={()=>setShow(false)}
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="loadTeamModal">
                    Team importieren
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Wähle ein Turnier, das {origin.name} kopiert werden soll:
                    <ul>
                        {tournamentsRef.current.map((tournament, key) =>
                            <li key={key} className="clickable" onClick={()=>handleSelectTournament(tournament)}>{tournament.name}</li>
                        )}
                    </ul>
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={()=>setShow(false)}>Schließen</Button>
            </Modal.Footer>
        </Modal>
        </>
    )
}