import { Image, Nav } from 'react-bootstrap'
import {LinkContainer} from 'react-router-bootstrap'
import { APITournament } from '../../Types'
import { t } from '../../language/translations';

export const sites = [  "see_overview",
                        "add_team", 
                        "set_pairing", 
                        "set_action", 
                        "set_options", 
                        "set_result",
                        "set_info"]


type Props = {
    tournament: APITournament
}

export function TournamentAdminSideBar ({tournament}: Props) {
    return (
        <Nav variant="pills" className="flex-column justify-content-between pt-2 h-100">
            <div>
                {sites.map((action, key) =>
                    <LinkContainer to={"/"+tournament.id+"/admin/"+action} key={key}>
                        <Nav.Link>{t(action)}</Nav.Link>
                    </LinkContainer>
                )
            }
            </div>
            <Image src="/skk-logo.png" fluid className="px-2"/>
        </Nav>
    )
}